import React from "react";
import CoreTools from "../services/CoreTools";

const Icon = props => {
    const fontI = name => {
        let style = CoreTools.fuseObj(
            props.size ? {fontSize: typeof(props.size) === "number" ? props.size + "px" : props.size} : null,
            props.color ? {color: props.color} : null,
            props.margin !== 0 ? {marginRight: props.margin ? props.margin + "px" : "3px"} : null,
            props.style
        );
        return <i 
            className={[name, props.className].filter(cn => cn).join(" ")}
            style={style}
            title={props.title}
            onClick={props.onClick}
            onMouseDown={props.onMouseDown}
        ></i>;
    };
    switch (props.name) {
        case "add": return fontI("fas fa-plus");
        case "address-book": return fontI("fas fa-address-book");
        case "archive": return fontI("fa-solid fa-box-archive");
        case "arrow-down": return fontI("fas fa-arrow-down");
        case "arrow-left": return fontI("fas fa-arrow-left");
        case "arrow-right": return fontI("fas fa-arrow-right");
        case "arrow-up": return fontI("fas fa-arrow-up");
        case "backward": return fontI("fas fa-step-backward");
        case "book": return fontI("fas fa-book");
        case "calculator": return fontI("fa-solid fa-calculator");
        case "cancel": return fontI("fa-solid fa-xmark");
        case "caret-down": return fontI("fas fa-caret-down");
        case "caret-up": return fontI("fas fa-caret-up");
        case "caretbox-down": return fontI("far fa-caret-square-down");
        case "chat": return fontI("fa-solid fa-comment");
        case "chart": return fontI("fas fa-chart-line");
        case "clipboard": return fontI("fas fa-clipboard");
        case "dog": return fontI("fa-solid fa-dog");
        case "delete": return fontI("fas fa-trash");
        case "desktop": return fontI("fa-solid fa-object-group");
        case "document": return fontI("fas fa-file-alt");
        case "dollar": return fontI("fas fa-dollar-sign");
        case "car": return fontI("fas fa-car");
        case "check": return fontI("fas fa-check");
        case "checklist": return fontI("fas fa-tasks");
        case "close": return fontI("fas fa-door-open");
        case "contacts": return fontI("fa-solid fa-address-book");
        case "copy": return fontI("far fa-copy");
        case "customer": return fontI("fas fa-handshake");
        case "edit": return fontI("fas fa-edit");
        case "email": return fontI("fa-solid fa-envelope");
        case "enter": return fontI("fa-solid fa-arrow-right-to-bracket");
        case "folder": return fontI("fa-solid fa-folder");
        case "folderOpen": return fontI("fa-solid fa-folder-open");
        case "form": return fontI("fab fa-wpforms");
        case "fromCloud": return fontI("fas fa-cloud-download-alt");
        case "headset": return fontI("fa-solid fa-headset");
        case "heart": return fontI("far fa-heart");
        case "history": return fontI("fas fa-history");
        case "id-card": return fontI("fa-solid fa-id-card");
        case "images": return fontI("fas fa-images");
        case "in": return fontI("fas fa-sign-in-alt");
        case "invisible": return fontI("fas fa-eye-slash");
        case "link": return fontI("fas fa-link");
        case "list": return fontI("fas fa-list");
        case "logout": return fontI("fa-solid fa-person-circle-xmark");
        case "map-pin": return fontI("fas fa-map-marker-alt");
        case "maximize": return fontI("fa-solid fa-maximize");
        case "menu": return fontI("fas fa-bars");
        case "mic": return fontI("fa-solid fa-microphone");
        case "minimize": return fontI("fa-solid fa-window-minimize");
        case "network": return fontI("fas fa-ethernet");
        case "note": return fontI("fas fa-sticky-note");
        case "notify": return fontI("fas fa-exclamation-circle");
        case "out": return fontI("fas fa-sign-out-alt");
        case "overflow": return fontI("fa-brands fa-stack-overflow");
        case "pause": return fontI("fas fa-pause");
        case "phone": return fontI("fas fa-phone");
        case "pin": return fontI("fas fa-thumbtack");
        case "play": return fontI("fas fa-play");
        case "project": return fontI("fa-solid fa-diagram-project");
        case "pulse": return fontI("fa-solid fa-heart-pulse");
        case "refresh": return fontI("fas fa-sync-alt");
        case "resize": return fontI("fa-solid fa-up-right-and-down-left-from-center");
        case "restore": return fontI("fa-regular fa-square");
        case "save": return fontI("fas fa-save");
        case "search": return fontI("fas fa-search");
        case "send": return fontI("fas fa-paper-plane");
        case "settings": return fontI("fas fa-cog");
        case "share": return fontI("fas fa-share");
        case "signal": return fontI("fas fa-signal");
        case "sms": return fontI("fas fa-sms");
        case "star": return fontI("fas fa-asterisk");
        case "step-backward": return fontI("fa-solid fa-backward-step");
        case "step-forward": return fontI("fa-solid fa-forward-step");
        case "stop": return fontI("fas fa-stop");
        case "tag": return fontI("fas fa-tag");
        case "today": return fontI("fa-solid fa-calendar-day");
        case "vital": return fontI("fas fa-heartbeat");
        case "voicemail": return fontI("fas fa-voicemail");
        case "volume-down": return fontI("fas fa-volume-down");
        case "volume-up": return fontI("fas fa-volume-up");
        case "upload": return fontI("fas fa-file-upload");
        case "undo": return fontI("fa-solid fa-rotate-left");
        case "unlink": return fontI("fas fa-unlink");
        case "user-settings": return fontI("fas fa-user-cog");
        case "user": return fontI("fa-solid fa-user");
        case "users": return fontI("fa-solid fa-users");
        case "visible": return fontI("fas fa-eye");
        case "watchdog": return fontI("fa-solid fa-shield-dog");
        case "wired": return fontI("fa-solid fa-network-wired");
        default: return props.name ? fontI(props.name) : null;
    }
}
export default Icon;