import React from "react";
import CoreTools from "../../services/CoreTools";
import ModalDesktop from "./ModalDesktop";
import AppManager from "../../services/AppManager";
import WindowSetDesktop from "./WindowSetDesktop";

class Modal extends React.Component {
    state = {
        title: "New Modal",
        width: "auto",
        height: "auto",
        zIndex: 1,
        onClose: () => this.close()
    };
    
    setVal = CoreTools.stateHandler(this);
    wHolder = CoreTools.watchHolder();

    componentDidMount = () => {
        this.setVal("mount");
        const start = () => {
            if (this.state.isLoaded === undefined) {this.setVal({isLoaded: true});}
            if (typeof(this.onMount) === "function") {this.onMount(this.state, this.props);}
        };
        if (CoreTools.isObject(this.initState)) {this.setVal(this.initState, start);}
        else {start();}
        this.wHolder(CoreTools.on("isMobile", isMobile => this.setVal({isMobile: isMobile})));
    };

    componentWillUnmount = () => {
        this.setVal("unmount");
        this.wHolder("end");
        if (typeof(this.onUnmount) === "function") {this.onUnmount();}
    };

    setTitle = newTitle => this.setVal({title: newTitle});

    render = () => CoreTools.switch(
        [() => !this.state.isLoaded, null],
        [() => typeof(this.display) === "function", () => this.display(this.state)],
        [() => this.isMobile && typeof(this.mobile) === "function", () => this.mobile(this.state)],
        [() => !this.isMobile && typeof(this.desktop) === "function", () => <ModalDesktop state={this.state}>{this.desktop(this.state)}</ModalDesktop>],
        [true, null]
    );

    close = () => {
        if (typeof(this.props.onClose) === "function") {
            this.props.onClose();
        } else {
            console.log("No Close");
        }
    };
}

class Window extends React.Component {
    state = (() => {
        const state = AppManager.getAppState(this.props.wdwId) || {
            wdwTitle: this.props.applet.title,
            width: this.props.applet.startWidth,
            height: this.props.applet.startHeight,
            modals: []
        };
        state.isMobile = CoreTools.state.isMobile;
        console.log("Readying Window:", this.props.applet.title);
        return state;
    })();
    
    setVal = CoreTools.stateHandler(this);
    wHolder = CoreTools.watchHolder();
    
    componentDidMount = () => {
        this.setVal("mount");
        const start = () => {
            if (this.state.isLoaded === undefined) {this.setVal({isLoaded: true});}
            if (typeof(this.onMount) === "function") {this.onMount(this.state, this.props);}
        };
        if (CoreTools.isObject(this.initState)) {this.setVal(this.initState, start);}
        else {start();}
        this.wHolder(CoreTools.on("isMobile", isMobile => this.setVal({isMobile: isMobile})));
    };

    componentWillUnmount = () => {
        this.setVal("unmount");
        this.wHolder("end");
        if (typeof(this.onUnmount) === "function") {this.onUnmount();}
        AppManager.setAppState(this.props.wdwId, this.state);
    };

    addModal = modal => {
        if (!this.state.modals.find(m => m.key === modal.key)) {
            this.setVal({modals: [...this.state.modals, modal]});
        }
    };
    removeModal = modal => {
        if (typeof(modal) === "string") {
            const m = this.state.modals.find(m => m.key === modal);
            if (m) {this.setVal({modals: [...CoreTools.remove(this.state.modals, m)]});}
        } else {
            this.setVal({modals: [...CoreTools.remove(this.state.modals, modal)]});
        }
    };

    render = () => <>{CoreTools.switch(
        [() => !this.state.isLoaded, null],
        [() => typeof(this.display) === "function", () => this.display(this.state)],
        [() => this.state.isMobile && typeof(this.mobile) === "function", () => this.mobile(this.state)],
        [() => !this.state.isMobile && typeof(this.desktop) === "function", () => <WindowSetDesktop key={this.props.wdwId} wdwSettings={this.props} onResize={this.onResize} state={this.state}>{this.desktop(this.state)}</WindowSetDesktop>],
        [true, null]
    )}{this.state.modals}</>;
    
    setTitle = newTitle => {
        this.setVal({wdwTitle: newTitle});
        var apps = AppManager.getOpenApps();
        apps.find(a => a.id === this.props.wdwId).params.title = newTitle;
        CoreTools.emit("appsUpdated", apps);
    }

    close = () => AppManager.close(this.props.wdwId);
}

const Panels = {
    Window: Window,
    Modal: Modal
};

export default Panels;