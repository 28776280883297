import Icon from "../../../directives/Icon";
import IElements from "../../../directives/IElements";
import TView from "../../../directives/TView";
import BaseApi from "../../../services/BaseApi";
import CoreTools from "../../../services/CoreTools";
import Panels from "../../../shell/windows/Panels";

const Contact = {
    singleInstance: true,
    name: "Contact",
    title: "Contact",
    description: "Contact",
    menuIcon: <Icon name="id-card" size={18} />,
    taskbarIcon: <Icon name="id-card" size={20} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class Wdw extends Panels.Window {
        contactId = this.props.params.contactId;
        
        initState = {
            contact: false,
            isLoaded: true
        };

        api = {
            contactById: id => cb => BaseApi.push("api", "contacts", "contactById", {id: id}, cb)
        };

        routines = {
            load: () => this.api.contactById(this.contactId)(results => {
                console.log("Contact:", results);
                if (results.success) {
                    this.setVal({
                        contact: results.contact,
                        names: results.names,
                        phones: results.phones,
                        emails: results.emails,
                        dobs: results.dobs,
                        addresses: results.addresses,
                        applications: results.applications,
                        flinks: results.flinks
                    }, () => this.setTitle(`Contact - ${this.state.names.find(e => e.id === this.state.contact.nameId)?.firstName || "N/A"} ${this.state.names.find(e => e.id === this.state.contact.nameId)?.lastName || "N/A"}`));
                }
            })
        };

        onMount = () => {
            this.routines.load();
        };

        menuBar = () => <IElements.MenuBar.HeaderBar menu={[
            {
                caption: "File", 
                menu: [
                    {caption: "Refresh", onClick: () => this.routines.load()},
                    "line",
                    {caption: "Exit", onClick: this.close}
                ]
            },
            {caption: "View", disabled: true},
            {caption: "Help", disabled: true}
        ]} />;

        scrollBoxResize = () => null;
        onResize = (width, height) => {
            this.scrollBoxResize();
        };


        currentDetails = () => <table>
            <tbody>
                <tr><td>Firstname:</td><td>{this.state.names.find(e => e.id === this.state.contact.nameId)?.firstName || "N/A"}</td></tr>
                <tr><td>Lastname:</td><td>{this.state.names.find(e => e.id === this.state.contact.nameId)?.lastName || "N/A"}</td></tr>
                <tr><td>Phone:</td><td>{this.state.phones.find(e => e.id === this.state.contact.phoneId)?.phone || "N/A"}</td></tr>
                <tr><td>Email:</td><td>{this.state.emails.find(e => e.id === this.state.contact.emailId)?.email || "N/A"}</td></tr>
                <tr><td>Date Of Birth:</td><td>{this.state.dobs.find(e => e.id === this.state.contact.dobId)?.dob || "N/A"}</td></tr>
            </tbody>
        </table>;


        body = () => <div>
            <div style={{maxWidth: "800px"}}>
                <TView.Header>Contact Info</TView.Header>
                <TView.Body>
                    <TView.Table>
                        <TView.Column colSpan="6">
                            <TView.FieldView title="Firstname" content={this.state.names.find(e => e.id === this.state.contact.nameId)?.firstName || "N/A"} />
                            <TView.FieldView title="Lastname" content={this.state.names.find(e => e.id === this.state.contact.nameId)?.lastName || "N/A"} />
                            <TView.FieldView title="Phone" content={this.state.phones.find(e => e.id === this.state.contact.phoneId)?.phone || "N/A"} />
                            <TView.FieldView title="E-mail Address" content={this.state.emails.find(e => e.id === this.state.contact.emailId)?.email || "N/A"} />
                            <TView.FieldView title="Date Of Birth" content={this.state.dobs.find(e => e.id === this.state.contact.dobId)?.dob || "N/A"} />
                            <TView.FieldView title="Date Created" content={CoreTools.date.lhDateTime(this.state.contact.stampCreated)} />
                            <TView.FieldView title="Date Last Used" content={CoreTools.date.lhDateTime(this.state.contact.stampLastUsed)} />
                        </TView.Column>
                        <TView.Column colSpan="6">
                            <TView.FieldView title="Street Number" content={this.state.addresses.find(e => e.id === this.state.contact.addressId)?.streetNumber || "N/A"} />
                            <TView.FieldView title="Street Name" content={this.state.addresses.find(e => e.id === this.state.contact.addressId)?.streetName || "N/A"} />
                            <TView.FieldView title="Unit" content={this.state.addresses.find(e => e.id === this.state.contact.addressId)?.unit || "N/A"} />
                            <TView.FieldView title="City" content={this.state.addresses.find(e => e.id === this.state.contact.addressId)?.city || "N/A"} />
                            <TView.FieldView title="State" content={this.state.addresses.find(e => e.id === this.state.contact.addressId)?.state || "N/A"} />
                            <TView.FieldView title="Zip Code" content={this.state.addresses.find(e => e.id === this.state.contact.addressId)?.zipCode || "N/A"} />
                            <TView.FieldView title="Country" content={this.state.addresses.find(e => e.id === this.state.contact.addressId)?.country || "N/A"} />
                        </TView.Column>
                    </TView.Table>
                </TView.Body>
            </div>
            {(this.state.names?.length || 0) > 1 && <div style={{maxWidth: "1000px"}}>
                <TView.Header>Multiple Names On File</TView.Header>
                <TView.Body style={{backgroundColor: "#EEEEEE"}}>
                    {this.state.names.map(name => <TView.Table key={name.id} style={name.id === this.state.contact.nameId ? {backgroundColor: "#FFFFFF"} : null}>
                        <TView.Column colSpan="4">
                            <TView.FieldView title="Firstname" content={name.firstName || "N/A"} />
                        </TView.Column>
                        <TView.Column colSpan="4">
                            <TView.FieldView title="Lastname" content={name.lastName || "N/A"} />
                        </TView.Column>
                        <TView.Column colSpan="4">
                            <TView.FieldView title="Date Last Used" content={CoreTools.date.lhDateTime(name.stampLastUsed)} />
                        </TView.Column>
                    </TView.Table>)}
                </TView.Body>
            </div>}
            {(this.state.phones?.length || 0) > 1 && <div style={{maxWidth: "800px"}}>
                <TView.Header>Multiple Phone Numbers On File</TView.Header>
                <TView.Body style={{backgroundColor: "#EEEEEE"}}>
                    {this.state.phones.map(phone => <TView.Table key={phone.id} style={phone.id === this.state.contact.phoneId ? {backgroundColor: "#FFFFFF"} : null}>
                        <TView.Column colSpan="6">
                            <TView.FieldView title="Phone" content={phone.phone || "N/A"} />
                        </TView.Column>
                        <TView.Column colSpan="6">
                            <TView.FieldView title="Date Last Used" content={CoreTools.date.lhDateTime(phone.stampLastUsed)} />
                        </TView.Column>
                    </TView.Table>)}
                </TView.Body>
            </div>}
            {(this.state.emails?.length || 0) > 1 && <div style={{maxWidth: "800px"}}>
                <TView.Header>Multiple E-mail Addresses On File</TView.Header>
                <TView.Body style={{backgroundColor: "#EEEEEE"}}>
                    {this.state.emails.map(email => <TView.Table key={email.id} style={email.id === this.state.contact.emailId ? {backgroundColor: "#FFFFFF"} : null}>
                        <TView.Column colSpan="6">
                            <TView.FieldView title="E-mail Address" content={email.email || "N/A"} />
                        </TView.Column>
                        <TView.Column colSpan="6">
                            <TView.FieldView title="Date Last Used" content={CoreTools.date.lhDateTime(email.stampLastUsed)} />
                        </TView.Column>
                    </TView.Table>)}
                </TView.Body>
            </div>}
            {(this.state.dobs?.length || 0) > 1 && <div style={{maxWidth: "800px"}}>
                <TView.Header>Multiple Date Of Births On File</TView.Header>
                <TView.Body style={{backgroundColor: "#EEEEEE"}}>
                    {this.state.dobs.map(dob => <TView.Table key={dob.id} style={dob.id === this.state.contact.dobId ? {backgroundColor: "#FFFFFF"} : null}>
                        <TView.Column colSpan="6">
                            <TView.FieldView title="Date Of Birth" content={dob.dob || "N/A"} />
                        </TView.Column>
                        <TView.Column colSpan="6">
                            <TView.FieldView title="Date Last Used" content={CoreTools.date.lhDateTime(dob.stampLastUsed)} />
                        </TView.Column>
                    </TView.Table>)}
                </TView.Body>
            </div>}
            {(this.state.addresses?.length || 0) > 1 && <div style={{maxWidth: "1200px"}}>
                <TView.Header>Multiple Street Addresses On File</TView.Header>
                <TView.Body style={{backgroundColor: "#EEEEEE"}}>
                    {this.state.addresses.map(address => <TView.Table key={address.id} style={address.id === this.state.contact.addressId ? {backgroundColor: "#FFFFFF"} : null}>
                        <TView.Column colSpan="1">
                            <TView.FieldView title="Street #" content={address.streetNumber || "N/A"} />
                        </TView.Column>
                        <TView.Column colSpan="3">
                            <TView.FieldView title="Street Name" content={address.streetName || "N/A"} />
                        </TView.Column>
                        <TView.Column colSpan="1">
                            <TView.FieldView title="Unit" content={address.unit || "N/A"} />
                        </TView.Column>
                        <TView.Column colSpan="2">
                            <TView.FieldView title="City" content={address.city || "N/A"} />
                        </TView.Column>
                        <TView.Column colSpan="1">
                            <TView.FieldView title="State" content={address.state || "N/A"} />
                        </TView.Column>
                        <TView.Column colSpan="1">
                            <TView.FieldView title="Zip Code" content={address.zipCode || "N/A"} />
                        </TView.Column>
                        <TView.Column colSpan="1">
                            <TView.FieldView title="Country" content={address.country || "N/A"} />
                        </TView.Column>
                        <TView.Column colSpan="2">
                            <TView.FieldView title="Date Last Used" content={CoreTools.date.lhDateTime(address.stampLastUsed)} />
                        </TView.Column>
                    </TView.Table>)}
                </TView.Body>
            </div>}
            <div style={{maxWidth: "1200px"}}>
                <TView.Header>Applications</TView.Header>
                <TView.Body>
                    {this.state.applications?.map(app => <div key={app.id}>
                        <TView.Table>
                            <TView.Column colSpan="2">
                                <TView.FieldView title="Flow" content={app.formName} />
                            </TView.Column>
                            <TView.Column colSpan="4">
                                <TView.FieldView title="ID" content={app.formId} />
                            </TView.Column>
                            <TView.Column colSpan="3">
                                <TView.FieldView title="Status" content={app.status} />
                            </TView.Column>
                            <TView.Column colSpan="3">
                                <TView.FieldView  title="Date" content={CoreTools.date.lhDateTime(app.stampCreated)} />
                            </TView.Column>
                        </TView.Table>
                    </div>)}
                </TView.Body>
            </div>
            {(this.state.flinks?.length ? true : false) && <div style={{maxWidth: "800px"}}>
                <TView.Header>Flinks</TView.Header>
                <TView.Body>
                    {this.state.flinks?.map(flinks => <TView.Table key={flinks.id}>
                            <TView.Column colSpan="8">
                                <TView.FieldView title="requestId" content={flinks.requestId} />
                            </TView.Column>
                            <TView.Column colSpan="3">
                                <TView.FieldView  title="Date" content={CoreTools.date.lhDateTime(flinks.stampCreated)} />
                            </TView.Column>
                        </TView.Table>)}
                </TView.Body>
            </div>}
        </div>;


        desktop = (state) => state.contact && <table style={{width: "100%", height: "100%", borderCollapse: "collapse", borderSpacing: "0px"}}>
            <tbody>
                <tr><td style={{padding: "0px"}}>{this.menuBar()}</td></tr>
                {/* <tr><td style={{padding: "0px", borderStyle: "solid", borderWidth: "1px 0px 1px 0px", borderColor: "#aaaaaa"}}>{this.SearchBar()}</td></tr> */}
                <tr><td style={{padding: "0px", height: "100%", width: "100%"}}><IElements.CompElems.ScrollBox onResize={ctrls => this.scrollBoxResize = ctrls}>{this.body()}</IElements.CompElems.ScrollBox></td></tr>
            </tbody>
        </table>;
    }
};
export default Contact;