import CoreTools from "../../services/CoreTools";
import React from "react";
import Icon from "../../directives/Icon";
import AppManager from "../../services/AppManager";
import FuncCompTools from "../../services/FuncCompTools";

const styles = state => ({
    container: {
        position: "absolute",
        top: state.fullscreen ? "0px" : `${state.top}px`,
        left: state.fullscreen ? "0px" : `${state.left}px`,
        width: state.fullscreen ? `${state.screenWidth}px` : `${state.width}px`,
        height: state.fullscreen ? `${state.screenHeight - CoreTools.state.taskbarHeight()}px` : `${state.height}px`
    },
    titleBar: {
        padding: "3px",
        cursor: state.fullscreen ? "default" : "move",
        color: "var(--color-taskbar-font)",
        borderRadius: state.fullscreen ? "0px 0px 0px 0px" : "9px 9px 0px 0px",
        backgroundImage: "linear-gradient(var(--color-taskbar-background), var(--color-taskbar-shade))",
        backgroundColor: "var(--color-taskbar-background)",
        display: "flex",
        justifyContent: "space-between",
        height: "28px",
        lineHeight: "28px",
        verticalAlign: "middle",
        userSelect: "none"
    },
    body: {
        backgroundColor: "#FFFFFF",
        height: state.fullscreen ? `${state.screenHeight - CoreTools.state.taskbarHeight() - 28}px` : `${state.height - 28}px`,
        borderStyle: "solid",
        borderWidth: state.fullscreen ? "0px 0px 0px 0px" : "0px 2px 2px 2px",
        borderColor: "var(--color-taskbar-background)",
        overflow: "hidden"
    },
    cornerDrag: {
        position: "absolute",
        bottom: "-5px",
        right: "3px",
        display: state.fullscreen ? "none" : "block",
        cursor: "crosshair",
        margin: "0px",
        fontSize: "20px",
        color: "var(--color-taskbar-background)",
        opacity: 0.5
    }
});

const WindowSetDesktop = props => {
    const [state, setState] = React.useState(AppManager.getViewState(props.wdwSettings.wdwId) || {
        fullscreen: props.wdwSettings.applet.startFullscreen,
        allowResize: props.wdwSettings.applet.allowResize,
        width: props.wdwSettings.applet.startWidth || 800,
        height: props.wdwSettings.applet.startHeight || 600,
        top: ++CoreTools.state.startTop * 20,
        left: ++CoreTools.state.startLeft * 20,
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight
    });

    const setVal = FuncCompTools.stateHandler(() => state, setState);
    const setRef = FuncCompTools.refHandler();
    
    const onResize = elem => {
        if (typeof(props.onResize) === "function") {
            const target = elem || setRef.get("container");
            props.onResize(target.offsetWidth - (!state.fullscreen ? 0 : 4), target.offsetHeight + 6 - (setRef.get("titleBar")?.offsetHeight || 0));
        }
    };

    const constrain = event => {
        const parent = setRef.get("container") || event?.target.parentNode || false;
        if (parent) {
            if (!state.fullscreen) {
                if (parent.offsetLeft < 0) {parent.style.left = "0px";}
                if (parent.offsetLeft + parent.offsetWidth > window.innerWidth) {parent.style.left = `${window.innerWidth - parent.offsetWidth}px`;}
                if (parent.offsetTop < 0) {parent.style.top = "0px";}
                if (parent.offsetTop + parent.offsetHeight > window.innerHeight - CoreTools.state.taskbarHeight() - 2) {
                    parent.style.top = `${window.innerHeight - CoreTools.state.taskbarHeight() - parent.offsetHeight - 2}px`;
                }
            } else {
                parent.style.top = "0px";
                parent.style.left = "0px";
                parent.style.width = `${state.screenWidth}px`;
                parent.style.height = `${window.innerHeight - CoreTools.state.taskbarHeight()}px`;
            }
            setTimeout(onResize);
        }
    };

    React.useEffect(() => {
        const wHolder = [
            CoreTools.on("screenSize", (w, h) => setVal({screenWidth: w, screenHeight: h}, constrain))
        ];
        return () => {
            wHolder.forEach(k => k());
            AppManager.setViewState(props.wdwSettings.wdwId, state);
        };
    });

    const move = event => {
        event.preventDefault();
        const parent = setRef.get("container") || event.target.parentNode;
        let pX = parent.offsetLeft;
        let pY = parent.offsetTop;
        let mX = event.clientX;
        let mY = event.clientY;
        let inMove = false;
        const move = sEvent => {
            if (sEvent.buttons !== 1) {
                window.removeEventListener("mousemove", move);
                if (inMove) {
                    constrain(event);
                    setVal({top: parent.offsetTop, left: parent.offsetLeft});
                }
            } else if (inMove) {
                parent.style.left = (pX - mX + sEvent.clientX) + "px";
                parent.style.top = (pY - mY + sEvent.clientY) + "px";
                constrain(event);
            } else if (
                sEvent.clientX - mX > 25 ||
                sEvent.clientX - mX < -25 ||
                sEvent.clientY - mY > 25 ||
                sEvent.clientY - mY < -25
            ) {inMove = true;}
        };
        window.addEventListener("mousemove", move);
    };

    const resize = event => {
        event.preventDefault();
        const parent = setRef.get("container") || event.target.parentNode;
        let pX = parent.offsetWidth;
        let pY = parent.offsetHeight;
        let mX = event.clientX;
        let mY = event.clientY;
        const sizeMove = sEvent => {
            if (sEvent.buttons !== 1) {
                window.removeEventListener("mousemove", sizeMove);
            } else if (parent) {
                if (pX - mX + sEvent.clientX >= (props.wdwSettings.applet.minWidth || 0)) {parent.style.width = (pX - mX + sEvent.clientX) + "px";}
                if (
                    parent.offsetTop + pY - mY + sEvent.clientY > state.screenHeight - CoreTools.state.taskbarHeight() - 2
                ) {
                    parent.style.height = (state.screenHeight - CoreTools.state.taskbarHeight() - 2 - parent.offsetTop) + "px";
                } else if (pY - mY + sEvent.clientY < (props.wdwSettings.applet.minHeight || 0)) {
                    parent.style.height = (props.wdwSettings.applet.minHeight || 0) + "px";
                } else {
                    parent.style.height = (pY - mY + sEvent.clientY) + "px";
                }

                if (parent.offsetLeft + parent.offsetWidth > window.innerWidth) {
                    parent.style.width = (window.innerWidth - parent.offsetLeft) + "px";
                }
                if (parent.offsetTop + parent.offsetHeight > window.innerHeight - CoreTools.state.taskbarHeight()) {
                    parent.style.height = (window.innerHeight - CoreTools.state.taskbarHeight() - parent.offsetTop) + "px";
                }
                setVal({height: parent.offsetHeight, width: parent.offsetWidth});
                onResize();
            }
        };
        window.addEventListener("mousemove", sizeMove);
    };

    const control = event => {
        event.preventDefault();
        event.stopPropagation();
        AppManager.setTop(props.wdwSettings.wdwId);
        return {
            toggleFullscreen: () => {
                if (!state.fullscreen && props.wdwSettings.applet.allowFullscreen) {
                    setVal({fullscreen: true}, constrain);
                } else if (state.fullscreen && props.wdwSettings.applet.allowWindowed) {
                    setVal({fullscreen: false}, constrain);
                }
            },
            move: () => {
                if (!state.fullscreen) {move(event);}
            },
            minimize: () => AppManager.minimize(props.wdwSettings.wdwId),
            close: () => AppManager.close(props.wdwSettings.wdwId)
        };
    };
    
    return <div
        ref={setRef.set("container")}
        style={styles(state).container}
    ><div
        style={styles(state).titleBar}
        onDoubleClick={e => control(e).toggleFullscreen()}
        onMouseDown={e => control(e).move()}
        ref={setRef.set("titleBar")}
    ><span style={{pointerEvents: "none", paddingLeft: "5px"}}>
        {props.wdwSettings.applet.menuIcon}{props.state.wdwTitle || props.wdwSettings.applet.title}
    </span><span>
        <Icon name="minimize" size="20px" margin={10} title="Minimize" style={{cursor: "pointer"}} onClick={e => control(e).minimize()} />
        {props.wdwSettings.applet.allowWindowed && <Icon name="restore" size="20px" margin={10} title="Restore/Maximize" style={{cursor: "pointer"}} onClick={e => control(e).toggleFullscreen()} />}
        <Icon name="cancel" size="22px" title="Close" style={{cursor: "pointer"}} onClick={e => control(e).close()} />
    </span></div><div style={styles(state).body}>{props.children}</div>{state.allowResize && <Icon name="maximize" margin={0} style={styles(state).cornerDrag} onMouseDown={e => resize(e)} />}</div>;
};

export default WindowSetDesktop;