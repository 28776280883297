import AppManager from "../../../services/AppManager";
import React from "react";
import Icon from "../../../directives/Icon";
import BaseApi from "../../../services/BaseApi";
import IElements from "../../../directives/IElements";
import Modals from "../../../shell/windows/Modal";
import CoreTools from "../../../services/CoreTools";
import Panels from "../../../shell/windows/Panels";

const styles = ( row_key ) => ({
    table: {
        width: "100%",
        height: "100%",
        borderCollapse: "collapse"
    },
});


class EditDocumentModal extends React.Component {
    constructor(props) {
        super(props);
        Modals.Standard(this, {
            id: props.document?.id || null,
            name: props.document?.name || "",
            parentId: props?.parentId || props.document?.parentId || 0,
            type: props.document?.type || "",
            description: props.document?.description ||"",
        }, {
            menuIcon: null, 
            title: props.document?.id ? "Edit Document" : "Add Document",
            width: "auto",
            height: "auto",
            zIndex: 1,
            onClose: props.onClose
        }, () => {});
    }
    save = () => this.props.onSave({
        id: this.state.id,
        name: this.state.name,
        parentId: this.state.parentId,
        type: this.state.type,
        description: this.state.description,
    });
    edit = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InputMedium caption="Name" value={this.state.name} onChange={nv => this.setVal({name: nv})} /></div>
        <div><IElements.Form.InputMedium caption="Description" value={this.state.description} onChange={nv => this.setVal({description: nv})} /></div>
        <div><IElements.Form.SelectLarge disabled={this.state.id !== null} caption="Type" value={this.state.type} onChange={nv => this.setVal({type: nv})} options={[["File",0],["Folder",1]]} /></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Delete", onClick: () => this.setVal({delete: true}), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.props.onClose()},
            {content: "Save", onClick: this.save, disabled: () => !this.state.name}
        ]} /></div>
    </div>;
    delete = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InfoLarge title={`Delete the Document '${this.state.name}'?`} /></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Confirm Delete", onClick: () => this.props.onDelete(this.state.id), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.setVal({delete: false})}
        ]} /></div>
    </div>;
    desktop = () => this.state.delete ? this.delete() : this.edit();
}

const Exp = {
    singleInstance: true,
    menuGroup: "Development",
    name: "Documents",
    title: "Documents",
    description: "Documents and Attachments",
    menuIcon: <Icon name="document" size={18} />,
    taskbarIcon: <Icon name="document" size={22} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class Documents extends Panels.Window {
        initState = {
            documents: [],
            selected: false,
            modal: null,
            contextMenu: null
        };
        api = {
            getDocuments: callback => BaseApi.push("api", "documents", "getDocuments", {}, callback),
            deleteDocument: (id, callback) => BaseApi.push("api", "documents", "deleteDocument", {id: id}, callback),
            saveDocument: (document, callback) => BaseApi.push("api", "documents", "saveDocument", {document: document}, callback),
        };
        routines = {
            getDocuments: () => this.api.getDocuments(records => {
                let selected = CoreTools.switch(
                    [this.state.documents.includes(this.state.selected), ["document", this.state.selected.id]],
                    [true, ["none"]]
                );

                records.documents.forEach(document => {
                    const count = records.attachments.filter(attachment => attachment.documentId === document.id);
                    document.attachments = parseInt(count) ? count + " attachments" : "";
                });
                            
                this.setVal({
                    documents: records.documents ?? [],
                }, () => this.setVal({selected: CoreTools.switch(
                    [selected[0] === "document", this.state.documents.find(u => u.id === selected[1]) || false],
                    [true, false]
                )}));
            }),
            saveDocument: document => this.api.saveDocument(document, results => {if (results.success) {this.setVal({modal: null}); this.routines.getDocuments();}}),
            deleteDocument: id => this.api.deleteDocument(id, results => {if (results.success) {this.setVal({modal: null}); this.routines.getDocuments();}}),
        };

        onMount = () => {
            this.routines.getDocuments();
        };
        onUnMount = () => {};

        menuBar = state => <IElements.MenuBar.HeaderBar menu={[
            {
                caption: "File",
                menu: [
                    {caption: "Refresh", onClick: e => this.routines.getDocuments()},
                    "line",
                    {caption: "Exit", onClick: e => this.close()}
                ]
            },
            {
                caption: "Edit",
                menu: [
                    {caption: "Unselect Document", onClick: e => this.setVal({selected: false}), disabled: !state.selected},
                    "line",
                    {caption: "Add Folder Or Document", onClick: e => this.setVal({modal: <EditDocumentModal key={0} onSave={document => this.routines.saveDocument(document)} onClose={() => this.setVal({modal: null})} />})},
                    "line",
                    {caption: "Edit Selected Document", onClick: e => this.setVal({modal: <EditDocumentModal key={state.selected.id} onSave={document => this.routines.saveDocument(document)} onDelete={id => this.routines.deleteDocument(id)} document={state.selected} onClose={() => this.setVal({modal: null})} />}), disabled: !state.documents.includes(state.selected)},
                ]
            },
            {
                caption: "View",
                menu: [
                    {caption: "View Selected Document Contents", onClick: e => AppManager.open("Document", {document: this.state.selected})},
                ]
            },
            {caption: "Help", disabled: true}
        ]} />;



        documents = state => <IElements.Tables.TreeView 
            header={[
                ["name", "Name"],
                ["attachments", "Attachments"],
                ["description", "Description"],
            ]}
            useKey={r => r.id}
            selected={this.state.selected}
            body={this.state.documents}
            onItemClick={this.documentClick}
            onItemContextMenu={this.documentContext}
            onFolderContextMenu={this.folderContext}
        />;

        documentClick = (event, record, field) => {
            AppManager.open("Document", {document: record});
            this.setVal({selected: record});
        };

        documentContext = (event, record, field) => {
            event.preventDefault();
            this.setVal({contextMenu: <IElements.ContextMenu
                title="Document Options"
                menu={[
                    "Document",
                    `${record.name} ${record.description}`,
                    "line",
                    {caption: "Select Document", onClick: () => this.setVal({selected: record})},
                    {caption: "Edit Document", onClick: () => this.setVal({modal: <EditDocumentModal key={record.id} onSave={document => this.routines.saveDocument(document)} onDelete={id => this.routines.deleteDocument(id)} document={record} onClose={() => this.setVal({modal: null})} />})},
                ]
            } event={event} onClose={() => this.setVal({contextMenu: null})} />})
        };

        folderContext = (event, record, field) => {
            event.preventDefault();
            this.setVal({contextMenu: <IElements.ContextMenu
                title="Folder Options"
                menu={[
                    "Folder",
                    `${record.name}`,
                    "line",
                    {caption: "Edit Folder", onClick: () => this.setVal({modal: <EditDocumentModal key={record.id} onSave={document => this.routines.saveDocument(document)} onDelete={id => this.routines.deleteDocument(id)} document={record} onClose={() => this.setVal({modal: null})} />})},
                    {caption: "Add Folder Or Document", onClick: () => this.setVal({modal: <EditDocumentModal parentId={record.id} key={0} onSave={document => this.routines.saveDocument(document)} onClose={() => this.setVal({modal: null})} />})},
                ]
            } event={event} onClose={() => this.setVal({contextMenu: null})} />})
        };

        document = state => <tr>
            <td style={{height: "50%", width: "100%", overflow: "auto", padding: "0px", verticalAlign: "top"}}><IElements.Titles.PanelHeader>Document</IElements.Titles.PanelHeader><IElements.Tables.ListView
                style={{width: "100%"}}
                body={[
                    ["Name", state.selected.name],
                    ["Description", state.selected.description],
                ]}
            /></td>
        </tr>;


        mobile = state => <div>Not Available</div>;
        desktop = state => {

        return <><table style={styles().table}>
            <tbody>
                <tr><td colSpan={2} style={{padding: "0px"}}>{this.menuBar(state)}</td></tr>
                <tr><td colSpan={2} style={{padding: "0px"}}><IElements.Titles.PanelHeader>Documents</IElements.Titles.PanelHeader></td></tr>
                <tr><td colSpan={2} style={{height: "100%", overflow: "auto", padding: "0px", verticalAlign: "top"}}>{this.documents(state)}</td></tr>
            </tbody>
        </table>{state.modal}{state.contextMenu}</>};
    }
};
export default Exp;