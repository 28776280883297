import CoreTools from "./CoreTools";

const Socket = address => {
    let socket = false;
    let watchers = [];
    let pingTimer = false;
    let alive = false;
    let reconnect = false;
    const emit = (name, value) => watchers.filter(w => w.name === name && typeof(w.cb) === "function").forEach(w => w.cb(value));
    const send = (name, value) => {
        if (socket && state() === 1) {
            socket.send(JSON.stringify({action: name, data: value === undefined ? null : value}));
        }
    };
    const state = () => socket ? socket.readyState : -1;
    const close = resp => {
        if (socket) {socket.close(); socket = false;}
        if (pingTimer) {clearInterval(pingTimer); pingTimer = false;}
        if (resp && reconnect) {setTimeout(connect, 1000);}
    };
    const end = () => {close(); watchers = [];};
    const on = (name, cb) => {const watcher = {name: name, cb: cb}; watchers.push(watcher); return () => CoreTools.remove(watchers, watcher);};
    const connect = () => {
        if (socket) {return;}
        socket = new WebSocket(address);
        socket.onopen = event => {
            emit("open", event);
            console.log("Socket Open:", address);
        };
        socket.onclose = event => {
            console.log("Socket Closed:", state(), event);
            emit("close", event);
            if (socket) {close(true);}
        };
        socket.onerror = event => {
            console.log("Socket Error:", state(), event);
            emit("error", event);
            if (socket) {close(true);}
        };
        socket.onmessage = event => {
            if (typeof(event.data) === "string") {
                try {
                    let rx = JSON.parse(event.data);
                    if (CoreTools.isObject(rx) && typeof(rx.action) === "string") {
                        if (rx.action === "pong") {alive = true;}
                        else {emit(rx.action, rx.data);}
                    }
                } catch (err) {
                    console.log("Invalid Input From Server", err, event);
                }
            }
        };
        alive = true;
        pingTimer = setInterval(() => {
            if (!alive) {if (socket) {close(true);}}
            else {alive = false; send("ping", {});}
        }, 10000);
    };
    return {
        reconnect: v => {reconnect = v;},
        connect: connect,
        close: close,
        on: on,
        send: send,
        state: state,
        end: end
    };
};
export default Socket;

// https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState