import React from "react";
import Panels from "../../../shell/windows/Panels";
import Icon from "../../../directives/Icon";
import IElements from "../../../directives/IElements";
import BaseApi from "../../../services/BaseApi";
import CoreTools from "../../../services/CoreTools";
import AudioPlayer from "../../../directives/AudioPlayer";


const styles = () => ({
    table: {
        width: "100%",
        height: "100%",
        borderCollapse: "collapse"
    },
    full: {
        width: "100%",
        height: "100%"
    }
});

class CallModal extends Panels.Modal {
    initState = {
        title: "Call",
        width: "auto",
        height: "auto",
        callLog: this.props.callLog
    };
    onMount = () => {
        this.setTitle("Call - " + CoreTools.asPhone(this.state.callLog.Direction === "inbound" ? this.state.callLog.FromNumber : this.state.callLog.ToNumber));
    };
    call = () => {};
    desktop = state => <table><tbody>
        <tr><td style={{textAlign: "right"}}>Contact #:</td><td>{CoreTools.asPhone(state.callLog.Direction === "inbound" ? state.callLog.FromNumber : state.callLog.ToNumber)}</td></tr>
        <tr><td style={{textAlign: "right"}}>Application #:</td><td>{CoreTools.asPhone(state.callLog.Direction === "inbound" ? state.callLog.ToNumber : state.callLog.FromNumber)}</td></tr>
        <tr><td style={{textAlign: "right"}}>Date/Time:</td><td>{CoreTools.date.lhDateTime(state.callLog.StampCreated)}</td></tr>
        <tr><td style={{textAlign: "right"}}>Direction:</td><td>{state.callLog.Direction}</td></tr>
        <tr><td style={{textAlign: "right"}}>Duration:</td><td>{state.callLog.CallDuration} Seconds</td></tr>
        {state.callLog.Voicemail && <tr><td colSpan={2} style={{textAlign: "center"}}><AudioPlayer key={state.callLog.Voicemail} icon="voicemail" title="Voicemail" filename={`https://assets.consumergenius.com/media/twilio/${state.callLog.Voicemail}`} /></td></tr>}
        {state.callLog.Recording && <tr><td colSpan={2} style={{textAlign: "center"}}><AudioPlayer key={state.callLog.Recording} icon="mic" title="Recording" filename={`https://assets.consumergenius.com/media/twilio/${state.callLog.Recording}`} /></td></tr>}
        <tr><td colSpan={2}><IElements.Form.ButtonGroupMedium buttons={[
            {content: "Call / SMS", onClick: () => this.call()},
            {content: "Close", onClick: () => this.props.onClose()}
        ]} /></td></tr>
    </tbody></table>;
    // desktop = state => <div>
    //     Contact: {CoreTools.asPhone(state.callLog.Direction === "inbound" ? state.callLog.FromNumber : state.callLog.ToNumber)}
    // </div>;
}

const Exp = {
    singleInstance: true,
    menuGroup: "Telecoms",
    name: "CallLogs",
    title: "Call Logs",
    description: "Call Logs",
    menuIcon: <Icon name="fa-solid fa-right-from-bracket" size={18} />,
    taskbarIcon: <Icon name="fa-solid fa-right-from-bracket" size={22} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    allowMobile: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class CallLogs extends Panels.Window {
        initState = {
            calls: [],
            modals: [],
            contextMenu: null,
            isLoaded: false
        };
        
        onMount = () => {
            this.api.getCallLogs(
                results => {
                    console.log("Loading Calls");
                    this.setVal({
                        calls: results.success ? results.data : [],
                        isLoaded: true
                    }, () => {
                        this.setTitle(`Call Logs - ${this.state.calls.length}`);
                    });
                }
            );
        };

        api = {
            getCallLogs: callback => BaseApi.push("api", "telecoms", "getCallLogs", {}, callback),
        };

        routines = {
            refresh: () => this.api.getCallLogs(results => this.setVal({calls: results.success ? results.data : []}, () => this.setTitle(`Call Logs - ${this.state.calls.length}`)))
        };
        
        userClick = (event, record, field) => {
            const callModal = <CallModal
                key={record.id}
                onClose={() => this.removeModal(callModal)}
                callLog={record.source}
            />;
            this.addModal(callModal);
        };

        Calls = () => <IElements.Tables.StdSpread 
            header={[
                ["date", "Date", "humanDate"],
                ["direction", "Direction"],
                ["from", "From"],
                ["to", "To"],
                ["duration", "Dur."],
                ["hasVoicemail", "Vm.", "voicemail"],
                ["hasRecording", "Rec.", "recording"],
                ["hasCallback", "Cb.", "callback"]
            ]}
            useKey={r => r.id}
            body={this.state.calls.map(r => ({
                id: r.id,
                date: r.StampCreated,
                humanDate: CoreTools.date.lhDateTime(r.StampCreated),
                direction: r.Direction,
                from: CoreTools.asPhone(r.FromNumber),
                to: CoreTools.asPhone(r.ToNumber),
                duration: CoreTools.toNumber(r.CallDuration),
                voicemail: r.Voicemail ? <Icon name="voicemail" margin={0} /> : "",
                hasVoicemail: r.Voicemail ? 1 : 2,
                recording: r.Recording ? <Icon name="mic" margin={0} /> : "",
                hasRecording: r.Recording ? 1 : 2,
                callback: r.Callback,
                hasCallback: r.Callback ? 1 : 2,
                source: r
            }))}
            onClick={this.userClick}
            // onContextMenu={this.userContext}
        />;

        scrollBoxResize = () => null;
        onResize = (width, height) => {
            this.scrollBoxResize();
        };

        mobile = state => {};
        desktop = state => <table style={{width: "100%", height: "100%", borderCollapse: "collapse", borderSpacing: "0px"}}>
            <tbody>
                <tr><td colSpan={2} style={{padding: "0px"}}><IElements.Titles.PanelHeader>Calls</IElements.Titles.PanelHeader></td></tr>
                <tr><td colSpan={2} style={{padding: "0px", width: "100%", height: "100%"}}><IElements.CompElems.ScrollBox  onResize={ctrls => this.scrollBoxResize = ctrls}>{this.Calls()}</IElements.CompElems.ScrollBox></td></tr>
            </tbody>
        </table>;
    }
};
export default Exp;