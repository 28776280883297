import CoreTools from "../services/CoreTools";
import {Chart, registerables} from "chart.js";
import React from "react";

Chart.register(...registerables);

const defaultColors = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(64, 255, 64, 1)',
    'rgba(255, 64, 64, 1)',
    'rgba(64, 64, 255, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(64, 255, 64, 1)',
    'rgba(255, 64, 64, 1)',
    'rgba(64, 64, 255, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(64, 255, 64, 1)',
    'rgba(255, 64, 64, 1)',
    'rgba(64, 64, 255, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(64, 255, 64, 1)',
    'rgba(255, 64, 64, 1)',
    'rgba(64, 64, 255, 1)',
];

const CanvasTemplate = props => <canvas ref={props.onLoad} style={props.style}></canvas>;

const UChart = props => {
    const [chart, setChart] = React.useState(null);
    const [canvas, setCanvas] = React.useState(null);
    const loadChart = ref => {
        const render = () => !canvas ? setTimeout(render) : (() => {
            if (Array.isArray(props.values)) {
                let labels = [];
                let values = [];
                let colors = [];
                if (Array.isArray(props.values)) {
                    props.values.forEach(value => CoreTools.switch(
                        [Array.isArray(value), () => {
                            labels.push(value[0]);
                            values.push(value[1]);
                            colors.push(value[2] || defaultColors[colors.length]);
                        }],
                        [CoreTools.isObject(value), () => {
                            labels.push(value.label);
                            values.push(value.value);
                            colors.push(value.color || defaultColors[colors.length]);
                        }]
                    ));
                } else if (CoreTools.isObject(props.values)) {
                    Object.keys(props.values).forEach(key => {
                        labels.push(key);
                        values.push(props.values[key]);
                    });
                }
                const chartData = {
                    labels: labels,
                    datasets: [{
                        label: props.title || "",
                        data: values,
                        backgroundColor: colors,
                        hoverOffset: 4,
                        options: {
                            responsive: false,
                            maintainAspectRatio: false
                        }
                    }]
                };
                if (chart) {
                    chart.data = chartData;
                    chart.update();
                } else {
                    setChart(new Chart(canvas.getContext('2d'), {
                        type: props.type || "bar",
                        data: chartData,
                        options: {
                            plugins: {
                                legend: {
                                    display: CoreTools.switch(
                                        [props.type === "bar", false],
                                        [props.type === "line", false],
                                        [true, true]
                                    )
                                }
                            }
                        }
                    }));
                }
            }
        })();
        if (ref) {
            setCanvas(ref);
            render();
        }
    };
    
    return <div className={props.className} style={props.outerStyle}><div>{props.title}</div><div><CanvasTemplate onLoad={loadChart} style={props.style} /></div></div>;
};
export default UChart;