import React from "react";
import CoreTools from "../../services/CoreTools";
import Icon from "../../directives/Icon";

export default class ModalDesktop extends React.Component {
    state = {
        width: this.props.state.width || "auto",
        height: this.props.state.height || "auto",
        zIndex: this.props.state.zIndex || 1,
        top: -10000,
        left: -10000,
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight
    };
    containerRef = false;
    styles = () => ({
        container: {
            position: "fixed",
            top: `${this.state.top}px`,
            left: `${this.state.left}px`,
            width: typeof(this.state.width) === "number" ? `${this.state.width}px` : this.state.width,
            height: typeof(this.state.height) === "number" ? `${this.state.height}px` : this.state.height,
            zIndex: this.state.zIndex
        },
        titleBar: {
            padding: "3px",
            cursor: "move",
            color: "var(--color-taskbar-font)",
            borderRadius: "9px 9px 0px 0px",
            backgroundImage: "linear-gradient(var(--color-taskbar-background), var(--color-taskbar-shade))",
            backgroundColor: "var(--color-taskbar-background)",
            display: "flex",
            justifyContent: "space-between",
            height: "28px",
            lineHeight: "28px",
            verticalAlign: "middle",
            userSelect: "none"
        },
        body: {
            backgroundColor: "#CCCCCC",
            height: typeof(this.state.height) === "number" ? `${this.state.height - 28}px` : this.state.height,
            borderStyle: "solid",
            borderWidth: "0px 2px 2px 2px",
            borderColor: "var(--color-taskbar-background)",
            overflow: "auto"
        }
    });
    setVal = CoreTools.stateHandler(this);
    wHolder = CoreTools.watchHolder();

    componentDidMount = () => {
        this.setVal("mount");
        this.wHolder(CoreTools.on("screenSize", () => this.constrain()));
        setTimeout(() => {
            this.containerRef.style.left = (this.state.screenWidth / 2 - this.containerRef.offsetWidth / 2) + "px";
            this.containerRef.style.top = ((this.state.screenHeight - CoreTools.state.taskbarHeight()) / 2 - this.containerRef.offsetHeight / 2) + "px";
        });
    };

    componentWillUnmount = () => {
        this.setVal("unmount");
        this.wHolder("end");
    };
    constrain = event => {
        const target = event?.target?.parentNode || this.containerRef || false;
        if (target) {
            if (target.offsetLeft < 0) {target.style.left = "0px";}
            if (target.offsetLeft + target.offsetWidth > window.innerWidth) {target.style.left = `${window.innerWidth - target.offsetWidth}px`;}
            if (target.offsetTop < 0) {target.style.top = "0px";}
            if (target.offsetTop + target.offsetHeight > window.innerHeight - CoreTools.state.taskbarHeight() - 8) {
                target.style.top = `${window.innerHeight - CoreTools.state.taskbarHeight() - target.offsetHeight - 8}px`;
            }
        } else if (target) {
            target.style.top = "0px";
            target.style.left = "0px";
            target.style.width = `${this.state.screenWidth}px`;
            target.style.height = `${this.state.screenHeight - CoreTools.state.taskbarHeight()}px`;
        }
    };
    move = event => {
        event.preventDefault();
        let pX = event.target.parentNode.offsetLeft;
        let pY = event.target.parentNode.offsetTop;
        let mX = event.clientX;
        let mY = event.clientY;
        let inMove = false;
        const move = sEvent => {
            if (sEvent.buttons !== 1) {
                window.removeEventListener("mousemove", move);
                if (inMove) {
                    this.constrain(event);
                    this.setVal({
                        top: event.target.parentNode.offsetTop,
                        left: event.target.parentNode.offsetLeft
                    });
                }
            } else if (inMove) {
                event.target.parentNode.style.left = (pX - mX + sEvent.clientX) + "px";
                event.target.parentNode.style.top = (pY - mY + sEvent.clientY) + "px";
                this.constrain(event);
            } else if (
                sEvent.clientX - mX > 25 ||
                sEvent.clientX - mX < -25 ||
                sEvent.clientY - mY > 25 ||
                sEvent.clientY - mY < -25
            ) {inMove = true;}
        };
        window.addEventListener("mousemove", move);
    };
    control = event => {
        event.preventDefault();
        event.stopPropagation();
        return {
            move: () => this.move(event),
            close: () => this.props.state.onClose()
        };
    };
    render = () => <div
        style={this.styles().container}
        ref={r => this.containerRef = r}
    ><div
        style={this.styles().titleBar}
        onMouseDown={e => this.control(e).move()}
    ><span style={{pointerEvents: "none", paddingLeft: "5px"}}>
        {this.props.state.menuIcon}{this.props.state.title}
    </span><span>
        <Icon name="cancel" size="22px" title="Close" style={{cursor: "pointer"}} onClick={e => this.control(e).close()} />
    </span></div><div style={this.styles().body}>{this.props.children}</div></div>;
}