import React from "react";
import Icon from "../../../directives/Icon";
import Panels from "../../../shell/windows/Panels";

const styles = () => ({
    body: {
        width: "100%",
        height: "100%"
    },
    full: {
        width: "100%",
        height: "100%"
    }
});


const Exp = {
    singleInstance: true,
    menuGroup: "Telecoms",
    name: "CallSms",
    title: "Call / SMS",
    description: "Call / SMS",
    menuIcon: <Icon name="phone" size={18} />,
    taskbarIcon: <Icon name="phone" size={22} />,
    startFullscreen: false,
    allowFullscreen: false,
    allowWindowed: false,
    allowResize: false,
    allowMobile: true,
    startWidth: 500,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class CallSms extends Panels.Window {
        initState = {
            users: [],
            groups: [],
            groupMembers: [],
            selected: false,
            modal: null,
            contextMenu: null
        };
        api = {

        };
        routines = {

        };
        onMount = () => {};
        onUnmount = () => {};
        mobile = state => {};
        desktop = state => <table style={styles().body}>
            <tbody>
                <tr><td></td></tr>
                <tr><td style={styles().full}></td></tr>
            </tbody>
        </table>;
    }
};
export default Exp;