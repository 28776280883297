import CoreTools from "./CoreTools";

import Users from "../modules/administration/users/users";
import Settings from "../modules/preferences/settings/settings";
import Themes from "../modules/preferences/themes/themes";
import DomainWatchdog from "../modules/administration/domainWatchdog/domainWatchdog";

import Projects from "../modules/development/projects/projects";
import ProjectTasks from "../modules/development/projects/projectTasks";
import Documents from "../modules/development/documents/documents";
import Document from "../modules/development/documents/document";
import BugTracker from "../modules/development/bugTracker/bugTracker";

import AgentManager from "../modules/telecom/agentManager/agentManager";
import CallLogs from "../modules/telecom/callLogs/callLogs";
import ManageNumbers from "../modules/telecom/manageNumbers/manageNumbers";
import CallSms from "../modules/telecom/callSms/callSms";

import Contacts from "../modules/customers/contacts/Contacts";
import Leadspedia from "../modules/customers/leadspedia/Leadspedia";
// import PageLife from "../modules/customers/pageLife/pageLife";
import WebChat from "../modules/customers/pageLife/webChat";
import PageLife2 from "../modules/funnels/pageLife2";
import Contact from "../modules/customers/contacts/Contact";
import FunnelTrafficStats from "../modules/funnels/FunnelTrafficStats";

let lastWindowId = 0;
let openApps = [];

const apps = [
    // Administration
    Users,
    DomainWatchdog,

    // Preferences
    Settings,
    Themes,

    // Contacts
    Leadspedia,
    Contacts,
    Contact,
    // PageLife,
    PageLife2,
    WebChat,

    // Telecoms
    AgentManager,
    CallLogs,
    ManageNumbers,
    CallSms,

    //Funnels
    FunnelTrafficStats,

    // Development
    Projects,
    ProjectTasks,
    Documents,
    Document,
    BugTracker
];

const AppManager = {
    open: (appName, params) => {
        let Applet = apps.find(a => a.name === appName);
        if (Applet) {
            const open = () => {
                let id = ++lastWindowId;
                openApps.push({
                    id: id, 
                    elem: <Applet.Component key={id} wdwId={id} params={params || false} applet={Applet} />, 
                    params: Applet, 
                    state: {
                        visible: true,
                        viewState: false,
                        appState: false
                    }
                });
                CoreTools.emit("appsUpdated", openApps);
            };
            if (Applet.singleInstance) {
                let appInstance = openApps.find(oa => oa.params.name === appName);
                if (appInstance) {
                    appInstance.state.visible = true;        
                    CoreTools.remove(openApps, appInstance);
                    openApps.push(appInstance);
                    CoreTools.emit("appsUpdated", openApps);
                } else {
                    open();
                }
            } else {
                open();
            }
        }
    },
    close: wdwId => {
        let app = openApps.find(a => a.id === wdwId);
        if (app) {
            CoreTools.remove(openApps, app);
            CoreTools.emit("appsUpdated", openApps);
        }
    },
    minimize: wdwId => {
        let app = openApps.find(a => a.id === wdwId);
        if (app) {
            CoreTools.remove(openApps, app);
            if (app.state.visible) {
                app.state.visible = false;
                openApps.unshift(app);
            } else {
                app.state.visible = true;
                openApps.push(app);
            }
            CoreTools.emit("appsUpdated", openApps);
        }
    },
    taskbarClick: wdwId => {
        let app = openApps.find(a => a.id === wdwId);
        if (app) {
            if (openApps.indexOf(app) !== openApps.length - 1) {
                CoreTools.remove(openApps, app);
                openApps.push(app);
                app.state.visible = true;
            } else if (app.state.visible) {
                CoreTools.remove(openApps, app);
                openApps.unshift(app);
                app.state.visible = false;
            } else {
                CoreTools.remove(openApps, app);
                openApps.push(app);
                app.state.visible = true;
            }
            CoreTools.emit("appsUpdated", openApps);
        }
    },
    setTop: wdwId => {
        let app = openApps.find(a => a.id === wdwId);
        if (app && openApps.indexOf(app) !== openApps.length - 1) {
            CoreTools.remove(openApps, app);
            openApps.push(app);
            CoreTools.emit("appsUpdated", openApps);
        }
    },
    getOpenApps: () => openApps,
    getMenuItems: () => apps.filter(e => e.menuGroup),
    appParams: wdwId => openApps.find(oa => oa.id === wdwId),
    setViewState: (wdwId, state) => {
        let app = openApps.find(oa => oa.id === wdwId);
        if (app) {app.state.viewState = state;}
    },
    getViewState: wdwId => {
        let app = openApps.find(oa => oa.id === wdwId);
        if (app) {return app.state.viewState;}
        else {return false;}
    },
    setAppState: (wdwId, state) => {
        let app = openApps.find(oa => oa.id === wdwId);
        if (app) {app.state.appState = state;}
    },
    getAppState: wdwId => {
        let app = openApps.find(oa => oa.id === wdwId);
        if (app) {return app.state.appState;}
        else {return false;}
    }
};
export default AppManager;