import BaseApi from "./BaseApi";
import CoreTools from "./CoreTools";
import Socket from "./Socket";

const loadSettings = source => BaseApi.settings.get(results => {
    console.log("loadSettings", source, results);
    if (results.success) {
        const getSetting = (groupName, setttingName) => (results.records.find(s => s.groupName === groupName && s.settingName === setttingName) || {settingValue: ""}).settingValue;
        CoreTools.setTheme({
            "--color-taskbar-shade": getSetting("theme", "--color-taskbar-shade") || "rgba(0, 0, 0, 0.3)",
            "--color-taskbar-background": getSetting("theme", "--color-taskbar-background") || "#316CCC",
            "--color-taskbar-font": getSetting("theme", "--color-taskbar-font") || "#FFFFFF",
            "--color-window-banner": getSetting("theme", "--color-window-banner") || "#000000",
            "--color-window-active": getSetting("theme", "--color-window-active") || "#333333",
            "--color-window-highlight": getSetting("theme", "--color-window-highlight") || "#666666",
            "--color-window-font": getSetting("theme", "--color-window-font") || "#FFFFFF",
    
            "--image-desktop-background": getSetting("theme", "--image-desktop-background") || "url('/images/backgrounds/chong-wei-l_C_R1cULnA-unsplash.jpg')",
            "--color-hover-background": getSetting("theme", "--color-hover-background") || "rgba(49, 108, 204, 0.5)",
            "--color-hover-font": getSetting("theme", "--color-hover-font") || "#FFFFFF"
        });
    }
});
let apiAuthTimer = null;
const getApiAuthPackage = () => {
    if (UserService.user.valid) {
        clearTimeout(apiAuthTimer);
        BaseApi.security.apiAuthPackage(data => {
            if (data && data.success) {
                UserService.user.apiAuthPackage = data.apiAuthPackage;
                apiAuthTimer = setTimeout(getApiAuthPackage, data.expiresIn);
            }
        });
    }
};

const user = {
    valid: false,
    service: false,
    authkey: false,
    id: false,
    firstname: null,
    lastname: null,
    email: null,
    theme: false,
    apiAuthPackage: null
};

const userWatchers = [];
const stateWatchers = [];

const state = {};

const setState = values => {
    if (CoreTools.isObject(values)) {
        const keys = Object.keys(values);
        if (keys.length) {
            keys.forEach(key => state[key] = values[key]);
            stateWatchers.filter(w => !w.res.length || w.res.find(r => keys.find(k => k === r))).forEach(w => w.cb(state));
        }
    }
};

const userSocket = (() => {
    let socket = Socket("wss://crm.consumergenius.com/user");
    socket.reconnect(true);
    let sendCache = [];
    let authed = false;
    const send = (action, data) => {
        if (authed && socket.state() === 1) {
            socket.send(action, data);
        } else {
            sendCache.push({action: action, data: data});
        }
    };
    socket.on("ident", data => {
        if (data.success) {
            console.log("User Socket Online");
            authed = true;
            Object.keys(data.state).forEach(key => state[key] = data.state[key]);
            setUser({service: true});
            console.log("User Socket Ready");
        }
    });
    socket.on("close", () => {
        console.log("User Socket Closed");
        setUser({service: false});
    });
    socket.on("error", error => {
        console.log("User Socket Error", error);
        setUser({service: false});
    });
    socket.on("open", data => {
        console.log("User Socket Connected");
        socket.send("ident", {authkey: user.authkey});
    });
    socket.on("setState", setState);
    const close = () => {
        console.log("User Socket Closed Local");
        setUser({service: false});
        socket.close();
    };
    return {
        on: socket.on,
        send: send,
        connect: socket.connect,
        close: close,
        end: socket.end
    };
})();

const setUser = values => {
    if (CoreTools.isObject(values)) {
        const keys = Object.keys(values);
        if (keys.length) {
            const authState = CoreTools.switch(
                [values.valid === true && user.valid === false, 1],
                [values.valid === false && user.valid === true, 0],
                [true, -1]
            );
            const serviceState = CoreTools.switch(
                [values.service === true && user.service === false, 1],
                [values.service === false && user.service === true, 0],
                [true, -1]
            );
            console.log("UserStates:", authState, serviceState);
            keys.forEach(key => user[key] = values[key]);
            if (authState === 1) {
                loadSettings("Login");
                userSocket.connect();
                getApiAuthPackage();
                CoreTools.memory.set("ukey", user.authkey, 2);
            } else if (authState === 0) {
                userSocket.close();
                clearTimeout(apiAuthTimer);
                user.authkey = null;
                user.id = null;
                user.firstname = null;
                user.lastname = null;
                user.email = null;
                user.theme = false;
                user.apiAuthPackage = null;
                CoreTools.memory.set("ukey", "", 2);
                console.log("Logout");
            }
            userWatchers.forEach(cb => cb(user, authState, serviceState));
        }
    }
};

const UserService = {
    user: user,
    socket: {
        connect: userSocket.connect,
        close: userSocket.close,
        on: userSocket.on
    },
    state: state,
    setState: setState,
    setUser: setUser,
    onUpdate: cb => {
        userWatchers.push(cb);
        return () => CoreTools.remove(userWatchers, cb);
    },
    onState: (opt1, opt2) => {
        const watch = {
            res: CoreTools.switch(
                [Array.isArray(opt1), opt1],
                [Array.isArray(opt2), opt2],
                [true, []]
            ), 
            cb: CoreTools.switch(
                [typeof(opt1) === "function", opt1],
                [typeof(opt2) === "function", opt2],
                [true, () => {}]
            )
        };
        stateWatchers.push(watch);
        return () => CoreTools.remove(stateWatchers, watch);
    },
    loadSettings: loadSettings
};

export default UserService;