import Icon from "../../../directives/Icon"
import IElements from "../../../directives/IElements";
import BaseApi from "../../../services/BaseApi";
import CoreTools from "../../../services/CoreTools";
import Panels from "../../../shell/windows/Panels";

class BugModal extends Panels.Modal {
    initState = {
        title: CoreTools.isObject(this.props.bug) ? "Bug - " + this.props.bug.site : "Bug",
        width: "auto",
        height: "auto",
        bug: this.props.bug
    };
    
    onMount = (state, props) => {};

    desktop = state => <div>
        <div style={{width: "600px", height: "250px", overflow: "auto"}}>
            <IElements.Tables.ListView
                style={{width: "100%"}}
                body={[
                    ["Site", state.bug.site],
                    ["Date", CoreTools.date.lhDateTime(state.bug.stamp)],
                    ["Message", state.bug.message],
                    ["Line", state.bug.line],
                    ["Column", state.bug.column],
                    ["Source", state.bug.source],
                    ["Url", state.bug.url],
                    ["Agent", state.bug.agent]
                ]}
            />
        </div>
        <div style={{textAlign: "right", padding: "5px"}}><button onClick={e => this.close()}>Close</button></div>
    </div>;
}

const Exp = {
    singleInstance: true,
    menuGroup: "Development",
    name: "BugTracker",
    title: "Bug Tracker",
    description: "Bug Tracker",
    menuIcon: <Icon name="fa-solid fa-bug" size={18} />,
    taskbarIcon: <Icon name="fa-solid fa-bug" size={22} margin={0} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    allowMobile: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class BugTracker extends Panels.Window {
        initState = {
            bugs: [],
            modals: [],
            contextMenu: null,
            isLoaded: false
        };

        onMount = (state, props) => {
            this.routines.refresh(() => this.setVal({isLoaded: true}));
        };

        api = {
            get: callback => BaseApi.push("api", "bugTracker", "get", {}, callback)
        };

        routines = {
            refresh: cb => this.api.get(results => {
                console.log("Bugs:", results);
                if (results.success) {
                    this.setVal({bugs: results.records}, () => {
                        if (typeof(cb) === "function") {cb(true);}
                    });
                } else if (typeof(cb) === "function") {cb(true);}
            })
        };

        MenuBar = () => <IElements.MenuBar.HeaderBar menu={[
            {
                caption: "File",
                menu: [
                    {caption: "Refresh", onClick: e => this.routines.refresh()},
                    "line",
                    {caption: "Exit", onClick: e => this.close()}
                ]
            },
            {caption: "Edit", disabled: true},
            {caption: "View", disabled: true},
            {caption: "Help", disabled: true}
        ]} />;

        userClick = (event, record, field) => {
            const bugModal = <BugModal
                key={record.id}
                onClose={() => this.removeModal(bugModal)}
                bug={record.full}
            />;
            this.addModal(bugModal);
        };

        Bugs = () => <IElements.Tables.StdSpread 
            header={[
                ["date", "Date", "humanDate"],
                ["site", "Site"],
                ["message", "Message", "messageDisplay"]
            ]}
            useKey={r => r.id}
            body={this.state.bugs.map(r => ({
                id: r.id,
                date: r.stamp,
                humanDate: <span style={{whiteSpace: "nowrap"}}>{CoreTools.date.lhDateTime(r.stamp)}</span>,
                site: r.site,
                message: r.message,
                messageDisplay: <div style={{whiteSpace: "nowrap"}}>{r.message || ""}</div>,
                full: r
            }))}
            order={["date", true]}
            onClick={this.userClick}
            // onContextMenu={this.userContext}
        />;

        scrollBoxResize = () => null;
        onResize = (width, height) => {
            this.scrollBoxResize();
        };

        desktop = state => <table style={{width: "100%", height: "100%", borderCollapse: "collapse", borderSpacing: "0px"}}>
            <tbody>
                <tr><td style={{padding: "0px"}}>{this.MenuBar()}</td></tr>
                <tr><td style={{padding: "0px", height: "100%", width: "100%"}}><IElements.CompElems.ScrollBox onResize={ctrls => this.scrollBoxResize = ctrls}>{this.Bugs()}</IElements.CompElems.ScrollBox></td></tr>
            </tbody>
        </table>;
    }
};
export default Exp;