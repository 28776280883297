import Icon from "../../directives/Icon";
import IElements from "../../directives/IElements";
import CoreTools from "../../services/CoreTools";
import Socket from "../../services/Socket";
import UserService from "../../services/UserService";
import Panels from "../../shell/windows/Panels";

const funnelConnector = () => {
    let socket = Socket("wss://form.consumergenius.com/agent");
    socket.reconnect(true);
    let sendCache = [];
    let authed = false;
    const send = (action, data) => {
        if (authed && socket.state() === 1) {
            socket.send(action, data);
        } else {
            sendCache.push({action: action, data: data});
        }
    };

    socket.on("ident", data => {
        if (data.success) {
            authed = true;
            send("activeClients", {});
        }
    });

    socket.on("open", data => {
        socket.send("ident", {authkey: UserService.user.authkey});
    });

    return {
        on: socket.on,
        send: send,
        connect: socket.connect,
        close: socket.close,
        end: socket.end
    };
};

const PageLife2 = {
    singleInstance: true,
    menuGroup: "Funnels",
    name: "PageLife2",
    title: "Page Life",
    description: "Page Life Graphical",
    menuIcon: <Icon name="pulse" size={18} />,
    taskbarIcon: <Icon name="pulse" size={22} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class PageLife2 extends Panels.Window {
        clients = [];
        forms = [];

        initState = {
            clients: this.clients,
            forms: this.forms,
            isLoaded: true
        };
        
        routines = {
            active: data => {
                console.log("active", data);
                data.clients.forEach(client => {
                    client.forms.forEach(f => {if (!this.forms.includes(f)) {this.forms.push(f);}});
                    this.clients.push(client);
                });
                this.setVal({clients: this.clients, forms: this.forms});
                this.setTitle(`Page Life (${this.clients.length})`);
            },
            add: data => {
                let client = this.clients.find(c => c.id === data.id);
                if (client) {
                    if (client.timeout) {
                        clearTimeout(client.timeout);
                        Object.keys(client).forEach(key => delete(client[key]));
                        Object.keys(data).forEach(key => client[key] = data[key]);
                    }
                } else {
                    this.clients.push(data);
                }
                (data.forms || []).forEach(f => {if (!this.forms.includes(f)) {this.forms.push(f);}});
                this.setVal({clients: this.clients, forms: this.forms});
                this.setTitle(`Page Life (${this.clients.length})`);
            },
            fields: data => {
                let existing = this.clients.find(c => c.id === data.id);
                if (existing) {
                    Object.keys(data.global).forEach(key => existing.global[key] = data.global[key]);
                    if (data.formName) {
                        if (!CoreTools.isObject(existing.funnels)) {existing.funnels = {};}
                        if (!CoreTools.isObject(existing.funnels[data.formName])) {existing.funnels[data.formName] = {};}
                        if (!CoreTools.isObject(existing.funnels[data.formName].fields)) {existing.funnels[data.formName].fields = {};}
                        Object.keys(data.fields).forEach(key => existing.funnels[data.formName].fields[key] = data.fields[key]);
                    }
                    this.setVal({clients: this.clients});
                }
            },
            params: data => {
                let existing = this.clients.find(c => c.id === data.id);
                if (existing) {
                    existing.params = data.params;
                    this.setVal({clients: this.clients});
                }
            },
            remove: data => {
                let existing = this.clients.find(c => c.id === data.id);
                if (existing) {
                    existing.timeout = setTimeout(() => {
                        CoreTools.remove(existing.forms, data.formName);
                        if (existing.forms.length === 0) {
                            CoreTools.remove(this.clients, existing);
                        }
                        if (!this.clients.filter(client => client.forms.includes(data.formName)).length) {
                            CoreTools.remove(this.forms, data.formName);
                            this.setVal({forms: this.forms});
                        }
                        this.setVal({clients: this.clients});
                        this.setTitle(`Page Life (${this.clients.length})`);
                    }, 10000);
                }
            },
            addForm: data => {
                let existing = this.clients.find(c => c.id === data.id);
                if (existing && !existing.forms.includes(data.formName)) {
                    existing.forms.push(data.formName);
                    if (!this.forms.includes(data.formName)) {this.forms.push(data.formName);}
                    this.setVal({clients: this.clients, forms: this.forms});
                }
            },
            removeForm: data => {
                let existing = this.clients.find(c => c.id === data.id);
                if (existing && existing.forms.includes(data.formName)) {
                    existing.timeout = setTimeout(() => {
                        CoreTools.remove(existing.forms, data.formName);
                        if (!existing.forms.length) {
                            CoreTools.remove(this.clients, existing);
                            this.setTitle(`Page Life (${this.clients.length})`);
                        }
                        if (!this.clients.filter(client => client.forms.includes(data.formName)).length) {
                            CoreTools.remove(this.forms, data.formName);
                            this.setVal({forms: this.forms});
                        }
                        this.setVal({clients: this.clients});
                    }, 10000);
                }
            }
        };

        socket = funnelConnector();
        onMount = () => {
            this.socket.on("activeClients",     this.routines.active);
            this.socket.on("activeClient",      this.routines.add);
            this.socket.on("deadClient",        this.routines.remove);
            this.socket.on("updateFields",      this.routines.fields);
            this.socket.on("updateParams",      this.routines.params);
            this.socket.on("addForm",           this.routines.addForm);
            this.socket.on("removeForm",        this.routines.removeForm);
            this.socket.on("document", data => console.log("document", data));
            this.socket.connect();
        };
        onUnmount = () => {
            this.socket.end();
        };

        MenuBar = () => <IElements.MenuBar.HeaderBar menu={[
            {caption: "File", disabled: true},
            {caption: "Find", disabled: true},
            {caption: "View", disabled: true},
            {caption: "Help", disabled: true}
        ]} />;

        columnStyle = fontSize => ({
            textAlign: "center",
            height: `${fontSize + 6}px`,
            width: "100%",
            fontSize: `${fontSize}px`,
            fontFamily: "arial",
            whiteSpace: "nowrap",
            overlow: "auto"
        });

        clientPanel = (client, form) => <div
            key={client.id + "_" + form}
            style={{
                display: "inline-block",
                verticalAlign: "top",
                width: "200px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "5px",
                margin: "2px",
                padding: "2px",
                backgroundColor: client.timeout ? "#ffa6af" : "#ffffff",
                cursor: "pointer",
                overflow: "hidden"
            }}
            onClick={() => console.log("Client Click:", client, form)}
        >
            <div style={this.columnStyle(14)}>{form}</div>
            <div style={this.columnStyle(12)}>{client.funnels[form]?.fields.page}</div>
            <div style={this.columnStyle(12)}>{client.params?.ip}</div>
            <div style={this.columnStyle(12)}>{client.global?.first_name} {client.global?.last_name}</div>
            <div style={this.columnStyle(12)}>{client.global?.phone}</div>
            <div style={this.columnStyle(12)}>{client.global?.email}</div>
        </div>;

        scrollBoxResize = () => null;
        onResize = (width, height) => {
            this.scrollBoxResize();
        };

        // clientLine = (client, form) => <div key={client.id + "_" + form}>{form} {client.funnels[form].fields.page} {client.global.first_name} {client.global.last_name} {client.global.state}</div>;
        //state.clients.map(client => client.forms.map(form => this.clientPanel(client, form)));
        
        clientPanels = state => state.forms.sort().map(formName => <div key={formName}>
            <div style={{padding: "2px"}}>{formName} ({state.clients.filter(c => c.forms.includes(formName)).length})</div>
            <div>{state.clients.filter(c => c.forms.includes(formName)).map(client => this.clientPanel(client, formName))}</div>
        </div>);
        
        desktop = (state) => <table style={{width: "100%", height: "100%", borderCollapse: "collapse", borderSpacing: "0px"}}>
            <tbody>
                <tr><td style={{padding: "0px"}}>{this.MenuBar()}</td></tr>
                <tr><td style={{padding: "0px", height: "100%", width: "100%"}}><IElements.CompElems.ScrollBox onResize={ctrls => this.scrollBoxResize = ctrls}>{this.clientPanels(state)}</IElements.CompElems.ScrollBox></td></tr>
            </tbody>
        </table>;

        mobile = () => {};
    }
};
export default PageLife2;