import React from "react";
import CoreTools from "./services/CoreTools";
import BaseApi from "./services/BaseApi";
import UserService from "./services/UserService";
import LoginPage from "./login/LoginPage";
import LoadingScreen from "./shell/loading/LoadingScreen";
import Environments from "./shell/environments/Environments";

const isMobile = () => window.innerWidth < 800;

CoreTools.state.taskbar = false;
CoreTools.state.taskbarHeight = () => window.innerHeight + 6 - (CoreTools.state.taskbar?.offsetTop || 0);
CoreTools.state.startTop = 0;
CoreTools.state.startLeft = 0;
CoreTools.state.isMobile = isMobile();
CoreTools.state.zIndex = 10;

let resizeTimer = null;
window.addEventListener("resize", () => {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
    CoreTools.emit("screenSize", window.innerWidth, window.innerHeight);
    if (CoreTools.state.isMobile !== isMobile()) {
      CoreTools.state.isMobile = isMobile();
      CoreTools.emit("isMobile", isMobile());
    }
  }, 100);
});

if (UserService.user.authkey) {BaseApi.login.getSession();}

let lastLoadState = 0;

const App = () => {
  const [loadState, setLoadState] = React.useState(0);
  
  React.useEffect(() => {
    if (lastLoadState !== loadState) {
      lastLoadState = loadState;
      if (loadState === 1) {
        setLoadState(2);
      }
    }
    const userUpdated = UserService.onUpdate((user, validState, serviceState) => {
      CoreTools.switch(
        [validState === 1, () => setLoadState(1)],
        [validState === 0, () => setLoadState(0)]
      );
    });
    return () => userUpdated();
  }, [loadState]);
  
  return CoreTools.switch(
    [loadState === 1,    () => <LoadingScreen />],
    [loadState === 2,    () => <Environments />],
    [true,               () => <LoginPage />]
  );
};
export default App;