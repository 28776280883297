import React from "react";
import Icon from "../../../directives/Icon";
import BaseApi from "../../../services/BaseApi";
import IElements from "../../../directives/IElements";
// import Modals from "../../../shell/windows/Modal";
import CoreTools from "../../../services/CoreTools";import Panels from "../../../shell/windows/Panels";
;

const styles = () => ({
    table: {
        width: "100%",
        height: "100%",
        borderCollapse: "collapse"
    },
    bgBtn: {
        backgroundSize: "cover",
        width: "80px",
        height: "45px",
    },
    clrBtn: {
        width: "45px",
        height: "45px",
    }
});

const themeAccents = {
    white: {
        "--color-taskbar-font": "#FFFFFF",
        "--color-window-banner": "#000000",
        "--color-window-active": "#333333",
        "--color-window-highlight": "#666666",
        "--color-window-font": "#FFFFFF",
        "--color-hover-background": "rgba(49, 108, 204, 0.5)",
        "--color-hover-font": "#FFFFFF"
    },
    black: {
        "--color-taskbar-font": "#000000",
        "--color-window-banner": "#000000",
        "--color-window-active": "#333333",
        "--color-window-highlight": "#666666",
        "--color-window-font": "#000000",
        "--color-hover-background": "rgba(49, 108, 204, 0.5)",
        "--color-hover-font": "#000000"
    }
};
const themes = [
    {   
        ...themeAccents.white, 
        "--color-taskbar-shade": "rgba(0, 0, 0, 0.3)",
        "--color-taskbar-background": "#316CCC",
    },
    {
        ...themeAccents.white, 
        "--color-taskbar-shade": "rgba(255, 0, 0, 0.3)",
        "--color-taskbar-background": "#000000",
    },
    {
        ...themeAccents.white, 
        "--color-taskbar-shade": "rgba(8, 141, 8, 0.8)",
        "--color-taskbar-background": "#0b4406",
    },
    {
        ...themeAccents.white, 
        "--color-taskbar-shade": "rgba(104, 8, 141, 0.8)",
        "--color-taskbar-background": "#2a103a",
    },
    {
        ...themeAccents.white, 
        "--color-taskbar-shade": "rgba(171, 94, 36, 0.8)",
        "--color-taskbar-background": "#784117",
    },
    {
        ...themeAccents.white, 
        "--color-taskbar-shade": "rgba(36, 149, 171, 0.8)",
        "--color-taskbar-background": "#1b4e5d",
    },
    {
        ...themeAccents.white, 
        "--color-taskbar-shade": "rgba(171, 36, 36, 0.8)",
        "--color-taskbar-background": "#5d1b1b",
    },
];
const backgrounds = [
    { "--image-desktop-background": "url('/images/backgrounds/luca-micheli-ruWkmt3nU58-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/chong-wei-l_C_R1cULnA-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/dynamic-wang-dJxvs8rOt74-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/dynamic-wang-uMo1PTIGIR8-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/kellen-riggin-xfQOFuqSy-E-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/navi-XUUBZRej_T0-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/paul-steiner-MBBi_esPx3g-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/alexey-demidov-waaM_nfNpgM-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/alvy-martinez-C_pJsE9gl_8-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/ashlyn-ciara-hjdQ0lgB9wY-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/austin-schmid-4tNSamUThkk-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/austin-schmid-uxHWbq-OVJw-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/brittany-chastagnier-B7xSl-dWuto-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/brittany-chastagnier-ObsVDV7Asj8-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/bruno-van-der-kraan-qez8mNJ3ilU-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/cedric-dhaenens-qrBVjQd82w4-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/chris-holgersson-zshyCr6HGw0-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/dan-cristian-padure-E1ti6KCxSTM-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/eberhard-grossgasteiger-A9PpqJWbftM-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/john-gibbons-oSMYeEq6YPo-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/luca-baggio-eKU3JGNCCMg-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/mark-olsen-m46nyZsV0go-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/michelle-brittain-A62zcEohwFk-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/mike-montgomery-IBtoC1j6UkY-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/pawel-czerwinski-L8fXJgMk5jc-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/rog-h-m47L5TEDiqA-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/sixteen-miles-out-lIM0ziwN5RE-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/stephen-leonardi-zKhSVDr7_Y4-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/steve-johnson-3Sf_G9m0gcQ-unsplash.jpg')" },
    { "--image-desktop-background": "url('/images/backgrounds/thom-milkovic-R_mpbJ2pHc0-unsplash.jpg')" },
];


const Exp = {
    singleInstance: true,
    menuGroup: "Preferences",
    name: "Themes",
    title: "Themes",
    description: "Manage Your Custom Themes",
    menuIcon: <Icon name="images" size={18} />,
    taskbarIcon: <Icon name="images" size={22} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class Users extends Panels.Window {

        onMount = () => {};
        onUnMount = () => {};

        menuBar = state => <IElements.MenuBar.HeaderBar menu={[
            {caption: "File", disabled: true},
            {caption: "View", disabled: true},
            {caption: "Help", disabled: true}
        ]} />;

        saveSettings = obj => {
            console.log("Theme:", obj);
            BaseApi.settings.saveObject("theme", obj);
        };

        mobile = state => <div>Not Available</div>;
        desktop = state => <table style={styles().table}>
            <tbody>
                <tr><td colSpan={2} style={{padding: "0px"}}>{this.menuBar(state)}</td></tr>
                <tr><td colSpan={2} style={{padding: "0px"}}><IElements.Titles.PanelHeader>Colors</IElements.Titles.PanelHeader></td></tr>
                <tr><td colSpan={2} style={{height: "50%", overflow: "auto", padding: "0px", verticalAlign: "top"}}>
                    { themes.map((e, index) => <button key={index} onClick={() => this.saveSettings(e)} style={CoreTools.fuseObj( 
                        styles().clrBtn, { 
                            color: e["--color-taskbar-font"],
                            backgroundImage: `linear-gradient(${e["--color-taskbar-background"]}, ${e["--color-taskbar-shade"]})`,
                            backgroundColor: e["--color-taskbar-background"], 
                        }
                    )} ></button> ) }
                </td></tr>
                <tr><td colSpan={2} style={{padding: "0px"}}><IElements.Titles.PanelHeader>Wallpapers</IElements.Titles.PanelHeader></td></tr>
                <tr><td colSpan={2} style={{height: "50%", overflow: "auto", padding: "0px", verticalAlign: "top"}}>
                    { backgrounds.map((e, index) => <button key={index} onClick={() => this.saveSettings(e)} style={CoreTools.fuseObj( 
                        styles().bgBtn, { 
                            backgroundImage: e["--image-desktop-background"] 
                        }
                    )} ></button> ) }
                </td></tr>
            </tbody>
        </table>;
    }
};
export default Exp;