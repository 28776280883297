import React from "react";
import CoreTools from "../../services/CoreTools";
// import css from "./mainMenu.module.css";
import Icon from "../../directives/Icon";
import BaseApi from "../../services/BaseApi";
import AppManager from "../../services/AppManager";

const styles = () => ({
    button: CoreTools.state.isMobile ? {
        position: "absolute",
        bottom: "0px",
        left: "50%",
        transform: "translateX(-50%)",
    } : {
        position: "fixed",
        bottom: "0px",
        left: "0px",
    },
    menu: CoreTools.state.isMobile ? {
        width: "100%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "var(--color-taskbar-background)",
        backgroundColor: "var(--color-taskbar-background)",
        position: "fixed",
        bottom: "44px",
        left: "0px",
        zIndex: ++CoreTools.state.zIndex,
    } : {
        width: "240px",
        minHeight: "30px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "var(--color-taskbar-background)",
        backgroundColor: "var(--color-taskbar-background)",
        position: "fixed",
        bottom: "44px",
        left: "0px",
        zIndex: ++CoreTools.state.zIndex,
        boxShadow: "var(--color-shadow-br)"
    },
    menuMask: {
        position: "fixed",
        left: "0px",
        top: "0px",
        width: "100%",
        height: "100%",
        zIndex: ++CoreTools.state.zIndex
    },
    mainMenu: CoreTools.state.isMobile ? {
        borderRadius: "0px 0px 0px 0px",
        borderWidth: "0px",
        display: "block",
        textAlign: "center",
        color: "#FFFFFF",
        width: "100%",
        height: `${window.innerHeight - CoreTools.state.taskbarHeight() + 6}px`,
        bottom: `${CoreTools.state.taskbarHeight() - 6}px`,
        zIndex: ++CoreTools.state.zIndex
    } : {
        borderRadius: "10px 10px 0px 0px",
        borderWidth: "1px",
        bottom: `${CoreTools.state.taskbarHeight() - 6}px`,
        zIndex: ++CoreTools.state.zIndex
    }
});

const MainMenu = props => {
    const [show, setShow] = React.useState(false);
    const [menuItems, setMenuItems] = React.useState([]);
    const [menuGroups, setMenuGroups] = React.useState([]);
    const [currentGroup, setCurrentGroup] = React.useState(false);
    const [showMobile, setMobile] = React.useState(CoreTools.state.isMobile);
    const [zIndex, setIndex] = React.useState(++CoreTools.state.zIndex);

    React.useEffect(() => {
        const wHolder = [
            CoreTools.on("isMobile", isMobile => setMobile(isMobile)),
        ];
        let menuItems = AppManager.getMenuItems();
        let menuGroups = [];
        menuItems.forEach(mi => {
            if (mi.menuGroup && !menuGroups.includes(mi.menuGroup)) {
                menuGroups.push(mi.menuGroup);
            }
        });
        setMenuGroups([...menuGroups]);
        setMenuItems([...menuItems]);
        return () => {
            wHolder.forEach(k => k());
        };
    }, []);

    // const items = [
    //     {icon: "contacts", caption: "Contacts", onClick: e => AppManager.open("Contacts"), value: 0},
    //     {icon: "phone", caption: "Dialer", onClick: e => {}, value: 0},
    //     {icon: "users", caption: "Administration", onClick: e => {}, value: 0},
    //     {icon: "close", caption: "Log Out", onClick: e => BaseApi.login.logout(), value: 0},
    // ];

    // const menu = () => <div
    //     className={css["alert-menu"]}
    //     style={styles().menu}
    // >{items.map((b, i) => <button
    //     key={i}
    //     className={css["menu-button"]}
    //     onClick={e => b.onClick(e)}
    // ><Icon name={b.icon} />{b.caption} {b.value > 0 && <span className="a-count">{b.value}</span>}</button>)}</div>;


    const setSubGroup = (event, name) => {
        event.stopPropagation();
        event.preventDefault();
        setCurrentGroup(name);
    };

    const desktopMenu = () => <div className="mainmenu" style={styles().mainMenu}>
        <div className="quickbar" style={{position: "relative"}}>
            <button onClick={e => {}} title="User Profile"><Icon name="user" size={28} margin={0} /></button>
            <button onClick={e => AppManager.open("Contacts")} title="Contacts"><Icon name="contacts" size={28} margin={0} /></button>
            <button onClick={e => AppManager.open("CallSms")} title="Dialer"><Icon name="phone" size={28} margin={0} /></button>
            <button onClick={e => {}} title="Chat"><Icon name="chat" size={28} margin={0} /></button>
            <button onClick={e => {}} title="E-mail"><Icon name="email" size={28} margin={0} /></button>
            <div style={{position: "absolute", bottom: "9px", left: "7px"}}>
                <button onClick={e => AppManager.open("Settings")} title="Settings"><Icon name="settings" size={28} margin={0} /></button>
                <button onClick={e => BaseApi.login.logout()} title="Log Out"><Icon name="close" size={28} margin={0} /></button>
            </div>
        </div>
        <div className="rootmenu">
            <button
                onMouseDown={e => setSubGroup(e, false)}
                onMouseOver={e => setSubGroup(e, false)}
                disabled={currentGroup === false}
            >All Applications</button>
            {menuGroups.map((mg, i) => <button
                key={i} 
                onMouseDown={e => setSubGroup(e, mg)}
                onMouseOver={e => setSubGroup(e, mg)}
                disabled={currentGroup === mg}
            >{mg}</button>)}
        </div>
        <div className="submenu">
            {menuItems.filter(fi => !currentGroup || fi.menuGroup === currentGroup).map((mi, i) => <button
                key={mi.name}
                onClick={() => {
                    console.log("Opening:", mi.name);
                    AppManager.open(mi.name);
                }}
            >{mi.menuIcon}{mi.title}</button>)}
        </div>
    </div>;

   const mobileMenu = () => <div className="mainmenu" style={styles().mainMenu}>
        <div className="quickbarmobile" style={{position: "relative"}}>
            <button onClick={e => {}} title="User Profile"><Icon name="user" size={28} margin={0} /></button>
            <button onClick={e => AppManager.open("Contacts")} title="Contacts"><Icon name="contacts" size={28} margin={0} /></button>
            <button onClick={e => {}} title="Dialer"><Icon name="phone" size={28} margin={0} /></button>
            <button onClick={e => {}} title="Chat"><Icon name="chat" size={28} margin={0} /></button>
            <button onClick={e => {}} title="E-mail"><Icon name="email" size={28} margin={0} /></button>
            <button onClick={e => {}} title="Settings"><Icon name="settings" size={28} margin={0} /></button>
            <button onClick={e => BaseApi.login.logout()} title="Log Out"><Icon name="close" size={28} margin={0} /></button>
        </div>
        <div className="submenumobile">
            {menuItems.filter(fi => fi.allowMobile).map((mi, i) => <button
                key={i}
                onClick={() => AppManager.open(mi.name)}
            >{mi.menuIcon}{mi.title}</button>)}
        </div>
    </div>;

    const mask = () => show ? <div
        style={CoreTools.fuseObj(styles().menuMask, {zIndex: zIndex})}
        onClick={() => setShow(false)}
    >
        {showMobile ? mobileMenu() : desktopMenu()}
    </div> : null;

    return <>
        {mask()}
        <button 
            className="mainmenu"
            style={styles().button}
            onClick={() => {
                setShow(true);
                setIndex(++CoreTools.state.zIndex);
                CoreTools.emit("elevateTaskbar");
            }}
            disabled={show}
        ><img className="mainmenu-image" src="/favicon.ico" alt="Main Menu" /></button>
    </>;
};

export default MainMenu;

// export default class MainMenu extends React.Component {
//     state = {
//         show: false,
//         menuItems: [],
//         menuGoups: [],
//         currentGroup: false,
//         isMobile: CoreTools.state.isMobile
//     };
//     setVal = CoreTools.stateHandler(this);
//     wHolder = CoreTools.watchHolder();
//     componentDidMount = () => {
//         this.setVal("mount");
//         let menuItems = AppManager.getMenuItems();
//         let menuGroups = [];
//         menuItems.forEach(mi => {
//             if (mi.menuGroup && !menuGroups.includes(mi.menuGroup)) {
//                 menuGroups.push(mi.menuGroup);
//             }
//         });
//         this.setVal({
//             menuGroups: menuGroups,
//             menuItems: menuItems
//         });
//         this.wHolder(CoreTools.on("isMobile", isMobile => this.setVal({isMobile: isMobile})));
//     };
//     componentWillUnmount = () => {
//         this.setVal("unmount");
//         this.wHolder("end");
//     };

//     items = [
//         {icon: "contacts", caption: "Contacts", onClick: e => {}, value: 0},
//         {icon: "phone", caption: "Dialer", onClick: e => {}, value: 0},
//         {icon: "users", caption: "Administration", onClick: e => {}, value: 0},
//         {icon: "close", caption: "Log Out", onClick: e => BaseApi.login.logout(), value: 0},
//     ];

//     menu = () => <div
//         className={css["alert-menu"]}
//         style={styles().menu}
//     >{this.items.map((b, i) => <button
//         key={i}
//         className={css["menu-button"]}
//         onClick={e => b.onClick(e)}
//     ><Icon name={b.icon} />{b.caption} {b.value > 0 && <span className="a-count">{b.value}</span>}</button>)}</div>;

//     setSubGroup = (event, name) => {
//         event.stopPropagation();
//         event.preventDefault();
//         this.setVal({
//             currentGroup: name
//         });
//     };

//     desktopMenu = () => <div className="mainmenu" style={styles().mainMenu}>
//         <div className="quickbar" style={{position: "relative"}}>
//             <button onClick={e => {}} title="User Profile"><Icon name="user" size={28} margin={0} /></button>
//             <button onClick={e => {}} title="Contacts"><Icon name="contacts" size={28} margin={0} /></button>
//             <button onClick={e => {}} title="Dialer"><Icon name="phone" size={28} margin={0} /></button>
//             <button onClick={e => {}} title="Chat"><Icon name="chat" size={28} margin={0} /></button>
//             <button onClick={e => {}} title="E-mail"><Icon name="email" size={28} margin={0} /></button>
//             <div style={{position: "absolute", bottom: "9px", left: "7px"}}>
//                 <button onClick={e => {}} title="Settings"><Icon name="settings" size={28} margin={0} /></button>
//                 <button onClick={e => BaseApi.login.logout()} title="Log Out"><Icon name="close" size={28} margin={0} /></button>
//             </div>
//         </div>
//         <div className="rootmenu">
//             <button
//                 onMouseDown={e => this.setSubGroup(e, false)}
//                 onMouseOver={e => this.setSubGroup(e, false)}
//                 disabled={this.state.currentGroup === false}
//             >All Applications</button>
//             {this.state.menuGroups.map((mg, i) => <button
//                 key={i} 
//                 onMouseDown={e => this.setSubGroup(e, mg)}
//                 onMouseOver={e => this.setSubGroup(e, mg)}
//                 disabled={this.state.currentGroup === mg}
//             >{mg}</button>)}
//         </div>
//         <div className="submenu">
//             {this.state.menuItems.filter(fi => !this.state.currentGroup || fi.menuGroup === this.state.currentGroup).map((mi, i) => <button
//                 key={i}
//                 onClick={() => AppManager.open(mi.name)}
//             >{mi.menuIcon}{mi.title}</button>)}
//         </div>
//     </div>;

//     mobileMenu = () => <div className="mainmenu" style={styles().mainMenu}>
//         <div className="quickbarmobile" style={{position: "relative"}}>
//             <button onClick={e => {}} title="User Profile"><Icon name="user" size={28} margin={0} /></button>
//             <button onClick={e => {}} title="Contacts"><Icon name="contacts" size={28} margin={0} /></button>
//             <button onClick={e => {}} title="Dialer"><Icon name="phone" size={28} margin={0} /></button>
//             <button onClick={e => {}} title="Chat"><Icon name="chat" size={28} margin={0} /></button>
//             <button onClick={e => {}} title="E-mail"><Icon name="email" size={28} margin={0} /></button>
//             <button onClick={e => {}} title="Settings"><Icon name="settings" size={28} margin={0} /></button>
//             <button onClick={e => BaseApi.login.logout()} title="Log Out"><Icon name="close" size={28} margin={0} /></button>
//         </div>
//         <div className="submenumobile">
//             {this.state.menuItems.filter(fi => fi.allowMobile).map((mi, i) => <button
//                 key={i}
//                 onClick={() => AppManager.open(mi.name)}
//             >{mi.menuIcon}{mi.title}</button>)}
//         </div>
//     </div>;

//     mask = () => this.state.show ? <div
//         style={CoreTools.fuseObj(styles().menuMask, {zIndex: this.state.zIndex})}
//         onClick={() => this.setVal({show: false})}
//     >
//         {this.state.isMobile ? this.mobileMenu() : this.desktopMenu()}
//     </div> : null;

//     render = () => <>
//         {this.mask()}
//         <button className="mainmenu" style={styles().button} onClick={() => this.setVal({show: !this.state.show, zIndex: ++CoreTools.state.zIndex}, () => CoreTools.emit("elevateTaskbar"))} disabled={this.state.show}><img className="mainmenu-image" src="/favicon.ico" alt="Main Menu" /></button>
//     </>;
// }