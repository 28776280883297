import DatePicker from "../../directives/DatePicker";
import Icon from "../../directives/Icon";
import IElements from "../../directives/IElements";
import UChart from "../../directives/UChart";
import BaseApi from "../../services/BaseApi";
import CoreTools from "../../services/CoreTools";
import OptionLists from "../../services/OptionLists";
import Panels from "../../shell/windows/Panels";

const styles = {
    filters: {
        minHeight: "32px",
        fontSize: "14px",
        cursor: "pointer",
        verticalAlign: "bottom",
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    funnelTitle: {
        padding: "10px",
        fontSize: "18px"
    }
};

const FunnelTrafficStats = {
    singleInstance: true,
    menuGroup: "Funnels",
    name: "FunnelTrafficStats",
    title: "Funnel Traffic Stats",
    description: "Funnel Traffic Stats",
    menuIcon: <Icon name="overflow" size={18} />,
    taskbarIcon: <Icon name="overflow" size={20} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class Wdw extends Panels.Window {
        stats = [];
        initState = {
            chart: false,
            startDate: (() => {
                let date = new Date();
                date.setHours(0, 0, 0, 0);
                return date;
            })(),
            endDate: (() => {
                let date = new Date();
                date.setHours(0, 0, 0, 0);
                return date;
            })(),
            startHours: 0,
            endHours: 82800000,
            forms: [],
            cpids: [],
            aids: [],
            usedAids: [],
            usedCpids: []
        };

        api = {
            getStates: filters => cb => {
                BaseApi.push("api", "funnelStats", "get", filters || {}, cb);
            }
            // search: filters => cb => BaseApi.push("api", "contacts", "search", filters || {}, cb)
        };

        hourOptions = OptionLists.Hours.map(h => <option key={h.value} value={h.ms}>{h.local}:00 {h.ap}</option>);

        filterRefs = {
            form: null,
            aid: null,
            cpid: null
        };

        routines = {
            runFilters: () => {
                if (this.filterRefs.form.value) {
                    let stats = this.stats.filter(stat => stat.form === this.filterRefs.form.value);
                    
                    let aids = [];
                    stats.forEach(stat => {if (!aids.includes(stat.aid)) {aids.push(stat.aid);}});
                    if (![...aids, "NOT"].includes(this.filterRefs.aid.value)) {this.filterRefs.aid.value = "";}
                    CoreTools.switch(
                        [this.filterRefs.aid.value === "NOT", () => stats = stats.filter(stat => stat.aid === "")],
                        [this.filterRefs.aid.value, () => stats = stats.filter(stat => stat.aid === this.filterRefs.aid.value)],
                    );
                    
                    let cpids = [];
                    if (this.filterRefs.aid.value !== "NOT") {
                        stats.forEach(stat => {if (!cpids.includes(stat.cpid)) {cpids.push(stat.cpid);}});
                        if (!cpids.includes(this.filterRefs.cpid.value)) {this.filterRefs.cpid.value = "";}
                        if (this.filterRefs.cpid.value) {stats = stats.filter(stat => stat.cpid === this.filterRefs.cpid.value);}
                    }

                    let form = {
                        pages: {},
                        total: 0,
                        from: null,
                        through: null
                    };

                    let usedAids = [];
                    let usedCpids = [];
                    
                    stats.forEach(stat => {
                        if (!usedAids.includes(stat.aid)) {usedAids.push(stat.aid);}
                        if (!usedCpids.includes(stat.cpid)) {usedCpids.push(stat.cpid);}
                        if (!form.pages[stat.name]) {form.pages[stat.name] = {
                            index: stat.index,
                            count: 0
                        };}
                        form.pages[stat.name].count += stat.count;
                        form.total += stat.count;
                        if (!form.from || stat.hour < form.from) {form.from = stat.hour;}
                        if (!form.through || stat.hour > form.through) {form.through = stat.hour;}
                    });
                    let chart = {
                        name: this.filterRefs.form.value,
                        total: form.total,
                        from: form.from,
                        through: form.through,
                        pages: CoreTools.orderBy(Object.keys(form.pages).map(page => ({
                            name: page,
                            index: form.pages[page].index,
                            count: form.pages[page].count,
                            percent: Math.round(CoreTools.percent(form.pages[page].count, form.total))
                        })), "index")
                    };

                    let progression = chart.total;
                    chart.pages.forEach(page => {
                        page.progP = Math.round(CoreTools.percent(progression, chart.total));
                        page.progC = progression;
                        progression -= page.count;
                    });
                    
                    this.setVal({
                        chart: chart,
                        aids: [
                            {caption: "All AIDs", value: ""},
                            ...aids.map(aid => ({caption: aid, value: aid}))
                        ],
                        cpids: [
                            {caption: "All CPIDs", value: ""},
                            ...cpids.map(cpid => ({caption: cpid, value: cpid}))
                        ],
                        usedAids: usedAids,
                        usedCpids: usedCpids
                    });
                }
            },
            getStates: filters => {
                const renderStats = stats => {
                    const statRecs = [];
                    const fForms = [];
                    stats.forEach(stat => {
                        if (!fForms.includes(stat.form)) {fForms.push(stat.form);}
                        statRecs.push({
                            form: stat.form,
                            hour: stat.hour,
                            index: stat.index,
                            name: stat.name,
                            count: stat.count - CoreTools.sum(stat.affiliates, "count"),
                            aid: "N/A",
                            cpid: "N/A"
                        });
                        stat.affiliates.forEach(aff => {
                            statRecs.push({
                                form: stat.form,
                                hour: stat.hour,
                                index: stat.index,
                                name: stat.name,
                                count: aff.count,
                                aid: aff.aid.toString(),
                                cpid: aff.cpid.toString()
                            }); 
                        });
                    });
                    this.stats = statRecs;
                    this.setVal({forms: fForms.sort()}, this.routines.runFilters);
                    console.log("statRecs", statRecs);
                    console.log("Stats:", stats);
                };
                if (filters) {
                    this.setVal(filters, () => this.api.getStates({start: this.state.startDate.getTime() + this.state.startHours, end: this.state.endDate.getTime() + this.state.endHours})(results => results.success ? renderStats(results.records) : console.log("Request Error:", results)));
                } else {
                    this.api.getStates({start: this.state.startDate.getTime() + this.state.startHours, end: this.state.endDate.getTime() + this.state.endHours})(results => results.success ? renderStats(results.records) : console.log("Request Error:", results));
                }
            }
        };

        onMount = () => {
            this.routines.getStates();
        };

        menuBar = () => <IElements.MenuBar.HeaderBar menu={[
            {
                caption: "File", 
                menu: [
                    {caption: "Refresh", onClick: () => this.routines.getStates()},
                    "line",
                    {caption: "Exit", onClick: this.close}
                ]
            },
            {
                caption: "Find",
                menu: [
                    {caption: "Contact Search", onClick: () => false}
                ]
            },
            {caption: "View", disabled: true},
            {caption: "Help", disabled: true}
        ]} />;

        scrollBoxResize = () => null;
        onResize = (width, height) => {
            this.scrollBoxResize();
        };

        searchBar = () => <div style={{backgroundColor: "#cccccc"}}>
            <div>
                {CoreTools.switch(
                    this.state.show,
                    ["from", () => <div style={{position: "absolute"}}><DatePicker title="From" date={this.state.startDate} onSelect={date => this.routines.getStates({show: false, startDate: date})} /></div>],
                    ["through", () => <div style={{position: "absolute"}}><DatePicker title="Through" date={this.state.endDate} onSelect={date => this.routines.getStates({show: false, endDate: date})} /></div>]
                )}
                <button style={styles.filters} onClick={() => this.setVal({show: "from"})}>From: {CoreTools.date.lhDate(this.state.startDate.getTime())}</button>
                <select style={styles.filters} defaultValue={this.state.startHours} onChange={e => this.routines.getStates({startHours: parseInt(e.target.value)})}>{this.hourOptions}</select>
                <button style={styles.filters} onClick={() => this.setVal({show: "through"})}>Through: {CoreTools.date.lhDate(this.state.endDate.getTime())}</button>
                <select style={styles.filters} defaultValue={this.state.endHours} onChange={e => this.routines.getStates({endHours: parseInt(e.target.value)})}>{this.hourOptions}</select>
            </div>
            <div>
                <select ref={r => this.filterRefs.form = r} style={styles.filters} onChange={() => setTimeout(this.routines.runFilters)}>{this.state.forms.map(form => <option key={form} value={form}>{form}</option>)}</select>
                <select ref={r => this.filterRefs.aid = r} style={styles.filters} onChange={() => setTimeout(this.routines.runFilters)}>{this.state.aids.map(aid => <option key={aid.value} value={aid.value}>{aid.caption}</option>)}</select>
                <select ref={r => this.filterRefs.cpid = r} style={styles.filters} onChange={() => setTimeout(this.routines.runFilters)}>{this.state.cpids.map(cpid => <option key={cpid.value} value={cpid.value}>{cpid.caption}</option>)}</select>
            </div>
        </div>;

        charts = () => !this.state.chart ? <div>Loading</div> : <div key={this.state.chart.name} style={{padding: "5px"}}>
            <div style={{marginBottom: "40px"}}>
                <IElements.Tables.StdSpread 
                    header={[
                        ["name", "Page Name"],
                        ["index", "Page #", "number"],
                        ["count", "DO#"],
                        ["percent", "DO%", "percentS"],
                        ["progC", "Prog#"],
                        ["progP", "Prog%", "progPS"]
                    ]}
                    order={["index", false]}
                    useKey={r => r.index}
                    body={this.state.chart.pages.map(r => {
                        r.number = r.index + 1;
                        r.progPS = r.progP + "%";
                        r.percentS = r.percent + "%";
                        return r;
                    })}
                    // onClick={this.contactClick}
                    // onContextMenu={this.userContext}
                />
            </div>
            <div><UChart
                title="Progression"
                type="line"
                values={this.state.chart.pages.map(page => ({label: `${page.name} (${page.progC}, ${page.progP}%)`, value: page.progP}))}
                style={{width: "auto", maxHeight: "300px"}}
            /></div>
            <div style={{marginTop: "40px"}}><UChart
                title="Last Page Visited"
                type="bar"
                values={this.state.chart.pages.map(page => ({label: `${page.name} (${page.count}, ${page.percent}%)`, value: page.count}))}
                style={{width: "auto", maxHeight: "300px"}}
            /></div>
            <div style={{textAlign: "center"}}><UChart
                type="pie"
                values={this.state.chart.pages.map(page => ({label: `${page.name} (${page.count}, ${page.percent}%)`, value: page.count}))}
                style={{maxWidth: "500px", maxHeight: "500px"}}
                outerStyle={{maxWidth: "500px", margin: "40px auto"}}
            /></div>
        </div>;

        details = () => this.state.chart && <table style={{width: "100%"}}><tbody>
            <tr>
                <td style={{textAlign: "left", fontWeight: "bold"}}>{this.state.chart.name}</td>
                <td  colSpan={2} style={{textAlign: "center", fontSize: "14px"}}>{CoreTools.date.lhDateTime(this.state.chart.from)} - {CoreTools.date.lhDateTime(this.state.chart.through + 1000 * 60 * 60)}</td>
                <td style={{textAlign: "right"}}>Visitors: {this.state.chart.total}</td>
            </tr>
            <tr>
                <td colSpan={2} style={{textAlign: "left"}}>AIDs: {this.state.usedAids.join(", ")}</td>
                <td colSpan={2} style={{textAlign: "right"}}>CPIDs: {this.state.usedCpids.join(", ")}</td>
            </tr>
        </tbody></table>;

        desktop = (state) => <table style={{width: "100%", height: "100%", borderCollapse: "collapse", borderSpacing: "0px"}}>
            <tbody>
                <tr><td style={{padding: "0px"}}>{this.menuBar()}</td></tr>
                <tr><td style={{padding: "0px"}}>{this.searchBar()}</td></tr>
                <tr><td style={styles.funnelTitle}>{this.details()}</td></tr>
                <tr><td style={{padding: "0px", borderStyle: "solid", borderWidth: "1px 0px 1px 0px", borderColor: "#aaaaaa"}}>{}</td></tr>
                <tr><td style={{padding: "0px", height: "100%", width: "100%"}}><IElements.CompElems.ScrollBox onResize={ctrls => this.scrollBoxResize = ctrls}>{this.charts()}</IElements.CompElems.ScrollBox></td></tr>
            </tbody>
        </table>;
    }
};
export default FunnelTrafficStats;