import CoreTools from "./CoreTools";
import UserService from "./UserService";

UserService.setUser({
    authkey: CoreTools.memory.get("ukey")
});

const apiUrl = (resource, script, routine) => `https://crm.consumergenius.com/${resource}/${script}/${routine}`;

const apiReq = varObj => ({
    method: 'POST',
    headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 'authkey': UserService.user.authkey || ''},
    body: JSON.stringify(varObj || {})
});

const resolveData = res => {
    if (res.ok) {
        try {return res.json();} 
        catch (e) {return Promise.reject({res: res, error: e});}
    } else {return Promise.reject({res: res});}
};

let api = {
    active: 0,
    push: (version, script, routine, params, callback) => {
        api.active++;
        CoreTools.emit("networkCount", api.active);
        let errorCount = 0;
        fetch(
            apiUrl(version, script, routine),
            apiReq(params)
        ).then(resolveData).then(data => {
            if (data.authExpired) {BaseApi.login.logout();}
            else if (typeof(callback) === "function") {callback(data);}
            if (data.updateActive) {CoreTools.emit("updateActive");}
            api.active--;
            CoreTools.emit("networkCount", api.active);
        }).catch(error => {
            console.error("API Request Failed:", errorCount + 1, apiUrl(version, script, routine), error);
            // if (++errorCount < 3) {setTimeout(subRequest, 250);} else {api.active--;}
            if (typeof(callback) === "function") {callback({
                success: false, 
                error: CoreTools.fuseObj({code: "NETWORK_FAILURE"}, error)
            });}
            api.active--;
            CoreTools.emit("networkCount", api.active);
        });
    }
};

const loadSession = (data, callback) => {
    if (data && data.success) {
        UserService.setUser({
            valid: true,
            authkey: data.authkey,
            id: data.id,
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            theme: data.theme || false
        });
    } else {
        UserService.setUser({valid: false});
    }
    if (typeof(callback) === "function") {callback(data);}
};

const BaseApi = {
    push: api.push,
    login: {
        login: (email, password) => callback => api.push("api", "login", "login", {email: email, password: password}, data => loadSession(data, callback)),
        getSession: callback => api.push("api", "login", "getSession", {}, data => loadSession(data, callback)),
        resetInfo: key => callback => api.push("api", "login", "resetInfo", {key: key}, callback),
        resetPassword: email => callback => api.push("api", "login", "resetPassword", {email: email}, callback),
        savePassword: (key, password) => callback => api.push("api", "login", "savePassword", {key: key, password: password}, callback),
        logout: callback => api.push("api", "login", "logout", {}, data => loadSession(false, callback))
    },
    settings: {
        get: callback => api.push("api", "user", "getSettings", {}, callback),
        save: (groupName, settingName, settingValue) => api.push("api", "user", "saveSettings", {settings: {groupName: groupName, settingName: settingName, settingValue: settingValue}}, results => {
            if (results.success) {UserService.loadSettings("save");}
        }),
        saveObject: (groupName, settingObj) => {
            if (CoreTools.isObject(settingObj)) {
                const settings = Object.keys(settingObj).map(key => ({groupName: groupName, settingName: key, settingValue: settingObj[key]}));
                api.push("api", "user", "saveSettings", {settings: settings}, results => {
                    if (results.success) {UserService.loadSettings("saveObject");}
                });      
            }
        },
        delete: (groupName, settingName) => api.push("api", "user", "saveSettings", {groupName: groupName, settingName: settingName}, results => {
            if (results.success) {UserService.loadSettings("saveObject");}
        })
    },
    security: {
        apiAuthPackage: callback => api.push("api", "user", "apiAuthPackage", {}, data => callback(data))
    }
};

export default BaseApi;