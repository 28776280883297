import React from "react";
import Icon from "../../../directives/Icon";
import BaseApi from "../../../services/BaseApi";
import IElements from "../../../directives/IElements";
import Modals from "../../../shell/windows/Modal";
import CoreTools from "../../../services/CoreTools";
import Panels from "../../../shell/windows/Panels";

const styles = () => ({
    table: {
        width: "100%",
        height: "100%",
        borderCollapse: "collapse"
    }
});

class EditUserModal extends React.Component {
    constructor(props) {
        super(props);
        Modals.Standard(this, {
            id: props.user?.id || null,
            firstname: props.user?.firstname || "",
            lastname: props.user?.lastname ||"",
            email: props.user?.email ||"",
            active: props.user?.active ? true : false,
            delete: false
        }, {
            menuIcon: null, 
            title: props.user?.id ? "Edit User" : "Add User",
            width: "auto",
            height: "auto",
            zIndex: 1,
            onClose: props.onClose
        }, () => {});
    }
    save = () => this.props.onSave({
        id: this.state.id,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        active: this.state.active
    });
    edit = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InputMedium caption="Firstname" value={this.state.firstname} onChange={nv => this.setVal({firstname: nv})} /></div>
        <div><IElements.Form.InputMedium caption="Lastname" value={this.state.lastname} onChange={nv => this.setVal({lastname: nv})} /></div>
        <div><IElements.Form.InputMedium caption="E-mail Address" value={this.state.email} onChange={nv => this.setVal({email: nv})} /></div>
        <div><IElements.Form.Switch caption="Enabled" value={this.state.active} onChange={nv => this.setVal({active: nv})} /></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Delete", onClick: () => this.setVal({delete: true}), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.props.onClose()},
            {content: "Save", onClick: this.save, disabled: () => !this.state.firstname || !this.state.lastname || !this.state.email || !this.state.email.includes("@") || !this.state.email.includes(".")}
        ]} /></div>
    </div>;
    delete = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InfoLarge title={`Delete the User '${this.state.firstname} ${this.state.lastname}'?`}>Deleting this user will also remove them from all groups. Any records that depend on this will show as 'Deleted User'. It is recommended to disable this user until you are done with such records.</IElements.Form.InfoLarge></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Confirm Delete", onClick: () => this.props.onDelete(this.state.id), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.setVal({delete: false})}
        ]} /></div>
    </div>;
    desktop = () => this.state.delete ? this.delete() : this.edit();
}

class EditGroupModal extends React.Component {
    constructor(props) {
        super(props);
        Modals.Standard(this, {
            id: props.group?.id || null,
            name: props.group?.name || "",
            description: props.group?.description || "",
            delete: false
        }, {
            menuIcon: null, 
            title: props.group?.id ? "Edit Group" : "Add Group",
            width: "auto",
            height: "auto",
            zIndex: 1,
            onClose: props.onClose
        }, () => {});
    }
    save = () => this.props.onSave({
        id: this.state.id,
        name: this.state.name,
        description: this.state.description
    });
    edit = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InputMedium caption="Name" value={this.state.name} onChange={nv => this.setVal({name: nv})} /></div>
        <div><IElements.Form.InputMedium caption="Description" value={this.state.description} onChange={nv => this.setVal({description: nv})} /></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Delete", onClick: () => this.setVal({delete: true}), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.props.onClose()},
            {content: "Save", onClick: this.save, disabled: () => !this.state.name || !this.state.description}
        ]} /></div>
    </div>;
    delete = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InfoLarge title={`Delete the Group '${this.state.name}'?`}>Warning, Deleting this group may orphan applications.</IElements.Form.InfoLarge></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Confirm Delete", onClick: () => this.props.onDelete(this.state.id), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.setVal({delete: false})}
        ]} /></div>
    </div>;
    desktop = () => this.state.delete ? this.delete() : this.edit();
}

class SelectGroupModal extends React.Component {

}

class SelectUserModal extends React.Component {

}

const Exp = {
    singleInstance: true,
    menuGroup: "Administration",
    name: "Users",
    title: "Users & Groups",
    description: "Manage Users & Groups",
    menuIcon: <Icon name="users" size={18} />,
    taskbarIcon: <Icon name="users" size={20} margin={0} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class Users extends Panels.Window {
        initState = {
            users: [],
            groups: [],
            groupMembers: [],
            selected: false,
            modal: null,
            contextMenu: null
        };
        
        api = {
            getUsers: callback => BaseApi.push("api", "users", "get", {}, callback),
            saveUser: (user, callback) => BaseApi.push("api", "users", "saveUser", {user: user}, callback),
            deleteUser: (id, callback) => BaseApi.push("api", "users", "deleteUser", {id: id}, callback),
            saveGroup: (group, callback) => BaseApi.push("api", "users", "saveGroup", {group: group}, callback),
            deleteGroup: (id, callback) => BaseApi.push("api", "users", "deleteGroup", {id: id}, callback),
        };
        routines = {
            getUsers: () => this.api.getUsers(records => {
                let selected = CoreTools.switch(
                    [this.state.users.includes(this.state.selected), ["user", this.state.selected.id]],
                    [this.state.groups.includes(this.state.selected), ["group", this.state.selected.id]],
                    [true, ["none"]]
                );
                this.setVal({
                    users: records.users,
                    groups: records.groups,
                    groupMembers: records.groupMembers
                }, () => this.setVal({selected: CoreTools.switch(
                    [selected[0] === "user", this.state.users.find(u => u.id === selected[1]) || false],
                    [selected[0] === "group", this.state.groups.find(g => g.id === selected[1]) || false],
                    [true, false]
                )}));
            }),
            saveUser: user => this.api.saveUser(user, results => {if (results.success) {this.setVal({modal: null}); this.routines.getUsers();}}),
            deleteUser: id => this.api.deleteUser(id, results => {if (results.success) {this.setVal({modal: null}); this.routines.getUsers();}}),
            saveGroup: group => this.api.saveGroup(group, results => {if (results.success) {this.setVal({modal: null}); this.routines.getUsers();}}),
            deleteGroup: id => this.api.deleteGroup(id, results => {if (results.success) {this.setVal({modal: null}); this.routines.getUsers();}})
        };

        onMount = () => {
            this.routines.getUsers();
        };

        userClick = (event, record, field) => this.setVal({selected: record});
        groupClick = (event, record, field) => this.setVal({selected: record});
        userContext = (event, record, field) => {
            event.preventDefault();
            this.setVal({contextMenu: <IElements.ContextMenu
                title="Group Options"
                menu={[
                    "User",
                    `${record.firstname} ${record.lastname}`,
                    "line",
                    {caption: "Select User", onClick: () => this.setVal({selected: record})},
                    {caption: "Edit User", onClick: () => this.setVal({modal: <EditUserModal key={record.id} onSave={user => this.routines.saveUser(user)} onDelete={id => this.routines.deleteUser(id)} user={record} onClose={() => this.setVal({modal: null})} />})},
                    "line",
                    {caption: "Add User To Group", disabled: !this.state.groups.includes(this.state.selected)}
                ]
            } event={event} onClose={() => this.setVal({contextMenu: null})} />})
        };
        groupContext = (event, record, field) => {
            event.preventDefault();
            this.setVal({contextMenu: <IElements.ContextMenu
                title="Group Options"
                menu={[
                    "Group",
                    record.name,
                    "line",
                    {caption: "Select Group", onClick: () => this.setVal({selected: record})},
                    {caption: "Edit Group", onClick: () => this.setVal({modal: <EditGroupModal key={record.id} onSave={group => this.routines.saveGroup(group)} onDelete={id => this.routines.deleteGroup(id)} group={record} onClose={() => this.setVal({modal: null})} />})},
                    "line",
                    {caption: "Add Group To User", disabled: !this.state.users.includes(this.state.selected)}
                ]
            } event={event} onClose={() => this.setVal({contextMenu: null})} />})
        };

        menuBar = state => <IElements.MenuBar.HeaderBar menu={[
            {
                caption: "File",
                menu: [
                    {caption: "Refresh", onClick: e => this.routines.getUsers()},
                    "line",
                    {caption: "Exit", onClick: e => this.close()}
                ]
            },
            {
                caption: "Edit",
                menu: [
                    {caption: "Unselect User/Group", onClick: e => this.setVal({selected: false}), disabled: !state.selected},
                    "line",
                    {caption: "Add User", onClick: e => this.setVal({modal: <EditUserModal key={0} onSave={user => this.routines.saveUser(user)} onClose={() => this.setVal({modal: null})} />})},
                    {caption: "Add Group", onClick: e => this.setVal({modal: <EditGroupModal key={0} onSave={group => this.routines.saveGroup(group)} onClose={() => this.setVal({modal: null})} />})},
                    "line",
                    {caption: "Edit Selected User", onClick: e => this.setVal({modal: <EditUserModal key={state.selected.id} onSave={user => this.routines.saveUser(user)} onDelete={id => this.routines.deleteUser(id)} user={state.selected} onClose={() => this.setVal({modal: null})} />}), disabled: !state.users.includes(state.selected)},
                    {caption: "Edit Selected Group", onClick: e => this.setVal({modal: <EditGroupModal key={state.selected.id} onSave={group => this.routines.saveGroup(group)} onDelete={id => this.routines.deleteGroup(id)} group={state.selected} onClose={() => this.setVal({modal: null})} />}), disabled: !state.groups.includes(state.selected)},
                ]
            },
            {caption: "View", disabled: true},
            {caption: "Help", disabled: true}
        ]} />;

        users = state => <IElements.Tables.StdSpread 
            header={[
                ["id", "ID"],
                ["firstname", "Firstname"],
                ["lastname", "Lastname"],
                ["email", "E-mail Address"],
                ["active", "Enabled"]
            ]}
            useKey={r => r.id}
            body={this.state.users}
            onClick={this.userClick}
            onContextMenu={this.userContext}
        />;

        groups = state => <IElements.Tables.StdSpread 
            header={[
                ["id", "ID"],
                ["name", "Name"],
                ["description", "Description"]
            ]}
            useKey={r => r.id}
            body={this.state.groups}
            onClick={this.groupClick}
            onContextMenu={this.groupContext}
        />;


        members = () => {
            const members = CoreTools.switch(
                [this.state.users.includes(this.state.selected), ["user", this.state.groupMembers.filter(gm => gm.userId === this.state.selected.id)]],
                [this.state.groups.includes(this.state.selected), ["group", this.state.groupMembers.filter(gm => gm.groupId === this.state.selected.id)]],
                [true, ["none"]]
            );
            switch (members[0]) {
                case "user": return <IElements.Tables.StdSpread 
                    header={[
                        ["id", "ID"],
                        ["name", "Name"],
                        ["description", "Description"]
                    ]}
                    useKey={r => r.id}
                    body={members[1].map(gm => this.state.groups.find(g => g.id === gm.groupId) || false).filter(gm => gm)}
                    onClick={() => {}}
                    onContextMenu={() => {}}
                />;
                case "group": return null;
                default: return null;
            }
        };

        user = state => <tr>
            <td style={{height: "100%", width: "40%", overflow: "auto", padding: "0px", verticalAlign: "top"}}><IElements.Titles.PanelHeader>User</IElements.Titles.PanelHeader><IElements.Tables.ListView
                style={{width: "100%"}}
                body={[
                    ["Firstname", state.selected.firstname],
                    ["Lastname", state.selected.lastname],
                    ["E-mail", state.selected.email],
                    ["Enabled", state.selected.active]
                ]}
            /></td>
            <td style={{height: "100%", width: "60%", overflow: "auto", padding: "0px", verticalAlign: "top"}}>{this.members()}</td>
        </tr>;

        group = state => <tr>
            <td style={{height: "100%", width: "40%", overflow: "auto", padding: "0px", verticalAlign: "top"}}><IElements.Titles.PanelHeader>Group</IElements.Titles.PanelHeader><div style={{padding: "5px"}}>Pending Update</div></td>
            <td style={{height: "100%", width: "60%", overflow: "auto", padding: "0px", verticalAlign: "top"}}><IElements.Titles.PanelHeader>Group Members</IElements.Titles.PanelHeader></td>
        </tr>;

        placeHolder = () => <tr><td colSpan={2} style={{height: "100%", padding: "0px", verticalAlign: "top"}}><IElements.Titles.PanelHeader>Users & Groups</IElements.Titles.PanelHeader><div style={{padding: "5px"}}>Select User/Group</div></td></tr>;

        selected = state => {
            if (!state.selected) {return this.placeHolder();}
            else if (state.users.includes(state.selected)) {return this.user(state);}
            else if (state.groups.includes(state.selected)) {return this.group(state);}
            else {return this.placeHolder();}
        };

        onUnmount = () => {};
        mobile = state => <div>Not Available</div>;
        desktop = state => <><table style={styles().table}>
            <tbody>
                <tr><td colSpan={2} style={{padding: "0px"}}>{this.menuBar(state)}</td></tr>
                <tr><td colSpan={2} style={{padding: "0px"}}><IElements.Titles.PanelHeader>Users</IElements.Titles.PanelHeader></td></tr>
                <tr><td colSpan={2} style={{height: "30%", overflow: "auto", padding: "0px", verticalAlign: "top"}}>{this.users(state)}</td></tr>
                <tr><td colSpan={2} style={{padding: "0px"}}><IElements.Titles.PanelHeader>Groups</IElements.Titles.PanelHeader></td></tr>
                <tr><td colSpan={2} style={{height: "30%", overflow: "auto", padding: "0px", verticalAlign: "top"}}>{this.groups(state)}</td></tr>
                <tr><td colSpan={2} style={{padding: "0px"}}><IElements.Titles.PanelHeader>Parameters</IElements.Titles.PanelHeader></td></tr>
                {this.selected(state)}
            </tbody>
        </table>{state.modal}{state.contextMenu}</>;
    }
};
export default Exp;