const States = [
    {short: "AL", long: "Alabama"},
    {short: "AK", long: "Alaska"},
    {short: "AZ", long: "Arizona"},
    {short: "AR", long: "Arkansas"},
    {short: "CA", long: "California"},
    {short: "CO", long: "Colorado"},
    {short: "CT", long: "Connecticut"},
    {short: "DE", long: "Delaware"},
    {short: "FL", long: "Florida"},
    {short: "GA", long: "Georgia"},
    {short: "HI", long: "Hawaii"},
    {short: "ID", long: "Idaho"},
    {short: "IL", long: "Illinois"},
    {short: "IN", long: "Indiana"},
    {short: "IA", long: "Iowa"},
    {short: "KS", long: "Kansas"},
    {short: "KY", long: "Kentucky"},
    {short: "LA", long: "Louisiana"},
    {short: "ME", long: "Maine"},
    {short: "MD", long: "Maryland"},
    {short: "MA", long: "Massachusetts"},
    {short: "MI", long: "Michigan"},
    {short: "MN", long: "Minnesota"},
    {short: "MS", long: "Mississippi"},
    {short: "MO", long: "Missouri"},
    {short: "MT", long: "Montana"},
    {short: "NE", long: "Nebraska"},
    {short: "NV", long: "Nevada"},
    {short: "NH", long: "New Hampshire"},
    {short: "NJ", long: "New Jersey"},
    {short: "NM", long: "New Mexico"},
    {short: "NY", long: "New York"},
    {short: "NC", long: "North Carolina"},
    {short: "ND", long: "North Dakota"},
    {short: "OH", long: "Ohio"},
    {short: "OK", long: "Oklahoma"},
    {short: "OR", long: "Oregon"},
    {short: "PA", long: "Pennsylvania"},
    {short: "RI", long: "Rhode Island"},
    {short: "SC", long: "South Carolina"},
    {short: "SD", long: "South Dakota"},
    {short: "TN", long: "Tennessee"},
    {short: "TX", long: "Texas"},
    {short: "UT", long: "Utah"},
    {short: "VT", long: "Vermont"},
    {short: "VA", long: "Virginia"},
    {short: "WA", long: "Washington"},
    {short: "WV", long: "West Virginia"},
    {short: "WI", long: "Wisconsin"},
    {short: "WY", long: "Wyoming"}
];

const Provinces = [
    {short: "AB", long: "Alberta"},
    {short: "BC", long: "British Columbia"},
    {short: "MB", long: "Manitoba"},
    {short: "NB", long: "New Brunswick"},
    {short: "NL", long: "Newfoundland and Labrador"},
    {short: "NS", long: "Nova Scotia"},
    {short: "ON", long: "Ontario"},
    {short: "PE", long: "Prince Edward Island"},
    {short: "QC", long: "Quebec"},
    {short: "SK", long: "Saskatchewan"},
    {short: "NT", long: "Northwest Territories"},
    {short: "NU", long: "Nunavut"},
    {short: "YT", long: "Yukon"}
];

const Months = [
    {short: "JAN", long: "January", value: 1, sValue: "01"},
    {short: "FEB", long: "February", value: 2, sValue: "02"},
    {short: "MAR", long: "March", value: 3, sValue: "03"},
    {short: "APR", long: "April", value: 4, sValue: "04"},
    {short: "MAY", long: "May", value: 5, sValue: "05"},
    {short: "JUN", long: "June", value: 6, sValue: "06"},
    {short: "JUL", long: "July", value: 7, sValue: "07"},
    {short: "AUG", long: "August", value: 8, sValue: "08"},
    {short: "SEP", long: "September", value: 9, sValue: "09"},
    {short: "OCT", long: "October", value: 10, sValue: "10"},
    {short: "NOV", long: "November", value: 11, sValue: "11"},
    {short: "DEC", long: "December", value: 12, sValue: "12"}
];

const Hours = [
    {value: 0, sValue: "00", ap: "AM", local: 12, sLocal: "12", ms: 0},
    {value: 1, sValue: "01", ap: "AM", local: 1, sLocal: "01", ms: 3600000},
    {value: 2, sValue: "02", ap: "AM", local: 2, sLocal: "02", ms: 3600000 * 2},
    {value: 3, sValue: "03", ap: "AM", local: 3, sLocal: "03", ms: 3600000 * 3},
    {value: 4, sValue: "04", ap: "AM", local: 4, sLocal: "04", ms: 3600000 * 4},
    {value: 5, sValue: "05", ap: "AM", local: 5, sLocal: "05", ms: 3600000 * 5},
    {value: 6, sValue: "06", ap: "AM", local: 6, sLocal: "06", ms: 3600000 * 6},
    {value: 7, sValue: "07", ap: "AM", local: 7, sLocal: "07", ms: 3600000 * 7},
    {value: 8, sValue: "08", ap: "AM", local: 8, sLocal: "08", ms: 3600000 * 8},
    {value: 9, sValue: "09", ap: "AM", local: 9, sLocal: "09", ms: 3600000 * 9},
    {value: 10, sValue: "10", ap: "AM", local: 10, sLocal: "10", ms: 3600000 * 10},
    {value: 11, sValue: "11", ap: "AM", local: 11, sLocal: "11", ms: 3600000 * 11},
    {value: 12, sValue: "12", ap: "PM", local: 12, sLocal: "12", ms: 3600000 * 12},
    {value: 13, sValue: "13", ap: "PM", local: 1, sLocal: "01", ms: 3600000 * 13},
    {value: 14, sValue: "14", ap: "PM", local: 2, sLocal: "02", ms: 3600000 * 14},
    {value: 15, sValue: "15", ap: "PM", local: 3, sLocal: "03", ms: 3600000 * 15},
    {value: 16, sValue: "16", ap: "PM", local: 4, sLocal: "04", ms: 3600000 * 16},
    {value: 17, sValue: "17", ap: "PM", local: 5, sLocal: "05", ms: 3600000 * 17},
    {value: 18, sValue: "18", ap: "PM", local: 6, sLocal: "06", ms: 3600000 * 18},
    {value: 19, sValue: "19", ap: "PM", local: 7, sLocal: "07", ms: 3600000 * 19},
    {value: 20, sValue: "20", ap: "PM", local: 8, sLocal: "08", ms: 3600000 * 20},
    {value: 21, sValue: "21", ap: "PM", local: 9, sLocal: "09", ms: 3600000 * 21},
    {value: 22, sValue: "22", ap: "PM", local: 10, sLocal: "10", ms: 3600000 * 22},
    {value: 23, sValue: "23", ap: "PM", local: 11, sLocal: "11", ms: 3600000 * 23}
];

const OptionLists = {
    States: States,
    Provinces: Provinces,
    Months: Months,
    Hours: Hours
};

export default OptionLists;