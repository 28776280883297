import CoreTools from "../services/CoreTools";
import React from "react";
import styles from "./IElements.module.css";
import SwitchBox from "./SwitchBox";
import Icon from "../directives/Icon";
// import BaseApi from "../services/BaseApi";

const funcWrap = func => (...params) => typeof(func) === "function" ? func(...params) : undefined;

const IElements = {
    Titles: {
        H1: props => <h1 style={{marginTop: "0px", marginBottom: "0px", fontSize: "24px"}}>{props.title || props.children}</h1>,
        H2: props => <h2 style={{marginTop: "0px", marginBottom: "0px", fontSize: "22px"}}>{props.title || props.children}</h2>,
        H3: props => <h3 style={{marginTop: "0px", marginBottom: "0px", fontSize: "20px"}}>{props.title || props.children}</h3>,
        H4: props => <h4 style={{marginTop: "0px", marginBottom: "0px", fontSize: "18px"}}>{props.title || props.children}</h4>,
        H5: props => <h5 style={{marginTop: "0px", marginBottom: "0px", fontSize: "16px"}}>{props.title || props.children}</h5>,
        H6: props => <h6 style={{marginTop: "0px", marginBottom: "0px", fontSize: "14px"}}>{props.title || props.children}</h6>,
        H7: props => <h7 style={{marginTop: "0px", marginBottom: "0px", fontSize: "12px"}}>{props.title || props.children}</h7>,
        PanelHeader: props => <div style={{
            color: "#000000",
            borderStyle: "outset",
            borderWidth: "2px",
            backgroundColor: "#CCCCCC",
            textAlign: "left",
            fontSize: "12px",
            cursor: "default",
            userSelect: "none",
            fontWeight: "bold",
            padding: "1px"
        }}>{props.children}</div>
    },
    Tables: {
        StdSpread: props => {
            const [order, setOrder] = React.useState(props.order || [CoreTools.first(props.header)(he => he[0]), false]);
            return <table style={{width: "100%", borderCollapse: "collapse"}}>
                <thead>
                    <tr>{
                        props.header.map(he => <th 
                            key={he[0]} 
                            style={{
                                color: "#000000",
                                borderStyle: "outset",
                                borderWidth: "2px",
                                backgroundColor: "#CCCCCC",
                                textAlign: "left",
                                fontSize: "12px",
                                cursor: "pointer",
                                userSelect: "none",
                                fontWeight: "bold",
                                padding: "1px",
                                whiteSpace: "nowrap"
                            }}
                            onClick={e => {
                                setOrder([he[0], order[0] === he[0] ? !order[1] : false])
                            }}
                        >{he[1]}{order[0] === he[0] && <Icon name={order[1] ? "caret-up" : "caret-down"} style={{marginLeft: "4px", marginRight: "-11px"}} />}</th>)
                    }</tr>
                </thead>
                <tbody>{
                    CoreTools.orderBy(props.body, order[0], order[1]).map((be, index) => <tr 
                        key={typeof(props.useKey) === "function" ? props.useKey(be, index) : index}
                        className={styles.tablerow}
                        style={{ 
                            background: props.selected === be ? "#d3dff8" : "", 
                            color: props.selected === be ? "#000" : "", 
                            cursor: props.onClick ? "pointer" : "default"
                        }}
                    >{props.header.map(he => <td
                        style={{fontSize: "14px", whiteSpace: "nowrap"}}
                        key={he[0]}
                        onClick={e => funcWrap(props.onClick)(e, be, he[0])}
                        onContextMenu={e => funcWrap(props.onContextMenu)(e, be, he[0])}
                    >{be[he[2] || he[0]]}</td>)}</tr>)
                }</tbody>
            </table>;
        },
        ListView: props => {
            return <table style={CoreTools.fuseObj({borderCollapse: "collapse", borderStyle: "solid", borderWidth: "1px 0px 0px 1px", borderColor: "#BBBBBB", fontSize: "14px"}, props.style)}>
                <tbody>{
                    props.body.map((r, i) => <tr key={i}>{(Array.isArray(r) ? r : [r]).map((c, ii) => <td
                        key={`${i}-${ii}`}
                        style={{
                            borderStyle: "solid",
                            borderWidth: "0px 1px 1px 0px",
                            borderColor: "#BBBBBB"
                        }}
                    >{CoreTools.asString(c)}</td>)}</tr>)
                }</tbody>
            </table>
        },
        TreeView: props => {
            const [treeState, setTreeState] = React.useState({});
            const TreeViewFolder = p => <tr key={p.folder.id}><td 
                style={{cursor: "pointer", fontSize: "14px", fontWeight: "bold", paddingLeft: (p.depth - 1) * 20 + "px"}}
                onClick={e => setTreeState({ ...treeState, [p.folder.id]: !treeState[p.folder.id] })}
                onContextMenu={e => funcWrap(props.onFolderContextMenu)(e, p.folder, props.header[0][0])}
            ><Icon name={treeState[p.folder.id]?"folderOpen":"folder"} size={14} /> {p.folder.name}</td></tr>
            const TreeViewItem = p => <tr
                className={styles.treerow}
                style={{background: props.selected === p.be ? "#d3dff8" : "", cursor: "pointer"}}
            >{props.header.map((he, index) => <td
                style={{fontSize: "14px", paddingLeft: !index ? p.depth * 20 + "px" : "0px"}}
                key={index}
                onClick={e => funcWrap(props.onItemClick)(e, p.be, he[0])}
                onContextMenu={e => funcWrap(props.onItemContextMenu)(e, p.be, he[0])}
            >{CoreTools.asString(p.be[he[0]])}</td>)}</tr>
            const treeViewRecursiveConstructor = (folder, depth) => [
                <TreeViewFolder key="folder" folder={folder} depth={depth} />,
                treeState[folder.id] ? props.body.filter(be => be.parentId === folder.id && !be.type).map((be, index) => <TreeViewItem key={index} depth={depth} be={be} />) : [],
                treeState[folder.id] ? props.body.filter(be => be.parentId === folder.id && be.type).map(folder => treeViewRecursiveConstructor(folder, depth + 1 )) : []
            ];
            return <table style={{width: "100%", borderCollapse: "collapse"}}>
                <thead>
                    <tr>{
                        props.header.map(he => <th key={he[0]} style={{
                            color: "#000000",
                            borderStyle: "outset",
                            borderWidth: "2px",
                            backgroundColor: "#CCCCCC",
                            textAlign: "left",
                            fontSize: "12px",
                            userSelect: "none",
                            fontWeight: "bold",
                            padding: "1px"
                        }}>{he[1]}</th>)
                    }</tr>
                </thead>
                <tbody>
                    {props.body.filter(be => be.parentId === 0 && !be.type).map((be, index)  => <TreeViewItem key={index} depth={0} be={be} />)}
                    {props.body.filter(be => be.parentId === 0 && be.type).map(folder => treeViewRecursiveConstructor(folder, 1))}
                </tbody>
            </table>;
        },
    },
    MenuBar: {
        HeaderBar: props => {
            const [menu, setMenu] = React.useState(false);
            const [target, setTarget] = React.useState(false);
            const menuClick = (event, menuitem) => {
                setTarget(event.target);
                if (menuitem.menu) {
                    setMenu(menuitem.menu);
                }
            };
            const menuLeave = event => {
                setMenu(false);
            };
            return <div onMouseLeave={e => menuLeave(e)} className={styles.menubar} style={{position: "relative", userSelect: "none"}}>{
                props.menu.map(
                    (mi, i) => <button key={i} className={styles.menuitem} disabled={mi.disabled} onClick={e => menuClick(e, mi)}>{mi.caption}</button>
                )
            }{menu && target && <div className={styles.submenu} style={{top: `${target.offsetHeight + 2}px`, left: `${target.offsetLeft - 5}px`}}>{menu.map(
                (ms, i) => ms === "line" ? <hr key={i} /> : <button key={`${i}`} onClick={ms.onClick} disabled={ms.disabled}>{ms.caption}</button>
            )}</div>}</div>;
        }
    },
    Form: {
        InfoLarge: props => <div className={styles.inputinfo} title={props.title} style={{width: "375px"}}>
            {props.title && <div className={styles.inputinfotitle}>{props.title}</div>}
            <div className={styles.inputinfobody}>{props.children}</div>
        </div>,
        InputLarge: props => <div className={styles.inputset} title={props.title}><div className={styles.caption}>{props.caption}</div><input type="text" placeholder={props.placeholder} onChange={e => funcWrap(props.onChange)(e.target.value)} defaultValue={props.value} style={{width: "300px"}} /></div>,
        InputMedium: props => <div className={styles.inputset} title={props.title}><div className={styles.caption}>{props.caption}</div><input type="text" placeholder={props.placeholder} onChange={e => funcWrap(props.onChange)(e.target.value)} defaultValue={props.value} style={{width: "240px"}} /></div>,
        InputSmall: props => <div className={styles.inputset} title={props.title}><div className={styles.caption}>{props.caption}</div><input type="text" placeholder={props.placeholder} onChange={e => funcWrap(props.onChange)(e.target.value)} defaultValue={props.value} style={{width: "180px"}} /></div>,
        InputNumber: props => <div className={styles.inputset} title={props.title}><div className={styles.caption}>{props.caption}</div><input type="number" placeholder={props.placeholder} onChange={e => funcWrap(props.onChange)(e.target.value)} defaultValue={props.value} style={{width: "80px"}} /></div>,
        Switch: props => <div className={styles.inputset} title={props.title}><div className={styles.caption}>{props.caption}</div><SwitchBox value={props.value} onChange={v => funcWrap(props.onChange)(v)} size={28} /></div>,
        ButtonGroupLarge: props => <div className={styles.buttonset} style={{width: "385px"}}>{props.buttons.filter(b => !b.hide).map((b, i) => <button key={b.index || i} style={b.style} onClick={() => funcWrap(b.onClick)(b)} disabled={typeof(b.disabled) === "function" ? b.disabled() : b.disabled}>{b.content}</button>)}</div>,
        ButtonGroupMedium: props => <div className={styles.buttonset} style={{width: "325px"}}>{props.buttons.filter(b => !b.hide).map((b, i) => <button key={b.index || i} style={b.style} onClick={() => funcWrap(b.onClick)(b)} disabled={typeof(b.disabled) === "function" ? b.disabled() : b.disabled}>{b.content}</button>)}</div>,
        SelectLarge: props => <div className={styles.inputset} title={props.title}>
            <div className={styles.caption}>{props.caption}</div>
            <select disabled={props.disabled} onChange={e => funcWrap(props.onChange)(e.target.value)} style={{width: "240px"}}>
                <option disabled>{props.placeholder}</option>
                {props.options.map(o => Array.isArray(o) ? <option value={o[1]} selected={props.value === o[1]}>{o[0]}</option> : <option name={o} selected={props.value === o}>{o}</option>)}
            </select>
        </div>
    },
    ContextMenu: props => <div className={styles.contextmenu} onMouseLeave={e => funcWrap(props.onClose)()} style={{left: `${props.event.clientX - 10}px`, top: `${props.event.clientY - 10}px`}}>{
        props.menu.map(
            (ms, i) => CoreTools.switch(
                [ms === "line", <hr key={i} />],
                [typeof(ms) === "string", <div key={i} className={styles.contexttitle} style={{fontWeight: i === 0 ? "bold" : "normal"}}>{ms}</div>],
                [CoreTools.isObject(ms), <button key={`${i}`} onClick={ms.onClick} disabled={ms.disabled}>{ms.caption}</button>],
                [true, null]
            )
        )
    }</div>,
    CompElems: {
        ScrollBox: props => {
            let parent = null;
            let box = null;
            const resize = () => {
                if (box && parent) {
                    parent.style.verticalAlign = "top";
                    box.style.position = "absolute";
                    setTimeout(() => {
                        box.style.width = parent.offsetWidth + "px";
                        box.style.height = parent.offsetHeight + "px";
                        box.style.position = "inherit";
                    });
                }
            };
            const setRef = ref => {
                if (ref) {
                    box = ref;
                    parent = ref.parentNode;
                    resize();
                }
            };
            if (typeof(props.onResize) === "function") {
                props.onResize(resize);
            }
            return <div
                ref={setRef}
                style={{overflow: "auto", width: "100%", height: "100%"}}
            >{props.children}</div>;
        }
    }
};
export default IElements;