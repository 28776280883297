import React from "react";
import CoreTools from "../services/CoreTools";
import Icon from "./Icon";
import styles from "./AudioPlayer.module.css";

class AudioPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            audio: false,
            duration: 0,
            play: false
        };
    }

    setVal = CoreTools.stateHandler(this);
    
    progressRef = false;

    routines = {
        playPause: () => {this.audio.paused ? this.audio.play() : this.audio.pause();},
        event: event => {
            switch (event.type) {
                case "play": this.setVal({play: true}); break;
                case "pause": this.setVal({play: false}); break;
                case "timeupdate": this.progressRef.value = event.target.currentTime; break;
                case "durationchange": this.setVal({duration: this.audio.duration || 0}); break;
                case "ended": this.progressRef.value = Math.ceil(this.state.duration); break;
                default:
            }
        },
        seek: event => {this.audio.currentTime = event.target.value;}
    };

    componentDidMount = () => {
        this.setVal("mount");
        this.audio = new Audio(this.props.filename);
        this.audio.addEventListener("play", this.routines.event);
        this.audio.addEventListener("pause", this.routines.event);
        this.audio.addEventListener("ended", this.routines.event);
        this.audio.addEventListener("timeupdate", this.routines.event);
        // this.audio.addEventListener("loaddata", this.routines.event);
        this.audio.addEventListener("durationchange", this.routines.event);
    };

    componentWillUnmount = () => {
        this.setVal("unmount");
        this.audio.removeEventListener("play", this.routines.event);
        this.audio.removeEventListener("pause", this.routines.event);
        this.audio.removeEventListener("ended", this.routines.event);
        this.audio.removeEventListener("timeupdate", this.routines.event);
        // this.audio.removeEventListener("loaddata", this.routines.event);
        this.audio.removeEventListener("durationchange", this.routines.event);
    };

    render = () => <div className={styles["audio-player"]} style={{width: "100%"}}>
        {this.props.icon && <span className={styles["ctrls"]} style={{textAlign: "center"}}>
            <Icon name={this.props.icon} style={{marginLeft: "4px", verticalAlign: "middle"}} size="24px" title={this.props.title} />
        </span>}
        <span className={styles["ctrls"]}>
            <button className={styles["ctrl"]} onClick={() => this.routines.playPause()}>{this.state.play ? <Icon name="pause" margin="0" /> : <Icon name="play" margin="0" />}</button>
        </span>
        <span className={styles["slider"]}>
            <input type="range" min="0" max={Math.ceil(this.state.duration)} ref={r => this.progressRef = r} onChange={e => this.routines.seek(e)} style={{width: "100%"}} />
        </span>
        <span className={styles["duration"]}>{Math.ceil(this.state.duration || 0)}</span>
    </div>;
}
export default AudioPlayer;