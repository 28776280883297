import React from "react";
import CoreTools from "../../services/CoreTools";
import UserService from "../../services/UserService";
import Taskbar from "../taskbar/Taskbar";
import AppSpace from "./appSpace";

const styles = () => ({
    logoDesktop: {
        position: "fixed",
        top: "30px",
        left: "30px",
        pointerEvents: "none"
    },
    logoMobile: {
        position: "fixed",
        top: "30px",
        left: "50%",
        transform: "translateX(-50%)",
        pointerEvents: "none"
    },
    nameTagDesktop: {
        position: "fixed",
        bottom: "50px",
        right: "5px",
        pointerEvents: "none",
        color: "var(--color-taskbar-background)",
        fontFamily: "federationregular",
        fontSize: "20px",
        textShadow: "var(--color-shadow-br)"
    },
    nameTagMobile: {
        position: "fixed",
        top: "60px",
        left: "0px",
        width: "100%",
        textAlign: "center",
        pointerEvents: "none",
        color: "#e34949",
        fontFamily: "federationregular",
        fontSize: "20px",
        textShadow: "var(--color-shadow-br)"
    }
});

const Desktop = props => <>
    <img src="/images/logo-consumer-genius-300x29.png" alt="ConsumerGenius" style={styles().logoDesktop} />
    <div style={styles().nameTagDesktop}>{UserService.user.firstname} {UserService.user.lastname}</div>
    <AppSpace />
    <Taskbar />
</>;

const Mobile = props => <>
    <img src="/images/logo-consumer-genius-300x29.png" alt="ConsumerGenius" style={styles().logoMobile} />
    <div style={styles().nameTagMobile}>{UserService.user.firstname} {UserService.user.lastname}</div>
    <AppSpace />
    <Taskbar />
</>;

const Environments = props => {
    const [showMobile, setMobile] = React.useState(CoreTools.state.isMobile);
    React.useEffect(() => {
        const mobileWatcher = CoreTools.on("isMobile", isMobile => setMobile(isMobile));
        return mobileWatcher;
    }, []);
    return showMobile ? <Mobile /> : <Desktop />;
};
export default Environments;