import React from "react";
import Icon from "../../../directives/Icon";
import IElements from "../../../directives/IElements";
import AppManager from "../../../services/AppManager";
import BaseApi from "../../../services/BaseApi";
import CoreTools from "../../../services/CoreTools";
import Panels from "../../../shell/windows/Panels";

class SearchModal extends Panels.Modal {
    initState = {
        params: this.props.params,
        title: "Search Contacts",
        clear: 0
    };

    filters = {
        firstName: this.props.filters.firstName || "",
        lastName: this.props.filters.lastName || "",
        dob: this.props.filters.dob || "",
        email: this.props.filters.email || "",
        phone: this.props.filters.phone || "",
        streetNumber: this.props.filters.streetNumber || "",
        streetName: this.props.filters.streetName || "",
        unit: this.props.filters.unit || "",
        city: this.props.filters.city || "",
        state: this.props.filters.state || "",
        zipCode: this.props.filters.zipCode || ""
    };

    clear = () => {
        Object.keys(this.filters).forEach(key => this.filters[key] = "");
        this.setVal({clear: ++this.state.clear});
    };

    search = () => {
        this.props.search(this.filters);
        this.close();
    };

    onMount = () => {};
    onUnmount = () => {};
    desktop = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InputMedium caption="Firstname" key={this.state.clear} value={this.filters.firstName} onChange={nv => this.filters.firstName = nv} /></div>
        <div><IElements.Form.InputMedium caption="Lastname" key={this.state.clear} value={this.filters.lastName} onChange={nv => this.filters.lastName = nv} /></div>
        <div><IElements.Form.InputMedium caption="Date Of Birth" key={this.state.clear} value={this.filters.dob} onChange={nv => this.filters.dob = nv} /></div>
        <div><IElements.Form.InputMedium caption="E-mail" key={this.state.clear} value={this.filters.email} onChange={nv => this.filters.email = nv} /></div>
        <div><IElements.Form.InputMedium caption="Phone" key={this.state.clear} value={this.filters.phone} onChange={nv => this.filters.phone = nv} /></div>
        <div><IElements.Form.InputMedium caption="Street Number" key={this.state.clear} value={this.filters.streetNumber} onChange={nv => this.filters.streetNumber = nv} /></div>
        <div><IElements.Form.InputMedium caption="Street Name" key={this.state.clear} value={this.filters.streetName} onChange={nv => this.filters.streetName = nv} /></div>
        <div><IElements.Form.InputMedium caption="Unit" key={this.state.clear} value={this.filters.unit} onChange={nv => this.filters.unit = nv} /></div>
        <div><IElements.Form.InputMedium caption="City" key={this.state.clear} value={this.filters.city} onChange={nv => this.filters.city = nv} /></div>
        <div><IElements.Form.InputMedium caption="State" key={this.state.clear} value={this.filters.state} onChange={nv => this.filters.state = nv} /></div>
        <div><IElements.Form.InputMedium caption="Zip Code" key={this.state.clear} value={this.filters.zipCode} onChange={nv => this.filters.zipCode = nv} /></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Cancel", onClick: () => this.close()},
            {content: "Clear", onClick: () => this.clear()},
            {content: "Search", onClick: () => this.search()}
        ]} /></div>
    </div>;
}

const Contacts = {
    singleInstance: true,
    menuGroup: "Contacts",
    name: "Contacts",
    title: "Contacts",
    description: "All Contacts",
    menuIcon: <Icon name="contacts" size={18} />,
    taskbarIcon: <Icon name="contacts" size={20} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class Wdw extends Panels.Window {
        initState = {
            contacts: [],
            filters: {}
        };
        
        api = {
            search: filters => cb => BaseApi.push("api", "contacts", "search", filters || {}, cb)
        };

        routines = {
            search: filters => {
                if (filters) {
                    this.setVal({filters: filters});
                    this.api.search(filters)(results => this.setVal({contacts: results.data}));
                } else {
                    this.api.search(this.state.filters)(results => this.setVal({contacts: results.success && Array.isArray(results.data) ? results.data : []}));
                }
            }
        };

        onMount = () => {
            this.routines.search();
        };

        contactClick = (event, record) => {
            AppManager.open("Contact", {contactId: record.id});
        };

        openSearchModal = () => {
            this.addModal(<SearchModal key="searchModal" filters={this.state.filters} onClose={() => {this.removeModal("searchModal"); console.log("Close Modal");}} search={this.routines.search} />);
        };

        menuBar = () => <IElements.MenuBar.HeaderBar menu={[
            {
                caption: "File", 
                menu: [
                    {caption: "Refresh", onClick: () => this.routines.search()},
                    "line",
                    {caption: "Exit", onClick: this.close}
                ]
            },
            {
                caption: "Find",
                menu: [
                    {caption: "Contact Search", onClick: this.openSearchModal}
                ]
            },
            {caption: "View", disabled: true},
            {caption: "Help", disabled: true}
        ]} />;

        contacts = () => <IElements.Tables.StdSpread 
            header={[
                ["firstName", "Firstname"],
                ["lastName", "Lastname"],
                ["phone", "Phone", "asPhone"],
                ["email", "Email"],
                ["dob", "DOB"],
                this.state.filters.streetNumber && ["streetNumber", "St #"],
                this.state.filters.streetName && ["streetName", "St. Name"],
                this.state.filters.unit && ["unit", "Unit"],
                this.state.filters.city && ["city", "City"],
                ["state", "State"],
                this.state.filters.zipCode && ["zipCode", "Zip"],
                ["stampCreated", "Created", "created"],
                ["stampLastUsed", "Updated", "updated"],
                ["applicationsCount", "Apps"]
            ].filter(f => f)}
            useKey={r => r.id}
            body={CoreTools.uniqueBy(this.state.contacts, "id").map(r => {
                r.created = CoreTools.date.lhDateTime(r.stampCreated);
                r.updated = CoreTools.date.lhDateTime(r.stampLastUsed);
                r.asPhone = CoreTools.asPhone(r.phone);
                return r;
            })}
            onClick={this.contactClick}
            // onContextMenu={this.userContext}
        />;

        scrollBoxResize = () => null;
        onResize = (width, height) => {
            this.scrollBoxResize();
        };

        searchBar = () => <div style={{backgroundColor: "#cccccc"}}>{[
            this.state.filters.firstName ? `Firstname = ${this.state.filters.firstName}` : false,
            this.state.filters.lastName ? `Lastname = ${this.state.filters.lastName}` : false,
            this.state.filters.dob ? `DOB = ${this.state.filters.dob}` : false,
            this.state.filters.email ? `Email = ${this.state.filters.email}` : false,
            this.state.filters.phone ? `Phone = ${this.state.filters.phone}` : false,
            this.state.filters.streetNumber ? `Street Number = ${this.state.filters.streetNumber}` : false,
            this.state.filters.streetName ? `Street Name = ${this.state.filters.streetName}` : false,
            this.state.filters.unit ? `Unit = ${this.state.filters.unit}` : false,
            this.state.filters.city ? `City = ${this.state.filters.city}` : false,
            this.state.filters.state ? `State = ${this.state.filters.state}` : false,
            this.state.filters.zipCode ? `Zip Code = ${this.state.filters.zipCode}` : false,
        ].filter(f => f).map((f, i, a) => <span key={i} style={{whiteSpace: "nowrap"}}>{f}{i < a.length - 1 && ", "}</span>)}</div>;

        desktop = (state) => <table style={{width: "100%", height: "100%", borderCollapse: "collapse", borderSpacing: "0px"}}>
            <tbody>
                <tr><td style={{padding: "0px"}}>{this.menuBar()}</td></tr>
                <tr><td style={{padding: "0px", borderStyle: "solid", borderWidth: "1px 0px 1px 0px", borderColor: "#aaaaaa"}}>{this.searchBar()}</td></tr>
                <tr><td style={{padding: "0px", height: "100%", width: "100%"}}><IElements.CompElems.ScrollBox onResize={ctrls => this.scrollBoxResize = ctrls}>{this.contacts()}</IElements.CompElems.ScrollBox></td></tr>
            </tbody>
        </table>;
    }
};
export default Contacts;