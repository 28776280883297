import AppManager from "../../../services/AppManager";
import React from "react";
import Icon from "../../../directives/Icon";
import BaseApi from "../../../services/BaseApi";
import IElements from "../../../directives/IElements";
import Modals from "../../../shell/windows/Modal";
import CoreTools from "../../../services/CoreTools";
import Panels from "../../../shell/windows/Panels";

const styles = ( row_key ) => ({
    table: {
        width: "100%",
        height: "100%",
        borderCollapse: "collapse"
    },
});


class EditProjectModal extends React.Component {
    constructor(props) {
        super(props);
        Modals.Standard(this, {
            id: props.project?.id || null,
            name: props.project?.name || "",
            description: props.project?.description ||"",
        }, {
            menuIcon: null, 
            title: props.project?.id ? "Edit Project" : "Add Project",
            width: "auto",
            height: "auto",
            zIndex: 1,
            onClose: props.onClose
        }, () => {});
    }
    save = () => this.props.onSave({
        id: this.state.id,
        name: this.state.name,
        description: this.state.description,
    });
    edit = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InputMedium caption="Name" value={this.state.name} onChange={nv => this.setVal({name: nv})} /></div>
        <div><IElements.Form.InputMedium caption="Description" value={this.state.description} onChange={nv => this.setVal({description: nv})} /></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Delete", onClick: () => this.setVal({delete: true}), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.props.onClose()},
            {content: "Save", onClick: this.save, disabled: () => !this.state.name || !this.state.description}
        ]} /></div>
    </div>;
    delete = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InfoLarge title={`Delete the Project '${this.state.name}'?`} /></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Confirm Delete", onClick: () => this.props.onDelete(this.state.id), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.setVal({delete: false})}
        ]} /></div>
    </div>;
    desktop = () => this.state.delete ? this.delete() : this.edit();
}

const Exp = {
    singleInstance: true,
    menuGroup: "Development",
    name: "Projects",
    title: "Projects",
    description: "Ongoing View and Manage Ongoing Projects",
    menuIcon: <Icon name="project" size={18} />,
    taskbarIcon: <Icon name="project" size={22} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class Projects extends Panels.Window {
        initState = {
            projects: [],
            selected: false,
            modal: null,
            contextMenu: null
        };
        
        api = {
            getProjects: callback => BaseApi.push("api", "projects", "getProjects", {}, callback),
            deleteProject: (id, callback) => BaseApi.push("api", "projects", "deleteProject", {id: id}, callback),
            saveProject: (project, callback) => BaseApi.push("api", "projects", "saveProject", {project: project}, callback),
        };
        routines = {
            getProjects: () => this.api.getProjects(records => {
                let selected = CoreTools.switch(
                    [this.state.projects.includes(this.state.selected), ["project", this.state.selected.id]],
                    [true, ["none"]]
                );

                records.projects.forEach(project => {
                    let arr = [];
                    records.tasks.filter(task => task.projectId === project.id).forEach(task => { 
                        records.items.filter(item => item.taskId === task.id).forEach(item => { 
                            arr.push( item.complete )
                        });
                    });
                    project.progress = arr.length ? Math.floor( arr.filter(a => a).length / arr.length * 100 ) + "%" : "";
                });
                            
                this.setVal({
                    projects: records.projects ?? [],
                }, () => this.setVal({selected: CoreTools.switch(
                    [selected[0] === "project", this.state.projects.find(u => u.id === selected[1]) || false],
                    [true, false]
                )}));
            }),
            saveProject: project => this.api.saveProject(project, results => {if (results.success) {this.setVal({modal: null}); this.routines.getProjects();}}),
            deleteProject: id => this.api.deleteProject(id, results => {if (results.success) {this.setVal({modal: null}); this.routines.getProjects();}}),
        };

        onMount = () => {
            this.routines.getProjects();
        };
        onUnMount = () => {};

        menuBar = state => <IElements.MenuBar.HeaderBar menu={[
            {
                caption: "File",
                menu: [
                    {caption: "Refresh", onClick: e => this.routines.getProjects()},
                    "line",
                    {caption: "Exit", onClick: e => this.close()}
                ]
            },
            {
                caption: "Edit",
                menu: [
                    {caption: "Unselect Project", onClick: e => this.setVal({selected: false}), disabled: !state.selected},
                    "line",
                    {caption: "Add Project", onClick: e => this.setVal({modal: <EditProjectModal key={0} onSave={project => this.routines.saveProject(project)} onClose={() => this.setVal({modal: null})} />})},
                    "line",
                    {caption: "Edit Selected Project", onClick: e => this.setVal({modal: <EditProjectModal key={state.selected.id} onSave={project => this.routines.saveProject(project)} onDelete={id => this.routines.deleteProject(id)} project={state.selected} onClose={() => this.setVal({modal: null})} />}), disabled: !state.projects.includes(state.selected)},
                ]
            },
            {
                caption: "View",
                menu: [
                    {caption: "View Selected Project Tasks", onClick: e => AppManager.open("Project Tasks", {project: this.state.selected})},
                ]
            },
            {caption: "Help", disabled: true}
        ]} />;



        projects = state => <IElements.Tables.StdSpread 
            header={[
                ["name", "Name"],
                ["description", "Description"],
                ["progress", "Progress"],
            ]}
            useKey={r => r.id}
            selected={this.state.selected}
            body={this.state.projects}
            onClick={this.projectClick}
            onContextMenu={this.projectContext}
        />;

        projectClick = (event, record, field) => {
            AppManager.open("Project Tasks", {project: record});
            this.setVal({selected: record});
        };
        projectContext = (event, record, field) => {
            event.preventDefault();
            this.setVal({contextMenu: <IElements.ContextMenu
                title="Project Options"
                menu={[
                    "Project",
                    `${record.name} ${record.description}`,
                    "line",
                    {caption: "Select Project", onClick: () => this.setVal({selected: record})},
                    {caption: "Edit Project", onClick: () => this.setVal({modal: <EditProjectModal key={record.id} onSave={project => this.routines.saveProject(project)} onDelete={id => this.routines.deleteProject(id)} project={record} onClose={() => this.setVal({modal: null})} />})},
                ]
            } event={event} onClose={() => this.setVal({contextMenu: null})} />})
        };

        project = state => <tr>
            <td style={{height: "50%", width: "100%", overflow: "auto", padding: "0px", verticalAlign: "top"}}><IElements.Titles.PanelHeader>Project</IElements.Titles.PanelHeader><IElements.Tables.ListView
                style={{width: "100%"}}
                body={[
                    ["Name", state.selected.name],
                    ["Description", state.selected.description],
                ]}
            /></td>
        </tr>;


        mobile = state => <div>Not Available</div>;
        desktop = state => <><table style={styles().table}>
            <tbody>
                <tr><td colSpan={2} stysle={{padding: "0px"}}>{this.menuBar(state)}</td></tr>
                <tr><td colSpan={2} style={{padding: "0px"}}><IElements.Titles.PanelHeader>Projects</IElements.Titles.PanelHeader></td></tr>
                <tr><td colSpan={2} style={{height: "100%", overflow: "auto", padding: "0px", verticalAlign: "top"}}>{this.projects(state)}</td></tr>
            </tbody>
        </table>{state.modal}{state.contextMenu}</>;
    }
};
export default Exp;