import React from "react";
import styles from "./DatePicker.module.css";
import OptionLists from "../services/OptionLists";
import Icon from "./Icon";

const DatePicker = props => {
    const initDate = () => {
        let date = !props.date || isNaN(new Date(props.date)) ? new Date() : new Date(props.date);
        date.setHours(0, 0, 0, 0);
        return date;
    };

    const [thisDate, setThisDate] = React.useState(initDate());

    const close = () => {
        thisDate.setHours(0, 0, 0, 0);
        setThisDate(new Date(thisDate));
        if (typeof(props.onSelect) === "function") {
            props.onSelect(thisDate);
        }
    };

    const selectDate = day => {
        thisDate.setDate(day);
        close();
    };

    const buildDaysElement = () => {
        let date = new Date(thisDate);
        date.setDate(1);
        let wd = date.getDay();
        date.setMonth(date.getMonth() + 1);
        date.setDate(date.getDate() - 1);
        date.setHours(0, 0, 0, 0);
        let lastDay = date.getDate();
        let weeks = [];
        let week = [];
        for (let i = 0; i < wd; i++) {week.push(null); if (week.length === 7) {weeks.push(week); week = [];}}
        for (let i = 1; i <= lastDay; i++) {
            week.push(i);
            if (week.length === 7) {weeks.push(week); week = [];}
        }
        while (week.length > 0 || weeks.length < 6) {week.push(null); if (week.length === 7) {weeks.push(week); week = [];}}
        return weeks.map((wks, i) => <tr key={"w" + i}>{
            wks.map((d, ii) => <td
                key={"w" + i + "d" + ii}
                className={[
                    d && (d === new Date().getDate() ? styles.today : styles.day),
                    d && (d === thisDate.getDate() && styles.selected )
                ].filter(v => v).join(" ")}
                onClick={() => d && selectDate(d)}
            >{d}</td>)
        }</tr>);
    };
    
    return <table className={styles.calContainer}>
        <tbody>
            <tr className={styles.titlebar}><td colSpan={7}>{props.title}</td></tr>
            <tr>
                <td className={styles.button} title="Previous Month" onClick={() => setThisDate((() => {thisDate.setMonth(thisDate.getMonth() - 1); return new Date(thisDate);})())}><Icon name="arrow-left" margin={0} /></td>
                <td colSpan={5}>{OptionLists.Months.filter(m => m.value === thisDate.getMonth() + 1).map(m => m.long).join("")} - {thisDate.getFullYear()}</td>
                <td className={styles.button} title="Next Month" onClick={() => setThisDate((() => {thisDate.setMonth(thisDate.getMonth() + 1); return new Date(thisDate);})())}><Icon name="arrow-right" margin={0} /></td>
            </tr>
            {buildDaysElement()}
            <tr>
                <td className={styles.button} title="Previous Day" onClick={() => setThisDate((() => {thisDate.setDate(thisDate.getDate() - 1); return new Date(thisDate);})())}><Icon name="step-backward" margin={0} /></td>
                <td className={styles.day} colSpan={2} onClick={() => setThisDate(new Date())}><Icon name="today" />Today</td>
                <td className={styles.button} title="Next Day" onClick={() => setThisDate((() => {thisDate.setDate(thisDate.getDate() + 1); return new Date(thisDate);})())}><Icon name="step-forward" margin={0} /></td>
                <td className={styles.button} colSpan={3} onClick={close}><Icon name="close" />Close</td>
            </tr>
        </tbody>
    </table>;
};
export default DatePicker;