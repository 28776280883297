import React from "react";
import Icon from "../../../directives/Icon";
import BaseApi from "../../../services/BaseApi";
import IElements from "../../../directives/IElements";
import Modals from "../../../shell/windows/Modal";
import CoreTools from "../../../services/CoreTools";
import Panels from "../../../shell/windows/Panels";

const styles = ( row_key ) => ({
    table: {
        width: "100%",
        height: "100%",
        borderCollapse: "collapse"
    },
});


class EditTaskModal extends React.Component {
    constructor(props) {
        super(props);
        Modals.Standard(this, {
            id: props.task?.id || null,
            name: props.task?.name || "",
            description: props.task?.description || "",
        }, {
            menuIcon: null, 
            title: props.task?.id ? "Edit Task" : "Add Task",
            width: "auto",
            height: "auto",
            zIndex: 1,
            onClose: props.onClose
        }, () => {});
    }
    save = () => this.props.onSave({
        id: this.state.id,
        name: this.state.name,
        description: this.state.description,
    });
    edit = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InputMedium caption="Name" value={this.state.name} onChange={nv => this.setVal({name: nv})} /></div>
        <div><IElements.Form.InputMedium caption="Description" value={this.state.description} onChange={nv => this.setVal({description: nv})} /></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Delete", onClick: () => this.setVal({delete: true}), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.props.onClose()},
            {content: "Save", onClick: this.save, disabled: () => !this.state.name || !this.state.description}
        ]} /></div>
    </div>;
    delete = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InfoLarge title={`Delete the Task '${this.state.name}'?`} /></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Confirm Delete", onClick: () => this.props.onDelete(this.state.id), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.setVal({delete: false})}
        ]} /></div>
    </div>;
    desktop = () => this.state.delete ? this.delete() : this.edit();
}
class EditNoteModal extends React.Component {
    constructor(props) {
        super(props);
        Modals.Standard(this, {
            id: props.note?.id || null,
            note: props.note?.note || "",
        }, {
            menuIcon: null, 
            title: props.note?.id ? "Edit Note" : "Add Note",
            width: "auto",
            height: "auto",
            zIndex: 1,
            onClose: props.onClose
        }, () => {});
    }
    save = () => this.props.onSave({
        id: this.state.id,
        note: this.state.note,
    });
    edit = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InputMedium caption="Note" value={this.state.note} onChange={nv => this.setVal({note: nv})} /></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Delete", onClick: () => this.setVal({delete: true}), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.props.onClose()},
            {content: "Save", onClick: this.save, disabled: () => !this.state.note}
        ]} /></div>
    </div>;
    delete = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InfoLarge title={`Delete the Note '${this.state.note}'?`} /></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Confirm Delete", onClick: () => this.props.onDelete(this.state.id), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.setVal({delete: false})}
        ]} /></div>
    </div>;
    desktop = () => this.state.delete ? this.delete() : this.edit();
}
class EditItemModal extends React.Component {
    constructor(props) {
        super(props);
        Modals.Standard(this, {
            id: props.item?.id || null,
            name: props.item?.name || "",
            complete: props.item?.complete || false,
        }, {
            menuIcon: null, 
            title: props.item?.id ? "Edit Item" : "Add Item",
            width: "auto",
            height: "auto",
            zIndex: 1,
            onClose: props.onClose
        }, () => {});
    }
    save = () => this.props.onSave({
        id: this.state.id,
        name: this.state.name,
        complete: this.state.complete,
    });
    edit = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InputMedium caption="Name" value={this.state.name} onChange={nv => this.setVal({name: nv})} /></div>
        <div><IElements.Form.Switch caption="Enabled" value={this.state.complete} onChange={nv => this.setVal({complete: nv})} /></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Delete", onClick: () => this.setVal({delete: true}), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.props.onClose()},
            {content: "Save", onClick: this.save, disabled: () => !this.state.name}
        ]} /></div>
    </div>;
    delete = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InfoLarge title={`Delete the Item '${this.state.name}'?`} /></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Confirm Delete", onClick: () => this.props.onDelete(this.state.id), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.setVal({delete: false})}
        ]} /></div>
    </div>;
    desktop = () => this.state.delete ? this.delete() : this.edit();
}

const Exp = {
    singleInstance: false,
    name: "Project Tasks",
    title: "Project Tasks",
    description: "Ongoing View and Manage Ongoing Project Tasks",
    menuIcon: <Icon name="project" size={18} />,
    taskbarIcon: <Icon name="project" size={22} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class Tasks extends Panels.Window {
        initState = {
            tasks: [],
            notes: [],
            items: [],
            selectedTask: false,
            selectedNote: false,
            selectedItem: false,
            modal: null,
            contextMenu: null
        };
        
        api = {
            getTasks: callback => BaseApi.push("api", "projects", "getTasks", {projectId: this.props.params.project.id, taskId: this.state.selectedTask?.id}, callback),
            deleteTask: (id, callback) => BaseApi.push("api", "projects", "deleteTask", {id: id}, callback),
            saveTask: (task, callback) => BaseApi.push("api", "projects", "saveTask", {task: { ...task, projectId: this.props.params.project.id } }, callback),
            deleteNote: (id, callback) => BaseApi.push("api", "projects", "deleteNote", {id: id}, callback),
            saveNote: (note, callback) => BaseApi.push("api", "projects", "saveNote", {note: { ...note, projectId: this.props.params.project.id, taskId: this.state.selectedTask.id } }, callback),
            deleteItem: (id, callback) => BaseApi.push("api", "projects", "deleteItem", {id: id}, callback),
            saveItem: (item, callback) => BaseApi.push("api", "projects", "saveItem", {item: { ...item, projectId: this.props.params.project.id, taskId: this.state.selectedTask.id } }, callback),
        };
        routines = {
            getTasks: () => this.api.getTasks(records => {
                let selectedTask = CoreTools.switch(
                    [this.state.tasks.includes(this.state.selectedTask), ["task", this.state.selectedTask.id]],
                    [true, ["none"]]
                );
                let selectedNote = CoreTools.switch(
                    [this.state.notes.includes(this.state.selectedNote), ["note", this.state.selectedNote.id]],
                    [true, ["none"]]
                );
                let selectedItem = CoreTools.switch(
                    [this.state.items.includes(this.state.selectedItem), ["item", this.state.selectedItem.id]],
                    [true, ["none"]]
                );
                
                records.tasks.forEach(t => {
                    t.countNotes = records.notes.filter(n => n.taskId === t.id).length;
                    t.countItems = records.items.filter(i => i.taskId === t.id).length;
                });

                records.tasks.forEach(task => {
                    let arr = records.items.filter(item => item.taskId === task.id).map(item => item.complete);
                    task.progress = arr.length ? Math.floor( arr.filter(a => a).length / arr.length * 100 ) + "%" : "";
                });

                this.setVal({
                    tasks: records.tasks ?? [],
                    notes: records.notes ?? [],
                    items: records.items ?? [],
                }, () => this.setVal({
                    selectedTask: CoreTools.switch(
                        [selectedTask[0] === "task", this.state.tasks.find(u => u.id === selectedTask[1]) || false],
                        [true, false]
                    ),
                    selectedNote: CoreTools.switch(
                        [selectedNote[0] === "note", this.state.notes.find(u => u.id === selectedNote[1]) || false],
                        [true, false]
                    ),
                    selectedItem: CoreTools.switch(
                        [selectedItem[0] === "item", this.state.items.find(u => u.id === selectedItem[1]) || false],
                        [true, false]
                    ),
                }));
            }),
            saveTask: task => this.api.saveTask(task, results => {if (results.success) {this.setVal({modal: null}); this.routines.getTasks();}}),
            deleteTask: id => this.api.deleteTask(id, results => {if (results.success) {this.setVal({modal: null}); this.routines.getTasks();}}),
            saveNote: note => this.api.saveNote(note, results => {if (results.success) {this.setVal({modal: null}); this.routines.getTasks();}}),
            deleteNote: id => this.api.deleteNote(id, results => {if (results.success) {this.setVal({modal: null}); this.routines.getTasks();}}),
            saveItem: item => this.api.saveItem(item, results => {if (results.success) {this.setVal({modal: null}); this.routines.getTasks();}}),
            deleteItem: id => this.api.deleteItem(id, results => {if (results.success) {this.setVal({modal: null}); this.routines.getTasks();}}),
        };

        onMount = () => {
            this.routines.getTasks();
        };
        onUnMount = () => {};

        menuBar = state => <IElements.MenuBar.HeaderBar menu={[
            {
                caption: "File",
                menu: [
                    {caption: "Refresh", onClick: e => this.routines.getTasks()},
                    "line",
                    {caption: "Exit", onClick: e => this.close()}
                ]
            },
            {
                caption: "Edit",
                menu: [
                    {caption: "Unselect Task", onClick: e => this.setVal({selectedTask: false, selectedNote: false, selectedItem: false}, () => this.routines.getTasks()), disabled: !state.selectedTask},
                    {caption: "Unselect Note", onClick: e => this.setVal({selectedNote: false}), disabled: !state.selectedNote},
                    {caption: "Unselect Checlist Item", onClick: e => this.setVal({selectedItem: false}), disabled: !state.selectedItem},
                    "line",
                    {caption: "Add Task", onClick: e => this.setVal({modal: <EditTaskModal key={0} onSave={task => this.routines.saveTask(task)} onClose={() => this.setVal({modal: null})} />}), disabled: state.selectedTask},
                    {caption: "Add Note", onClick: e => this.setVal({modal: <EditNoteModal key={0} onSave={note => this.routines.saveNote(note)} onClose={() => this.setVal({modal: null})} />}), disabled: !state.tasks.includes(state.selectedTask)},
                    {caption: "Add Checklist Item", onClick: e => this.setVal({modal: <EditItemModal key={0} onSave={item => this.routines.saveItem(item)} onClose={() => this.setVal({modal: null})} />}), disabled: !state.tasks.includes(state.selectedTask)},
                    "line",
                    {caption: "Edit Selected Task", onClick: e => this.setVal({modal: <EditTaskModal key={state.selectedTask.id} onSave={task => this.routines.saveTask(task)} onDelete={id => this.routines.deleteTask(id)} task={state.selectedTask} onClose={() => this.setVal({modal: null})} />}), disabled: !state.tasks.includes(state.selectedTask)},
                    {caption: "Edit Selected Note", onClick: e => this.setVal({modal: <EditNoteModal key={state.selectedNote.id} onSave={note => this.routines.saveNote(note)} onDelete={id => this.routines.deleteNote(id)} note={state.selectedNote} onClose={() => this.setVal({modal: null})} />}), disabled: !state.notes.includes(state.selectedNote)},
                    {caption: "Edit Selected Checklist Item", onClick: e => this.setVal({modal: <EditItemModal key={state.selectedItem.id} onSave={item => this.routines.saveItem(item)} onDelete={id => this.routines.deleteItem(id)} item={state.selectedItem} onClose={() => this.setVal({modal: null})} />}), disabled: !state.items.includes(state.selectedItem)},
                ]
            },
            {caption: "View", disabled: true},
            {caption: "Help", disabled: true}
        ]} />;



        tasks = state => <IElements.Tables.StdSpread 
            header={[
                ["name", "Name"],
                ["description", "Description"],
                ["countNotes", "Notes"],
                ["countItems", "Items"],
                ["progress", "Progress"],
            ]}
            useKey={r => r.id}
            body={this.state.tasks}
            onClick={this.taskClick}
            onContextMenu={this.taskContext}
        />;
        notes = state => <IElements.Tables.StdSpread 
            header={[
                ["note", "Note"],
            ]}
            useKey={r => r.id}
            selected={this.state.selectedNote}
            body={this.state.notes}
            onClick={this.noteClick}
            onContextMenu={this.noteContext}
        />;
        items = state => <IElements.Tables.StdSpread 
            header={[
                ["name", "Name"],
                ["complete", "Complete"],
            ]}
            useKey={r => r.id}
            selected={this.state.selectedItem}
            body={this.state.items}
            onClick={this.itemClick}
            onContextMenu={this.itemContext}
        />;

        taskPlaceHolder = () => <tr><td style={{height: "33%", padding: "0px", verticalAlign: "top"}}><IElements.Titles.PanelHeader>Task</IElements.Titles.PanelHeader><div style={{padding: "5px"}}>Select Task</div></td></tr>;
        notePlaceHolder = () => <tr><td style={{height: "33%", padding: "0px", verticalAlign: "top"}}><IElements.Titles.PanelHeader>Note</IElements.Titles.PanelHeader><div style={{padding: "5px"}}>Select Note</div></td></tr>;
        itemPlaceHolder = () => <tr><td style={{height: "33%", padding: "0px", verticalAlign: "top"}}><IElements.Titles.PanelHeader>Item</IElements.Titles.PanelHeader><div style={{padding: "5px"}}>Select Item</div></td></tr>;

        selectedTask = state => {
            if (!state.selectedTask) {return this.taskPlaceHolder();}
            else if (state.tasks.includes(state.selectedTask)) {return this.task(state);}
            else {return this.taskPlaceHolder();}
        };
        selectedNote = state => {
            if (!state.selectedNote) {return this.notePlaceHolder();}
            else if (state.notes.includes(state.selectedNote)) {return this.note(state);}
            else {return this.notePlaceHolder();}
        };
        selectedItem = state => {
            if (!state.selectedItem) {return this.itemPlaceHolder();}
            else if (state.items.includes(state.selectedItem)) {return this.item(state);}
            else {return this.itemPlaceHolder();}
        };

        taskClick = (event, record, field) => this.setVal({selectedTask: record}, () => this.routines.getTasks());
        noteClick = (event, record, field) => this.setVal({selectedNote: record});
        itemClick = (event, record, field) => this.setVal({selectedItem: record});

        taskContext = (event, record, field) => {
            event.preventDefault();
            this.setVal({contextMenu: <IElements.ContextMenu
                title="Task Options"
                menu={[
                    "Task",
                    `${record.name} ${record.description}`,
                    "line",
                    {caption: "Select Task", onClick: () => this.setVal({selectedTask: record}, () => this.routines.getTasks())},
                    {caption: "Edit Task", onClick: () => this.setVal({modal: <EditTaskModal key={record.id} onSave={task => this.routines.saveTask(task)} onDelete={id => this.routines.deleteTask(id)} task={record} onClose={() => this.setVal({modal: null})} />})},
                ]
            } event={event} onClose={() => this.setVal({contextMenu: null})} />})
        };
        noteContext = (event, record, field) => {
            event.preventDefault();
            this.setVal({contextMenu: <IElements.ContextMenu
                title="Note Options"
                menu={[
                    "Note",
                    `${record.note}`,
                    "line",
                    {caption: "Select Note", onClick: () => this.setVal({selectedNote: record})},
                    {caption: "Edit Note", onClick: () => this.setVal({modal: <EditNoteModal key={record.id} onSave={note => this.routines.saveNote(note)} onDelete={id => this.routines.deleteNote(id)} note={record} onClose={() => this.setVal({modal: null})} />})},
                ]
            } event={event} onClose={() => this.setVal({contextMenu: null})} />})
        };
        itemContext = (event, record, field) => {
            event.preventDefault();
            this.setVal({contextMenu: <IElements.ContextMenu
                title="Item Options"
                menu={[
                    "Item",
                    `${record.name}`,
                    "line",
                    {caption: "Select Item", onClick: () => this.setVal({selectedItem: record})},
                    {caption: "Edit Item", onClick: () => this.setVal({modal: <EditItemModal key={record.id} onSave={item => this.routines.saveItem(item)} onDelete={id => this.routines.deleteItem(id)} item={record} onClose={() => this.setVal({modal: null})} />})},
                    "line",
                    {caption: `Mark as ${ !record.complete ? "complete" : "incomplete" }`, onClick: () => this.setVal({contextMenu: null}, () => this.routines.saveItem({ ...record, complete: !record.complete}))},
                ]
            } event={event} onClose={() => this.setVal({contextMenu: null})} />})
        };
        project = state => <tr>
            <td style={{height: "50%", width: "100%", overflow: "auto", padding: "0px", verticalAlign: "top"}}><IElements.Titles.PanelHeader>Project</IElements.Titles.PanelHeader><IElements.Tables.ListView
                style={{width: "100%"}}
                body={[
                    ["Name", this.props.params.project.name],
                    ["Description", this.props.params.project.description],
                ]}
            /></td>
        </tr>;
        task = state => <tr>
            <td style={{height: "33%", width: "100%", overflow: "auto", padding: "0px", verticalAlign: "top"}}><IElements.Titles.PanelHeader>Task</IElements.Titles.PanelHeader><IElements.Tables.ListView
                style={{width: "100%"}}
                body={[
                    ["Name", state.selectedTask.name],
                    ["Description", state.selectedTask.description],
                ]}
            /></td>
        </tr>;
        note = state => <tr>
            <td style={{height: "33%", width: "100%", overflow: "auto", padding: "0px", verticalAlign: "top"}}><IElements.Titles.PanelHeader>Note</IElements.Titles.PanelHeader><IElements.Tables.ListView
                style={{width: "100%"}}
                body={[
                    ["Note", state.selectedNote.note],
                ]}
            /></td>
        </tr>;
        item = state => <tr>
            <td style={{height: "33%", width: "100%", overflow: "auto", padding: "0px", verticalAlign: "top"}}><IElements.Titles.PanelHeader>Checklist Item</IElements.Titles.PanelHeader><IElements.Tables.ListView
                style={{width: "100%"}}
                body={[
                    ["Name", state.selectedItem.name],
                    ["Complete", state.selectedItem.complete],
                ]}
            /></td>
        </tr>;

        taskView = state => <>
            <tr><td><button onClick={() => this.setVal({selectedTask: false, selectedNote: false, selectedItem: false}, () => this.routines.getTasks())} >Back</button></td></tr>
            {this.selectedTask(state)}
            <tr><td style={{padding: "0px"}}><IElements.Titles.PanelHeader>Notes</IElements.Titles.PanelHeader></td></tr>
            <tr><td style={{height: "33%", overflow: "auto", padding: "0px", verticalAlign: "top"}}>{this.notes(state)}</td></tr>
            <tr><td style={{padding: "0px"}}><IElements.Titles.PanelHeader>Checklist</IElements.Titles.PanelHeader></td></tr>
            <tr><td style={{height: "33%", overflow: "auto", padding: "0px", verticalAlign: "top"}}>{this.items(state)}</td></tr>
        </>;
        tasksView = state => <>
            {this.project(state)}
            <tr><td style={{padding: "0px"}}><IElements.Titles.PanelHeader>Tasks</IElements.Titles.PanelHeader></td></tr>
            <tr><td style={{height: "50%", overflow: "auto", padding: "0px", verticalAlign: "top"}}>{this.tasks(state)}</td></tr>
        </>;

        mobile = state => <div>Not Available</div>;
        desktop = state => <><table style={styles().table}><tbody>
            <tr><td style={{padding: "0px"}}>{this.menuBar(state)}</td></tr>
            { state.selectedTask ? this.taskView(state) : this.tasksView(state) }
        </tbody></table>{state.modal}{state.contextMenu}</>;
    }
};
export default Exp;