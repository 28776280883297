import React from "react";
import styles from "./login.module.css";
import CoreTools from "../services/CoreTools";
import BaseApi from "../services/BaseApi";

export default class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            panel: "login",
            firstname: null,
            lastname: null,
            email: null
        };
        this.refEmail = false;
        this.refPassword = false;
        this.refNewPassword = false;
        this.refConfirmPassword = false;
        this.requestId = CoreTools.getQueryParam("resetId"); 
    }

    setVal = CoreTools.stateHandler(this);

    componentDidMount() {
        this.setVal("mount");
        if (this.requestId) {
            BaseApi.login.resetInfo(this.requestId)(data => {
                if (data.success) {
                    this.setVal({
                        firstname: data.firstname,
                        lastname: data.lastname,
                        email: data.email,
                        panel: "new"
                    });
                }
            });
        }
    }

    errorTimer = null;
    setErrorMessage = msg => {
        this.setVal({
            errorMessage: msg
        });
        clearTimeout(this.errorTimer);
        this.errorTimer = setTimeout(() => {
            this.setVal({
                errorMessage: null
            }); 
        }, 3000);
    };

    componentWillUnmount() {
        this.setVal("unmount");
        clearTimeout(this.errorTimer);
    }

    submitLogin = event => {
        event.preventDefault();
        if (this.state.panel === "login") {
            if (!this.refEmail.value) {
                this.setErrorMessage("Please provide a valid E-mail Address");
            } else if (!this.refPassword.value || this.refPassword.value.length < 8) {
                this.setErrorMessage("Please provide a password of atleast 8 characters");
            } else {
                this.setErrorMessage("Processing...");
                BaseApi.login.login(this.refEmail.value, this.refPassword.value)(response => {
                    if (!response.success) {
                        this.setErrorMessage("Please verify your E-mail Address & Password");
                    }
                });
            }
        } else if (this.state.panel === "reset") {
            if (!this.refEmail.value) {
                this.setErrorMessage("Please provide a valid E-mail Address");
            } else {
                this.setErrorMessage("Processing...");
                BaseApi.login.resetPassword(this.refEmail.value)(response => {
                    if (response.success) {
                        this.setErrorMessage("Check your E-mail for next steps...");
                    } else {
                        this.setErrorMessage(response.error ? response.error.message : "Please verify your E-mail Address");
                    }
                });
            }
        } else if (this.state.panel === "new") {
            if (!this.refNewPassword.value || this.refNewPassword.value.length < 8) {
                this.setErrorMessage("Please provide a password of atleast 8 characters");
            } else if (this.refNewPassword.value !== this.refConfirmPassword.value) {
                this.setErrorMessage("Confirmation Password does not Match Password");
            } else {
                this.setErrorMessage("Processing...");
                BaseApi.login.savePassword(this.requestId, this.refNewPassword.value)(data => {
                    if (data.success) {
                        this.setErrorMessage("New Password Saved");
                        setTimeout(() => {
                            this.setVal({panel: "login"});
                        }, 3000);
                    } else {
                        this.setErrorMessage("Failed to set new Password");
                    }
                });
            }
        }
    };

    render() {
        return (
            <div>            
                <div className={styles.header}>ConsumerGenius - Customer Relations Portal</div>
                <form onSubmit={this.submitLogin}>
                    <table className={styles.loginTable}>
                        <tbody>
                            <tr><td style={{textAlign: "center"}} colSpan="2"><img src="/images/logo-consumer-genius-300x29.png" className={styles.objectCenter} style={{width: "240px", height: "auto"}} alt="" /></td></tr>
                            <tr><td className={[styles.textDanger, styles.envFull, styles.textCenter].join(" ")} colSpan="2">{ this.state.errorMessage }</td></tr>
                            {(() => {
                                if (this.state.panel === "login") {
                                    return (
                                        <>
                                            <tr><td style={{textAlign: "right", width: "100%"}}>E-mail Address:</td><td><input style={{width: "200px"}} ref={r => this.refEmail = r} type="text" placeholder="E-mail Address" /></td></tr>
                                            <tr><td style={{textAlign: "right", width: "100%"}}>Password:</td><td><input style={{width: "200px"}} ref={r => this.refPassword = r} type="password" placeholder="Password" /></td></tr>
                                            <tr><td colSpan="2" style={{textAlign: "right"}}><button type="submit">Login</button></td></tr>
                                            <tr><td colSpan="2" style={{textAlign: "center"}}><span onClick={() => {this.setVal({panel: "reset"});}} className={styles.link}>Reset My Password</span></td></tr>
                                        </>
                                    );
                                } else if (this.state.panel === "reset") {
                                    return (
                                        <>
                                            <tr><td style={{textAlign: "right", width: "100%"}}>E-mail Address:</td><td><input style={{width: "200px"}} ref={r => this.refEmail = r} type="text" placeholder="E-mail Address" /></td></tr>
                                            <tr><td colSpan="2" style={{textAlign: "right"}}><button type="submit">Reset Password</button></td></tr>
                                            <tr><td colSpan="2" style={{textAlign: "center"}}><span onClick={() => {this.setVal({panel: "login"});}} className={styles.link}>Return To Login</span></td></tr>
                                        </>
                                    );
                                } else if (this.state.panel === "new") {
                                    return (
                                        <>
                                            <tr><td style={{textAlign: "right", width: "100%", height: "32px"}}>Account Holder:</td><td>{this.state.firstname} {this.state.lastname}</td></tr>
                                            <tr><td style={{textAlign: "right", width: "100%", height: "32px"}}>E-mail Address:</td><td>{this.state.email}</td></tr>
                                            <tr><td style={{textAlign: "right", width: "100%"}}>New Password:</td><td><input style={{width: "200px"}} ref={r => this.refNewPassword = r} type="password" placeholder="New Password" /></td></tr>
                                            <tr><td style={{textAlign: "right", width: "100%"}}>Confirm Password:</td><td><input style={{width: "200px"}} ref={r => this.refConfirmPassword = r} type="password" placeholder="Confirm Password" /></td></tr>
                                            <tr><td colSpan="2" style={{textAlign: "right"}}><button type="submit">Save Password</button></td></tr>
                                            <tr><td colSpan="2" style={{textAlign: "center"}}><span onClick={() => {this.setVal({panel: "login"});}} className={styles.link}>Return To Login</span></td></tr>
                                        </>
                                    );
                                }
                            })()}
                        </tbody>
                    </table>
                </form>
                <div className={styles.footer}>Powered By: ConsumerGenius</div>
            </div>
        );
    }
}