import React from "react";
import Icon from "../../../directives/Icon";
import BaseApi from "../../../services/BaseApi";
import IElements from "../../../directives/IElements";
import CoreTools from "../../../services/CoreTools";
import Panels from "../../../shell/windows/Panels";

const styles = () => ({
    table: {
        width: "100%",
        height: "100%",
        borderCollapse: "collapse"
    },
    tabs: {
        minHeight: "32px",
        fontSize: "14px",
        cursor: "pointer",
        verticalAlign: "bottom",
        paddingLeft: "10px",
        paddingRight: "10px",
        border: "2px outset #e8e8e8",
        borderBottomColor: "transparent",
        background: "#cccccc",
        borderRadius: "2px",
    },
    activetab: {
        minHeight: "34px",
        fontSize: "14px",
        cursor: "pointer",
        verticalAlign: "bottom",
        paddingLeft: "10px",
        paddingRight: "10px",
        background: "#cccccc",
        border: "2px outset #e8e8e8",
        borderBottomColor: "transparent",
        marginBottom: "-2px",
        paddingBottom: "3px",
    },
});


const Exp = {
    singleInstance: true,
    menuGroup: "Contacts",
    name: "Leadspedia",
    title: "Leadspedia",
    description: "",
    menuIcon: <Icon name="dollar" size={18} />,
    taskbarIcon: <Icon name="dollar" size={22} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class Users extends Panels.Window {
        initState = {
            tab: "Affiliates",
            affiliates: [],
            campaigns: [],
            contracts: [],
            leads: [],
            conversions: [],
        };

        api = {
            getAffiliates: () => cb => BaseApi.push("api", "leadspedia", "getAffiliates", {}, cb),
            getCampaigns: ({affiliateID}) => cb => BaseApi.push("api", "leadspedia", "getCampaigns", {affiliateID}, cb),
            getContracts: ({accountManagerID}) => cb => BaseApi.push("api", "leadspedia", "getContracts", {accountManagerID}, cb),
            getLeads: () => cb => BaseApi.push("api", "leadspedia", "getLeads", {fromDate:"2022-10-11"}, cb),
            getConversions: () => cb => BaseApi.push("api", "leadspedia", "getConversions", {fromDate:"2022-10-11"}, cb),
        };

        routines = {
            load: () => {
                this.api.getAffiliates()(results => {
                    console.log("affiliates", results);
                    this.setVal( results.success ? {affiliates: results.affiliates.response.data} : {} );
                });
            }
        };

        onMount = () => {
            this.routines.load();
        };
        onUnMount = () => {};

        menuBar = state => <IElements.MenuBar.HeaderBar menu={[
            {caption: "File", disabled: true},
            {caption: "View", disabled: true},
            {caption: "Help", disabled: true}
        ]} />;

        affiliates = state => <IElements.Tables.StdSpread 
            header={[
                ["affiliateName", "Name"]
            ]}
            useKey={r => r.affiliateID}
            body={state.affiliates}
            onClick={this.affiliateClick}
            onContextMenu={this.affiliateContext}
        />;
        affiliateClick = (event, record, field) => {
            this.setVal({affiliate: record});
            this.api.getCampaigns(record)(results => {
                console.log("campaigns", results);
                this.setVal( results.success ? {campaigns: results.campaigns.response.data} : {} );
            });
            this.api.getContracts(record)(results => {
                console.log("contracts", results);
                this.setVal( results.success ? {contracts: results.contracts.response.data} : {} );
            });
        };
        affiliateContext = (event, record, field) => {};

        campaigns = state => <IElements.Tables.StdSpread 
            header={[
                ["campaignName", "Name"]]}
            useKey={r => r.campaignID}
            body={state.campaigns}
        />;

        contracts = state => <IElements.Tables.StdSpread 
            header={[]}
            useKey={r => r.contractID}
            body={state.contracts}
        />;

        leads = state => <IElements.Tables.StdSpread 
            header={[]}
            useKey={r => r.leadID}
            body={state.leads}
        />;

        conversions = state => <IElements.Tables.StdSpread 
            header={[]}
            useKey={r => r.conversionID}
            body={state.conversions}
        />;

        tabs = state => <div style={{backgroundColor: "#cccccc"}}>
            <div>
                <button style={this.state.tab === "Affiliates"?styles().activetab:styles().tabs} onClick={() => this.setVal({tab: "Affiliates"})}>Affiliates {this.state.affiliates.length}</button>
                <button style={this.state.tab === "Campaigns"?styles().activetab:styles().tabs} disabled={!this.state.affiliate} onClick={() => this.setVal({tab: "Campaigns"})}>Campaigns {this.state.campaigns.length}</button>
                <button style={this.state.tab === "Contracts"?styles().activetab:styles().tabs} disabled={!this.state.affiliate}  onClick={() => this.setVal({tab: "Contracts"})}>Contracts {this.state.contracts.length}</button>
                <button style={this.state.tab === "Leads"?styles().activetab:styles().tabs} disabled={!this.state.campaign} onClick={() => this.setVal({tab: "Leads"})}>Leads {this.state.leads.length}</button>
                <button style={this.state.tab === "Conversions"?styles().activetab:styles().tabs} disabled={!this.state.campaign} onClick={() => this.setVal({tab: "Conversions"})}>Conversions {this.state.conversions.length}</button>
            </div>
        </div>;

        scrollBoxResize = () => null;
        onResize = (width, height) => {
            this.scrollBoxResize();
        };

        affiliate = state => {
            if (!state.affiliate) return <div style={{padding: "5px"}}>Select Affiliate</div>;
            return <IElements.Tables.ListView
                style={{width: "100%"}}
                body={[
                    ["Name", state.affiliate.affiliateName],
                ]}
            />;
        };

        mobile = state => <div>Not Available</div>;
        desktop = state => <table style={styles().table}>
            <tbody>
                <tr><td colSpan={2} style={{padding: "0px"}}>{this.menuBar(state)}</td></tr>
                <tr><td style={{padding: "0px"}}>{this.tabs()}</td></tr>
                <tr><td colSpan={2} style={{padding: "0px"}}><IElements.Titles.PanelHeader>{state.tab}</IElements.Titles.PanelHeader></td></tr>
                <tr><td style={{padding: "0px", height: "60%", width: "100%",verticalAlign:"top"}}><IElements.CompElems.ScrollBox onResize={ctrls => this.scrollBoxResize = ctrls}>{CoreTools.switch(
                    [state.tab === "Affiliates",this.affiliates(state)],
                    [state.tab === "Campaigns",this.campaigns(state)],
                    [state.tab === "Contracts",this.contracts(state)],
                    [state.tab === "Leads",this.leads(state)],
                    [state.tab === "Conversions",this.conversions(state)],
                )}</IElements.CompElems.ScrollBox></td></tr>
                <tr><td colSpan={2} style={{padding: "0px"}}><IElements.Titles.PanelHeader>Selected Affiliate</IElements.Titles.PanelHeader></td></tr>
                <tr><td style={{padding: "0px", height: "30%", width: "100%",verticalAlign:"top"}}>{this.affiliate(state)}</td></tr>
            </tbody>
        </table>;
    }
};
export default Exp;