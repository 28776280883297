import React from "react";
import Icon from "../../../directives/Icon";
import SocketConnection from "../../../services/SocketConnection";
import Panels from "../../../shell/windows/Panels";

const Exp = {
    singleInstance: true,
    menuGroup: "Preferences",
    name: "Settings",
    title: "My Settings",
    description: "My Settings",
    menuIcon: <Icon name="settings" size={18} />,
    taskbarIcon: <Icon name="settings" size={22} margin={0} />,
    startFullscreen: true,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    startWidth: 500,
    startHeight: 300,
    minWidth: 500,
    minHeight: 300,
    Component: class Settings extends Panels.Window {
        
        onMount = () => {
            this.wHolder(SocketConnection("wss://api.consumergenius.com/test", sock => {
                sock.autoReconnect(500);
                sock.onReceive(data => {
                    console.log("Sck onReceive:", data);
                    console.log("Got Data", data);
                });
                sock.onClose(event => {
                    console.log("Sck onClose:", event);
                });
                sock.onError(event => {
                    console.log("Sck onError:", event);
                });
                sock.onConnect(event => {
                    console.log("Sck onConnect:", event);
                    sock.send({msg: "It's all good!"});
                });
                sock.connect();
            }));
        };
        onUnmount = () => {};
        mobile = state => <div>Not Available</div>;
        desktop = state => <div>{this.props.wdwId}</div>;
    }
};
export default Exp;