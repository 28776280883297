import React from "react";

const LoadingScreen = props => {
    return <><img src="/images/loading_rs2.gif" style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        pointerEvents: "none"
    }} alt="Loading..." /><img src="/favicon.ico" style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        marginTop: "-10px",
        pointerEvents: "none"
    }} alt="Loading..." /></>;
};
export default LoadingScreen;