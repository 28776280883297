import React from "react";
import Icon from "./Icon";
import CoreTools from "../services/CoreTools";

const funcWrap = func => (...params) => typeof(func) === "function" ? func(...params) : undefined;

const SwitchBox = props => {
    const [value, setValue] = React.useState(props.value ? true : false);
    React.useEffect(() => {
        funcWrap(props.onChange)(value);
    }, [value]);
    let style = CoreTools.fuseObj({
        display: "inline-table",
        height: `${props.size || 32}px`,
        verticalAlign: "middle",
        cursor: "pointer",
        borderColor: props.borderColor || "#CCCCCC"
    }, props.style);
    return (
        <span
            style={style}
            title={props.title}
            onClick={() => setValue(!value)}
        >{
            props.leftLabel && <span style={{display: "table-cell", paddingRight: "4px", height: `${(props.size || 32) - 2}px`, verticalAlign: "middle"}}>{props.leftLabel}</span>
        }<span
            style={{display: "flex"}}
        ><span 
            style={{
                display: "inline-block",
                minWidth: `${(props.size || 32) * 2 - 2}px`,
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "4px",
                borderColor: props.borderColor || "inherit",
                // backgroundColor: this.state.over ? "#CCCCCC" : "transparent",
                padding: "0px",
                color: "#FFFFFF"
            }}
        ><span
            style={{
                display: "block",
                width: `${(props.size || 32) - 2}px`,
                height: `${(props.size || 32) - 2}px`,
                textAlign: "center",
                borderStyle: "solid",
                borderColor: "inherit",
                borderWidth: value ? "0px 0px 0px 1px" : "0px 1px 0px 0px",
                borderRadius: "4px",
                marginLeft: value ? "auto" : "0px",
                backgroundColor: value ? "#00CC00" : "#FF0000",
                padding: "0px",
                overflow: "hidden",
                color: "inherit"
            }}
        ><Icon 
            name={value ? "check" : "cancel"} 
            size={(props.size || 32) - 8} 
            margin="0" 
            style={{marginTop: "3px", verticalAlign: "top"}}
        /></span></span></span>{props.rightLabel && <span 
            style={{display: "table-cell", paddingLeft: "4px", height: `${(props.size || 32) - 2}px`, verticalAlign: "middle"}}
        >{props.rightLabel}</span>}</span>
    );
}
export default SwitchBox;