import Icon from "../../../directives/Icon";
import React from "react";
import IElements from "../../../directives/IElements";
import Panels from "../../../shell/windows/Panels";

const styles = () => ({
    table: {
        width: "100%",
        height: "100%",
        borderCollapse: "collapse"
    }
});

const Exp = {
    singleInstance: true,
    menuGroup: "Administration",
    name: "DomainWatchdog",
    title: "Domain Watchdog",
    description: "Monitor Domains For Downtime",
    menuIcon: <Icon name="dog" size={18} />,
    taskbarIcon: <Icon name="dog" size={22} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class DomainWatchdog extends Panels.Window {
        initState = {
            recipients: [],
            domains: [],
            selected: false,
            modal: null,
            contextMenu: null
        };
        
        menuBar = props => <IElements.MenuBar.HeaderBar menu={[
            {
                caption: "File",
                menu: [
                    {caption: "Refresh", onClick: e => this.routines.getUsers()},
                    "line",
                    {caption: "Exit", onClick: e => this.close()}
                ]
            },
            {
                caption: "Edit",
                menu: [
                    {caption: "Add Recipient", onClick: e => {}, disabled: false},
                    {caption: "Add Domain", onClick: e => {}, disabled: false}
                ]
            },
            {caption: "View", disabled: true},
            {caption: "Help", disabled: true}
        ]} />;

        recipientClick = (event, record, field) => {};
        recipientContext = (event, record, field) => {};
        recipients = props => <IElements.Tables.StdSpread 
            header={[
                ["name", "Name"],
                ["phone", "Phone"],
                ["enabled", "Enabled"]
            ]}
            useKey={r => r.id}
            body={this.state.recipients}
            onClick={this.recipientClick}
            onContextMenu={this.recipientContext}
        />;

        desktop = state => <table style={styles().table}>
            <tbody>
                <tr><td style={{padding: "0px"}}>{this.menuBar()}</td></tr>
                <tr><td style={{padding: "0px"}}><IElements.Titles.PanelHeader>Recipients</IElements.Titles.PanelHeader></td></tr>
                <tr><td style={{height: "30%", overflow: "auto", padding: "0px", verticalAlign: "top"}}>{this.recipients()}</td></tr>
                <tr><td style={{padding: "0px"}}><IElements.Titles.PanelHeader>Domains</IElements.Titles.PanelHeader></td></tr>
                <tr><td style={{height: "30%", overflow: "auto", padding: "0px", verticalAlign: "top"}}></td></tr>
                <tr><td style={{padding: "0px"}}><IElements.Titles.PanelHeader>Domain Logs</IElements.Titles.PanelHeader></td></tr>
                <tr><td style={{height: "100%", overflow: "auto", padding: "0px", verticalAlign: "top"}}></td></tr>
            </tbody>
        </table>;
    }
};
export default Exp;