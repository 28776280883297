import React from "react";
import AppManager from "../../services/AppManager";
import CoreTools from "../../services/CoreTools";

const AppSpace = props => {
    const [size, setSize] = React.useState({width: window.innerWidth, height: window.innerHeight - 44});
    const [apps, setApps] = React.useState(AppManager.getOpenApps());

    React.useEffect(() => {
        const wHolder = [
            CoreTools.on("screenSize", (w, h) => setSize({width: w, height: h - 44})),
            CoreTools.on("appsUpdated", nApps => {
                console.log("Updating Apps AppSpace");
                setApps([...nApps]);
            })
        ];
        return () => wHolder.forEach(k => k());
    });

    return <div
        style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            minWidth: `${size.width}px`,
            minHeight: `${size.height}px`,
            overflow: "auto",
            backgroundColor: "rgba(0, 0, 0, 0.2)"
        }}
    >{apps.map(app => <span key={app.id} style={app.state.visible ? {} : {pointerEvents: "none", opacity: 0}}>{app.elem}</span>)}</div>;
};
export default AppSpace;