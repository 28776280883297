import React from "react";
import Icon from "../../../directives/Icon";
import IElements from "../../../directives/IElements";
import CoreTools from "../../../services/CoreTools";
import Panels from "../../../shell/windows/Panels";

const styles = () => ({
    table: {
        width: "100%",
        height: "100%",
        borderCollapse: "collapse"
    },
    full: {
        width: "100%",
        height: "100%"
    }
});


const Exp = {
    singleInstance: true,
    menuGroup: "Telecoms",
    name: "AgentManager",
    title: "Agent Manager",
    description: "Agent Manager",
    menuIcon: <Icon name="headset" size={18} />,
    taskbarIcon: <Icon name="headset" size={22} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    allowMobile: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class AgentManager extends Panels.Window {
        initState = {
            users: [],
            groups: [],
            groupMembers: [],
            selected: false,
            modal: null,
            contextMenu: null
        };
        
        api = {

        };
        routines = {
            getAgents: () => {}
        };
        onMount = () => {};
        onUnmount = () => {};
        menuBar = state => <IElements.MenuBar.HeaderBar menu={[
            {
                caption: "File",
                menu: [
                    {caption: "Refresh", onClick: e => this.routines.getAgents()},
                    "line",
                    {caption: "Exit", onClick: e => this.close()}
                ]
            },
            {
                caption: "Edit",
                menu: [
                    {caption: "Add Agent", onClick: e => {}, disabled: true}
                ]
            },
            {caption: "View", disabled: true},
            {caption: "Help", disabled: true}
        ]} />;
        agents = () => <IElements.Tables.StdSpread 
            header={[
                ["id", "ID"],
                ["firstname", "Firstname"],
                ["lastname", "Lastname"],
                ["email", "E-mail Address"],
                ["enabled", "Enabled"],
                ["lastSignIn", "Last Signin"]
            ]}
            useKey={r => r.id}
            body={[]}
            // onClick={this.userClick}
            // onContextMenu={this.userContext}
        />;
        mobile = state => {};
        desktop = state => <table style={styles().table}>
            <tbody>
                <tr><td colSpan={2} style={{padding: "0px"}}>{this.menuBar(state)}</td></tr>
                <tr><td colSpan={2} style={{padding: "0px"}}><IElements.Titles.PanelHeader>Agents</IElements.Titles.PanelHeader></td></tr>
                <tr><td colSpan={2} style={CoreTools.fuseObj(styles().full, {overflow: "auto", padding: "0px", verticalAlign: "top"})}>{this.agents()}</td></tr>
            </tbody>
        </table>;
    }
};
export default Exp;