const FuncCompTools = {
    stateHandler: (source, setter) => {
        let timeout = null;
        let cbs = [];
        return (valueSet, cb) => {
            clearTimeout(timeout);
            const values = source();
            Object.keys(valueSet).forEach(key => values[key] = valueSet[key]);
            if (typeof(cb) === "function") {cbs.push(cb);}
            timeout = setTimeout(() => {
                setter({...values});
                setTimeout(() => {while (cbs.length) {cbs.shift()();}});
            });
        };
    },
    refHandler: () => {
        let refs = {};
        return {
            set: name => ref => {if (ref) {refs[name] = ref;}},
            get: name => refs[name]
        };
    }
};
export default FuncCompTools;