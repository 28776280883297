import React from "react";
import Icon from "../../directives/Icon";
import AppManager from "../../services/AppManager";
import CoreTools from "../../services/CoreTools";
import MainMenu from "../mainmenu/MainMenu";

const styles = () => ({
    baseline: {
        position: "fixed",
        display: "block",
        bottom: "0px",
        left: "0px",
        height: "44px",
        width: "100%",
        backgroundColor: "var(--color-taskbar-background)",
        backgroundImage: "linear-gradient(var(--color-taskbar-shade), var(--color-taskbar-background), var(--color-taskbar-background), var(--color-taskbar-shade))",
        lineHeight: "44px",
        zIndex: ++CoreTools.state.zIndex
    },
    status: {
        position: "absolute",
        right: "0px",
        bottom: "0px",
        borderStyle: "inset",
        borderWidth: "2px",
        borderColor: "var(--color-taskbar-shade)",
        height: "26px",
        minWidth: "140px",
        margin: "4px",
        color: "#FFFFFF",
        backgroundColor: "var(--color-taskbar-background)",
        textAlign: "right",
        padding: "3px",
        verticalAlign: "middle",
        lineHeight: "26px",
        fontFamily: "federationregular",
        fontSize: "14px",
        pointerEvents: "none"
    },
    taskArea: {
        position: "fixed",
        left: "48px",
        bottom: "0px",
        height: "42px"
    },
    tooltip: {
        position: "absolute",
        bottom: "40px",
        marginLeft: "-7px",
        backgroundColor: "var(--color-taskbar-background)",
        whiteSpace: "nowrap",
        padding: "8px 14px",
        borderRadius: "4px",
        pointerEvents:"none",
        transition:"all .2s",
        opacity:0
    },
    tooltipHover: {
        bottom: "50px",
        opacity:1
    },
});

const Taskbar = props => {
    const [cTime, sTime] = React.useState("00:00 AM");
    const [showMobile, setMobile] = React.useState(CoreTools.state.isMobile);
    const [zIndex, setIndex] = React.useState(++CoreTools.state.zIndex);
    const [apps, setApps] = React.useState(AppManager.getOpenApps());
    const [top, setTop] = React.useState(CoreTools.last(apps.filter(a => a.state.visible)));
    const [tooltip, setTooltip] = React.useState(null);

    const interval = () => {
        const date = new Date();
        sTime(
            (date.getHours() > 12 ? date.getHours() - 12 : (date.getHours() ? date.getHours() : "12")) + 
            ":" + 
            (date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes()) + " " + (date.getHours() >= 12 ? "PM" : "AM")
        );
    };
    
    React.useEffect(() => {
        const intervalTracker = setInterval(interval, 1000);
        const wHolder = [
            CoreTools.on("isMobile", isMobile => setMobile(isMobile)),
            CoreTools.on("elevateTaskbar", () => setIndex(++CoreTools.state.zIndex)),
            CoreTools.on("appsUpdated", nApps => {
                const onApps = CoreTools.orderBy(nApps, "id");
                setApps([...onApps]);
                setTop(CoreTools.last(nApps.filter(a => a.state.visible))());
            })
        ];
        return () => {
            clearInterval(intervalTracker);
            wHolder.forEach(k => k());
        };
    }, []);

    const setRef = ref => {if (ref) {CoreTools.state.taskbar = ref;}};

    const mobile = () => <div
        style={CoreTools.fuseObj(styles().baseline, {zIndex: zIndex})}
        ref={setRef}
    >
        <MainMenu />
    </div>;

    const desktop = () => <div
        style={CoreTools.fuseObj(styles().baseline, {zIndex: zIndex})}
        ref={setRef}
    >
        <MainMenu />
        <div style={styles().taskArea}>{
            apps.map(app => <button 
                key={app.id} 
                className={app === top ? "task-button-active" : "task-button"} 
                onClick={() => AppManager.taskbarClick(app.id)}
                onMouseEnter={event => setTooltip(app)}
                onMouseLeave={event => setTooltip(null)}
            >
                <div style={CoreTools.fuseObj(styles().tooltip, tooltip === app ? styles().tooltipHover : {})}>{app.params.title}</div>
                {app.params.taskbarIcon}
            </button>)
        }</div>
        <div style={styles().status}><Icon name="chat" /><Icon name="phone" color="#CC0000" /><Icon name="wired" /><div style={{minWidth: "95px", display: "inline-block"}}>{cTime}</div></div>
    </div>;
    
    return showMobile ? mobile() : desktop();
};

export default Taskbar;