//import AppManager from "../../../services/AppManager";
import React from "react";
import Icon from "../../../directives/Icon";
import BaseApi from "../../../services/BaseApi";
import IElements from "../../../directives/IElements";
import Modals from "../../../shell/windows/Modal";
import Panels from "../../../shell/windows/Panels";
//import CoreTools from "../../../services/CoreTools";

const styles = ( row_key ) => ({
    table: {
        width: "100%",
        height: "100%",
        borderCollapse: "collapse"
    },
});

class EditDocumentModal extends React.Component {
    constructor(props) {
        super(props);
        Modals.Standard(this, {
            id: props.document?.id || null,
            name: props.document?.name || "",
            description: props.document?.description ||"",
        }, {
            menuIcon: null, 
            title: "Edit Document",
            width: "auto",
            height: "auto",
            zIndex: 1,
            onClose: props.onClose
        }, () => {});
    }
    save = () => this.props.onSave({
        id: this.state.id,
        name: this.state.name,
        description: this.state.description,
    });
    edit = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InputMedium caption="Name" value={this.state.name} onChange={nv => this.setVal({name: nv})} /></div>
        <div><IElements.Form.InputMedium caption="Description" value={this.state.description} onChange={nv => this.setVal({description: nv})} /></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Delete", onClick: () => this.setVal({delete: true}), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.props.onClose()},
            {content: "Save", onClick: this.save, disabled: () => !this.state.name}
        ]} /></div>
    </div>;
    delete = () => <div style={{padding: "10px"}}>
        <div><IElements.Form.InfoLarge title={`Delete the Document '${this.state.name}'?`} /></div>
        <div><IElements.Form.ButtonGroupLarge buttons={[
            {content: "Confirm Delete", onClick: () => this.props.onDelete(this.state.id), hide: !this.state.id},
            {content: "Cancel", onClick: () => this.setVal({delete: false})}
        ]} /></div>
    </div>;
    desktop = () => this.state.delete ? this.delete() : this.edit();
}

const Exp = {
    singleInstance: false,
    name: "Document",
    title: "Document",
    description: "Ongoing View and Manage Ongoing Project Tasks",
    menuIcon: <Icon name="project" size={18} />,
    taskbarIcon: <Icon name="project" size={22} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class Document extends Panels.Window {
        initState = {
            document: this.props.params.document || null,
            attachments: [],
            selectedAttachment: false,
            modal: null,
            contextMenu: null
        };
        api = {
            getDocument: callback => BaseApi.push("api", "documents", "getDocument", {id: this.props.params.document.id}, callback),
            deleteDocument: (id, callback) => BaseApi.push("api", "documents", "deleteDocument", {id: id}, callback),
            saveDocument: (document, callback) => BaseApi.push("api", "documents", "saveDocument", {document: document}, callback),
        };
        routines = {
            getDocument: () => this.api.getDocument(records => {this.setVal({document: records.documents[0] ?? [], attachments: records.attachments ?? [],});}),
            saveDocument: document => this.api.saveDocument(document, results => {if (results.success) {this.setVal({modal: null}); this.routines.getDocument();}}),
            deleteDocument: id => this.api.deleteDocument(id, results => {if (results.success) {this.setVal({modal: null}); this.close();}}),
        };

        onMount = () => {
            this.routines.getDocument();
        };
        onUnMount = () => {};

        menuBar = state => <IElements.MenuBar.HeaderBar menu={[
            {
                caption: "File",
                menu: [
                    {caption: "Refresh", onClick: e => this.routines.getDocument()},
                    "line",
                    {caption: "Exit", onClick: e => this.close()}
                ]
            },
            {
                caption: "Edit",
                menu: [
                    {caption: "Edit Document", onClick: e => this.setVal({modal: <EditDocumentModal key={state.document.id} onSave={document => this.routines.saveDocument(document)} onDelete={id => this.routines.deleteDocument(id)} document={state.document} onClose={() => this.setVal({modal: null})} />})},
                    "line",
                    {caption: "Unselect Attachment", onClick: e => this.setVal({selectedAttachment: false}, () => this.routines.getDocument()), disabled: !state.selectedAttachment},
                    {caption: "Add Attachment", onClick: e => this.setVal({modal: <EditDocumentModal key={0} onSave={task => this.routines.saveTask(task)} onClose={() => this.setVal({modal: null})} />})},
                    {caption: "Edit Selected Attachment", onClick: e => this.setVal({modal: <EditDocumentModal key={state.selectedAttachment.id} onSave={task => this.routines.saveTask(task)} onDelete={id => this.routines.deleteDocument(id)} task={state.selectedAttachment} onClose={() => this.setVal({modal: null})} />}), disabled: !state.attachments.includes(state.selectedAttachment)},
                ]
            },
            {caption: "View", disabled: true},
            {caption: "Help", disabled: true}
        ]} />;



        document = state => <IElements.Tables.ListView
            style={{width: "100%"}}
            body={[
                ["Name", state.document.name],
                ["Description", state.document.description],
            ]}
        />;
        content = state => <>
            <textarea
                value={state.document.content || ""} 
                onChange={event => this.setVal({document: {...state.document, content: event.target.value}})} 
                onKeyDown={e => {
                    if (e.key === "Tab") {
                        e.preventDefault();
                        var start = e.target.selectionStart;
                        var end = e.target.selectionEnd;
                        e.target.value = e.target.value.substring(0, start) + "\t" + e.target.value.substring(end);
                        e.target.selectionStart = e.target.selectionEnd = start + 1;
                    }
                }}
            />
            <button onClick={() => this.routines.saveDocument(state.document)} >Save</button>
        </>;
        attachments = state => <IElements.Tables.StdSpread 
            header={[
                ["name", "Name"],
                ["description", "Description"],
            ]}
            useKey={r => r.id}
            body={this.state.attachments}
            onClick={this.attachmentClick}
            onContextMenu={this.attachmentContext}
        />;

        attachmentClick = (event, record, field) => this.setVal({selectedAttachment: record}, () => this.routines.getDocument());

        attachmentContext = (event, record, field) => {
            event.preventDefault();
            this.setVal({contextMenu: <IElements.ContextMenu
                title="Task Options"
                menu={[
                    "Task",
                    `${record.name} ${record.description}`,
                    "line",
                    {caption: "Select Task", onClick: () => this.setVal({selectedAttachment: record}, () => this.routines.getDocument())},
                    {caption: "Edit Task", onClick: () => this.setVal({modal: <EditDocumentModal key={record.id} onSave={task => this.routines.saveTask(task)} onDelete={id => this.routines.deleteDocument(id)} task={record} onClose={() => this.setVal({modal: null})} />})},
                ]
            } event={event} onClose={() => this.setVal({contextMenu: null})} />})
        };
        documentContext = (event, record, field) => {
            event.preventDefault();
            this.setVal({contextMenu: <IElements.ContextMenu
                title="Document Options"
                menu={[
                    "Task",
                    `${record.name} ${record.description}`,
                    "line",
                    {caption: "Select Task", onClick: () => this.setVal({selectedAttachment: record}, () => this.routines.getDocument())},
                    {caption: "Edit Task", onClick: () => this.setVal({modal: <EditDocumentModal key={record.id} onSave={task => this.routines.saveTask(task)} onDelete={id => this.routines.deleteDocument(id)} task={record} onClose={() => this.setVal({modal: null})} />})},
                ]
            } event={event} onClose={() => this.setVal({contextMenu: null})} />})
        };

        mobile = state => <div>Not Available</div>;
        desktop = state => <><table style={styles().table}><tbody>
            <tr><td style={{padding: "0px"}}>{this.menuBar(state)}</td></tr>
            <tr><td style={{padding: "0px"}}><IElements.Titles.PanelHeader>Document</IElements.Titles.PanelHeader></td></tr>
            <tr><td style={{height: "33%", overflow: "auto", padding: "0px", verticalAlign: "top"}}>{this.document(state)}</td></tr>
            <tr><td style={{padding: "0px"}}><IElements.Titles.PanelHeader>Content</IElements.Titles.PanelHeader></td></tr>
            <tr><td style={{height: "33%", overflow: "auto", padding: "0px", verticalAlign: "top"}}>{this.content(state)}</td></tr>
            <tr><td style={{padding: "0px"}}><IElements.Titles.PanelHeader>Attachments</IElements.Titles.PanelHeader></td></tr>
            <tr><td style={{height: "33%", overflow: "auto", padding: "0px", verticalAlign: "top"}}>{this.attachments(state)}</td></tr>
        </tbody></table>{state.modal}{state.contextMenu}</>;
    }
};
export default Exp;