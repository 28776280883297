// import AppManager from "../../../services/AppManager";
import React from "react";
import Icon from "../../../directives/Icon";
import Panels from "../../../shell/windows/Panels";
// import BaseApi from "../../../services/BaseApi";
import IElements from "../../../directives/IElements";
// import Modals from "../../../shell/windows/Modal";
// import CoreTools from "../../../services/CoreTools";
import Socket from "../../../services/Socket";
import UserService from "../../../services/UserService";

const styles = ( row_key ) => ({
    table: {
        width: "100%",
        height: "100%",
        borderCollapse: "collapse"
    },
    full: {
        width: "100%",
        height: "33%",
        verticalAlign: "top"
    },
});

const funnelConnector = () => {
    let socket = Socket("wss://form.consumergenius.com/agent");
    socket.reconnect(true);
    let sendCache = [];
    let authed = false;
    const send = (action, data) => {
        if (authed && socket.state() === 1) {
            socket.send(action, data);
        } else {
            sendCache.push({action: action, data: data});
        }
    };

    socket.on("ident", data => {
        if (data.success) {
            authed = true;
            send("activeClients", {});
        }
    });

    socket.on("open", data => {
        socket.send("ident", {authkey: UserService.user.authkey});
    });

    return {
        on: socket.on,
        send: send,
        connect: socket.connect,
        close: socket.close,
        end: socket.end
    };
};

const Exp = {
    singleInstance: false,
    name: "WebChat",
    title: "Web Chat",
    description: "Customer Support Chat",
    menuIcon: <Icon name="user" size={18} />,
    taskbarIcon: <Icon name="user" size={22} />,
    startFullscreen: false,
    allowFullscreen: true,
    allowWindowed: true,
    allowResize: true,
    startWidth: 800,
    startHeight: 600,
    minWidth: 800,
    minHeight: 400,
    Component: class WebChat extends Panels.Window {
        init = (() => {
            this.setVal({clients: [this.props.params.user || null]});
        })();
        api = {
        };
        routines = {
        };

        onMount = () => {
            this.socket = funnelConnector();
            this.socket.connect();
            this.socket.on("updateFields", data => {
                let clients = this.state.clients;
                let client = clients.find(c => c.id === data.id);
                if (client) {
                    Object.keys(data.fields).forEach(key => {client.fields[key] = data.fields[key];});
                    this.setVal({clients: clients});
                }
            });
            this.socket.on("formName", data => {
                let clients = this.state.clients;
                let client = clients.find(c => c.id === data.id);
                if (client) {
                    client.formName = data.formName;
                    this.setVal({clients: clients});
                }
            });
        };
        onUnMount = () => {
            this.socket.end();
        };

        menuBar = state => <IElements.MenuBar.HeaderBar menu={[
            {
                caption: "File",
                menu: [
                    {caption: "Refresh", disabled: true},
                    "line",
                    {caption: "Exit", onClick: e => this.close()}
                ]
            },
            {caption: "Help", disabled: true}
        ]} />;

        user = state => <IElements.Tables.ListView
            style={{width: "100%"}}
            body={[
                ["ID", state.clients[0].id],
                ["Funnel", state.clients[0].formName],
                ["First Name", state.clients[0].fields.first_name],
                ["Last Name", state.clients[0].fields.last_name],
                ["Phone", state.clients[0].fields.phone],
                ["Email", state.clients[0].fields.email],
            ]}
        />;

        userfields = state => <IElements.Tables.ListView
            style={{width: "100%"}}
            body={
                [
                    ...Object.keys(state.clients[0].fields).map(key => {
                        //use type detection, eg: objects return "{object}"
                        return [key,state.clients[0].fields[key]];
                    }),
                    Object.keys(state.clients[0].params).map(key => {
                        //use type detection, eg: objects return "{object}"
                        return [key,state.clients[0].params[key]];
                    })
                ]
            }
        />;

        chat = state => <></>;

        mobile = state => <div>Not Available</div>;
        desktop = state => <table style={styles().table}>
            <tbody>
                <tr><td style={{padding: "0px"}}>{this.menuBar(state)}</td></tr>
                <tr><td style={{padding: "0px"}}><IElements.Titles.PanelHeader>Contact</IElements.Titles.PanelHeader></td></tr>
                <tr><td style={styles().full}>{this.user(state)}</td></tr>
                <tr><td style={{padding: "0px"}}><IElements.Titles.PanelHeader>Info</IElements.Titles.PanelHeader></td></tr>
                <tr><td style={styles().full}>{this.userfields(state)}</td></tr>
                <tr><td style={{padding: "0px"}}><IElements.Titles.PanelHeader>Chat</IElements.Titles.PanelHeader></td></tr>
                <tr><td style={styles().full}>{this.chat(state)}</td></tr>
            </tbody>
        </table>;
    }
};
export default Exp;